const accountTypes = {
  // sign in
  START_SIGNIN: 'START_SIGNIN',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_FAILURE: 'SIGNIN_FAILURE',
  // register
  START_REGISTER: 'START_REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  // forget password
  START_FORGET_PASSWORD: 'START_FORGET_PASSWORD',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',
  // change password
  START_CHANGE_PASSWORD: 'START_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  // confim account
  START_CONFIRM_ACCOUNT: 'START_CONFIRM_ACCOUNT',
  CONFIRM_ACCOUNT_SUCCESS: 'CONFIRM_ACCOUNT_SUCCESS',
  CONFIRM_ACCOUNT_FAILURE: 'CONFIRM_ACCOUNT_FAILURE',
  // get info account
  START_GET_INFO_ACCOUNT: 'START_GET_INFO_ACCOUNT',
  GET_INFO_ACCOUNT_SUCCESS: 'GET_INFO_ACCOUNT_SUCCESS',
  GET_INFO_ACCOUNT_FAILURE: 'GET_INFO_ACCOUNT_FAILURE',
  // update info account
  START_UPDATE_INFO_ACCOUNT: 'START_UPDATE_INFO_ACCOUNT',
  UPDATE_INFO_ACCOUNT_SUCCESS: 'UPDATE_INFO_ACCOUNT_SUCCESS',
  UPDATE_INFO_ACCOUNT_FAILURE: 'UPDATE_INFO_ACCOUNT_FAILURE',
}

export default accountTypes;