export const ROOT = "be";
export const VERSION = "v1";
export const ROOT_VERSION = `${ROOT}/${VERSION}`;

export const API_LOGIN = `auth/login`;
export const API_MEANING_OF_NUMBER = `items/meaning_of_numbers`;
export const API_CICLE_OF_LIFE = `items/circle_of_life`;
export const API_ARROW_INDEX = `items/arrow_index`;
export const API_MEANING_OF_INDEX = `items/meaning_of_index`;
export const API_NUMBER_OF_REPETITIONS = `items/number_of_repetitions`;
export const API_PERSONAL_MONTH_YEAR = `items/personal_month_year`;
export const API_PYRAMID_NUMBERS = `items/pyramid_numbers`;
export const API_REGISTER = `users`;
export const API_FORGET_PASSWORD = `${ROOT_VERSION}/auth/forget-password`;
export const API_CHANGE_PASSWORD = `${ROOT_VERSION}/auth/change-password`;
export const API_CONFIRM_ACCOUNT = `users`;
export const API_HISTORY_SEARCH = `items/history_search`;

export const API_CATEGORY = `${ROOT_VERSION}/category`;
export const API_PRODUCTS = `${ROOT_VERSION}/products`;
export const API_UPLOAD_IMAGES = `${ROOT_VERSION}/upload-images`;
export const API_UPLOAD_META_IMAGE = `${ROOT_VERSION}/upload-meta-image`;
export const API_UPLOAD_THUMBNAIL = `${ROOT_VERSION}/upload-thumbnail`;

export const API_UPLOADS = `${ROOT_VERSION}/uploads`;
export const API_UPLOAD_ICON = `${ROOT_VERSION}/upload-icon`;

export const API_ALL = "all";
