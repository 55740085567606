import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Popover, Menu ,Breadcrumb } from "antd";
import Sidenav from "./Sidenav";
import HeaderLayout from "./Header";
import Footer from "./Footer";
import "./styles.less";
import { useAuth } from "src/services/authorRouter";
import { routerNotFullScreen } from "src/constants";
const { Header, Content, Sider  } = Layout;

function DefaultLayout(props: { children: any }) {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [isShowFullScreen, setIsShowFullScreen] = useState(true);
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type: any) => setSidenavType(type);
  const handleSidenavColor = (color: any) => setSidenavColor(color);
  const handleFixedNavbar = (type: any) => setFixed(type);
  const auth = useAuth();

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  /****************************START**************************/
  /*                         Life Cycle                      */

  useEffect(() => {
    console.log('pathname',pathname);
    
    if(routerNotFullScreen.includes(pathname)){
      setIsShowFullScreen(false)
    } else {
      setIsShowFullScreen(true)
    }
  }, [pathname]);

  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */  
  
  const _renderBody = () => {
    return (
      <Layout className="pth-screen-bg">
        <div className={`w-full h-full`}>{children}</div>
      </Layout>
    );
  };

  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <HeaderLayout pathname={pathname}/>

      {_renderBody()}
      
      <Footer />
    </Layout>
  );

  /**************************** END **************************/
}

export default DefaultLayout;
