// import { CATEGORIES_MOCK } from "./json";
// import TableCate from "./TableCate";
import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WhoAmIImg from "src/assets/images/who_am_i.jpg";
import ButtonPTH from "src/components/buttons/ButtonPTH";
import FormInput from "src/components/form/FormInput";
import FormInputNumber from "src/components/form/FormInputNumber";
import FormSelect from "src/components/form/FormSelect";
import { notifyError } from "src/components/notification";
import { dataDate, dataGender, dataMonth, selectOptionsValue } from "src/constants";
import { getInfoAccount } from "src/services/actions/account.actions";
import { setDataNumerology } from "src/services/actions/searchNumerology.actions";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import routerNames from "src/utils/data/routerName";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const Home = () => {
  const [searchForm] = Form.useForm();
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataSearch, setDataSearch] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const {stateGetInfoAccount} = useSelector((state: AppState) => state.accountReducer);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetInfoAccount;
    if (success && dataSearch) {
      setLoading(false)
      if(data.count_vip_search > 0){
        const _dataSearch = {
          ...dataSearch,
          count_vip_search: data.count_vip_search - 1,
          type_name: 'VIP',
          id: data.id,
        }
        dispatch(setDataNumerology(_dataSearch))
        history.push({
          pathname: routerNames.RE_SEARCH,
        });
      } else{
        notifyError("Số lần tìm kiếm VIP của bạn đã hết. Vui lòng mua thêm gói để sử dụng");
      }
    } else if (success === false || error) {
      setLoading(false)
      notifyError(getMessageV1(message));
    }
  }, [stateGetInfoAccount.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const onSubmit = (values: any) => {
    const _data = {
      full_name: values.full_name,
      birthday: `${values.date_id}/${values.month_id}/${values.year_id}`,
      common_name: values.common_name,
      gender_id: values.gender_id,
      isSearch: true,
    }
    setLoading(true)
    setDataSearch(_data)
    dispatch(getInfoAccount({}))
  }

  
  const onFinishFailed = (error: any) => {
    console.log('====',error);
    
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  const _renderContent = () => {
    return (
      <div className="pth-content-2-3">
        <Row gutter={[8,8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormInput
              id='form-full_name'
              name='full_name'
              label='Họ tên khai sinh (nên nhập không dấu)'
              type='text'
              placeholder="Họ tên đầy đủ của bạn"
              className='mt-5 mb-0 pth-form-item-label w-full'
              required
            />
          </Col>
        </Row>
        <Form.Item label='Tên thường dùng nếu có (VD: Bao Thuong,...)' className="mb-0 pth-form-item-label">
          <Row gutter={[8,8]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <FormInput
                id='form-common_name'
                name='common_name'
                type='text'
                placeholder="Nhập tên thường dùng"
                className="mb-0 w-full"
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormSelect
                name="gender_id"
                dataSource={dataGender}
                placeholder="Giới tính"
                slOption={selectOptionsValue}
                classItem='mb-0 w-full'
                required
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label='Ngày/tháng/năm sinh dương lịch' className="mb-0 pth-form-item-label">
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormSelect
                name="date_id"
                dataSource={dataDate}
                slOption={selectOptionsValue}
                classItem='w-full'
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormSelect
                name="month_id"
                dataSource={dataMonth}
                slOption={selectOptionsValue}
                classItem='w-full'
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <FormInputNumber
                name="year_id"
                placeholder="Năm sinh"
                classInput='mb-0 w-full'
                // min={0}
                type="number"
                required
              />
            </Col>
          </Row>
        </Form.Item>
      </div>
    )
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className='flex-col flex items-center justify-start pth-bg-img-login'>
      <div className='w-full h-full pl-4 pr-4'>
        <Col className='mt-5'>
          <Row justify="center">
            <Col span={24} className='flex justify-center text-center'>
              <span className="text-xl text-neutral_color_1_8"><strong>CÔNG CỤ TRA CỨU THẦN SỐ HỌC ONLINE</strong></span>
            </Col>
          </Row>
          <Row>
            <Col span={24} className='flex justify-center'>
              <div className="mt-3 pth-content-2-3">
                <div><i className="text-neutral_color_1_8">- Tính toán hàng chục nghìn phép tính thần số phức tạp cực nhanh chỉ sau 1 giây để cho ra các tổ hợp chỉ số mà không hề sai sót như tính tay.</i></div>
                <div><i className="text-neutral_color_1_8">- Luận giải kết quả tra cứu được cá nhân hóa dựa vào nghiên cứu chuyên sâu của các chuyên gia Thần số học.</i></div>
              </div>
            </Col>
          </Row>
          <Form
            form={searchForm}
            requiredMark={false}
            layout='vertical'
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            initialValues={{
              gender_id: '1',
              date_id: '01',
              month_id: '01',
            }}
            className='w-full h-full flex justify-center items-center flex-col '>
              
            {_renderContent()}

            <ButtonPTH
              id='form-button-signin'
              type='default'
              htmlType='submit'
              icon='search'
              className="mt-3 pth-content-2-3"
              fillIcon={colors.neutral_color_1_8}
              loading={loading}
              >
              TRA CỨU
            </ButtonPTH>
          </Form>
          <Row>
            <Col span={24} className='flex justify-center mb-3'>
              <div className="mt-3 pth-content-2-3 bg-neutral_color_1_8 rounded-md px-2 py-2">
                <Row gutter={[8,8]}>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16} className="text-justify">
                    <span>
                    Bạn không hiểu chính mình? Bạn ngập ngừng khi ai đó hỏi về điểm mạnh, điểm yếu của bản thân? 
                    Bạn băn khoăn giữa các lựa chọn về cơ hội nghề nghiệp? Bạn lưỡng lự khi đưa ra quyết định cho 
                    kế hoạch tại một thời điểm? Bạn muốn biết về các cột mốc trong cuộc đời mình?... Hãy đến với 
                    công cụ tra cứu thần số học dựa vào ngày tháng năm sinh và họ tên của bạn, chúng tôi sẽ giải mã 
                    các câu hỏi trên một cách <strong>nhanh chóng</strong> và <strong>chính xác!</strong>
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}className='flex justify-center'>
                    <img src={WhoAmIImg} alt='' width={200} className="mt-2"/>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16} className="text-justify">
                    <span>
                    Nhắc đến cái tên <strong>Py-ta-go</strong> hẵn không còn xa lạ với chúng ta, bởi ông là nhà Toán học vĩ đại 
                    với định lý <strong>Py-ta-go</strong> nổi tiếng mà mỗi chúng ta đều đã học trong môn Toán học. Ông không những 
                    là nhà Toán học, Triết gia mà ông còn là cha đẻ của Thần số học – bộ môn khoa học mà chúng tôi 
                    đang ứng dụng trên công cụ tra cứu trên.
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className='flex justify-center'>
                    <div className="pth-bg-img-pitago mb-2"></div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );

  /**************************** END **************************/

};

export default Home;
