import { call, put, takeLatest } from 'redux-saga/effects';
import { API_PYRAMID_NUMBERS } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import pyramidNumberTypes from '../types/pyramidNumbers.types';

function* getPyramidNumbers(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _query = payload.params.map((item: any, idx: number) => {
        const _period = idx + 1;
        const _result = {"_and":[{ "period": _period},{ "number": {"_in":item}}]}
        return(_result);
      });
      const _filter = {
        filter: {
          "_or": _query
        }
      }
      return api.get(`${API_URL}/${API_PYRAMID_NUMBERS}`, _filter)
    });
    yield put(actionSuccess(pyramidNumberTypes.GET_PYRAMID_NUMBER_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(pyramidNumberTypes.GET_PYRAMID_NUMBER_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(pyramidNumberTypes.START_GET_PYRAMID_NUMBER, getPyramidNumbers),
]

