import { Col, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { genDataChart } from "src/utils/helpers/functions/helpFunctions";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

import LineChart from "./lineChart";
import ApexChart from "./lineChartCycleOfFortunes";
import "./styles.less";

const CardCycleOfFortunes = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  
  const [dataSeries, setDataSeries] = useState<any>(false);
  const [dataSerieShow, setDataSerieShow] = useState<any>(false);
  const [dataCategories, setDataCategories] = useState<any>(false);
  const [colorCategories, setColorCategories] = useState<any>(false);
  const [maxNum, setMaxNum] = useState<any>(0);
  const isMount = useIsMount();
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    if(isMount) return;
    const result = genDataChart(props.data?.number, 2);
    
    setDataSeries(result.dataSeries);
    setDataSerieShow(result.dataSerieShow);
    setDataCategories(result.dataCategories);
    setColorCategories(result.colorCategories);
    setMaxNum(result.maxNum);
    
  }, [props.data?.number]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHU KỲ VẬN SỐ CỦA BẠN</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Biểu đồ này cho biết ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
            <>
              <div>
                <i>
                Biểu đồ này cho biết bạn đang ở đâu trong chu kỳ vậ số của mình. Theo thần số học, chu kỳ 
                phát triển của mỗi người sẽ lặp lại mỗi 9 năm. Với mỗi năm có số cá nhân là 1, cuộc đời lại 
                bắt đầu một chu kỳ mới với xuất phát cao hơn chu kỳ trước. Ảnh hưởng của biểu đồ này sẽ thể 
                hiện mạnh nhất trong giai đoạn từ mốc đỉnh cao đầu tiên đến mốc đỉnh cao cuối cùng ( Xem ở 
                mục <a href="#card_pyramid" className="text-accent_color_5_6">Kim tự tháp thần số)</a>
                </i>
              </div>
              <div>
                <i>
                Đoạn biểu đồ đi lên cho thấy giai đoạn cuộc sống sẽ có nhiều cơ hội, thay đổi hoặc phát triển 
                từ bên ngoài
                </i>
              </div>
              <div>
                <i>
                Đoạn biểu đồ đi xuống cho thấy giai đoạn cuộc sống có nhiều thay đổi bên trong bản thân, bạn 
                nên giành thời gian phát triển nội tâm, trí tuệ của mình hơn là tập trung vào những thứ bên 
                ngoài trong giai đoạn này. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                </i>
              </div>
            </>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          <div className="flex justify-center ">
            <div className="w-full text-center">
              {/* <LineChart 
                xAxisFill="#000" 
                labelFill="#000"
                pointFill="#808A94"
                number={props.data?.number}
              /> */}
              {
                dataSeries && 
                <ApexChart 
                  dataSeries={dataSeries} 
                  dataCategories={dataCategories}
                  dataSerieShow={dataSerieShow}
                  colorCategories={colorCategories}
                  maxNum={maxNum}
                />
              }
              <div><span>Chu kỳ vận số của bạn</span></div>
              <div>
                <span className="text-primary_color_1_3">Đăng nhập tài khoản để xem được 
                chu kỳ vận số và giải thích <Tag color="#197742" className="rounded cursor-pointer">Tại đây</Tag>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <i>
            Năm nay {moment().year()}, bạn đang ở giai đoạn đầu của chu kỳ vận số. Xem thêm mục <a 
            href="#card_index_year" className="text-accent_color_5_6">CHỈ SỐ NĂM</a> của bạn để 
            biết hướng phát triển trong năm nay và thời gian tới! 
            </i>
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardCycleOfFortunes;
