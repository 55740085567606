import { call, put, takeLatest } from 'redux-saga/effects';
import { API_CICLE_OF_LIFE } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import circleOfLifeTypes from '../types/circleOfLife.types';

function* getCircleOfLife(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        filter: { "number": {"_in": payload.params}}
      }
      return api.get(`${API_URL}/${API_CICLE_OF_LIFE}`, _filter)
    });
    yield put(actionSuccess(circleOfLifeTypes.GET_CICLE_OF_LIFE_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(circleOfLifeTypes.GET_CICLE_OF_LIFE_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(circleOfLifeTypes.START_GET_CICLE_OF_LIFE, getCircleOfLife),
]

