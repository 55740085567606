const uploadTypes = {

  START_UPLOAD_LIST_IMAGE_PRODUCT: 'START_UPLOAD_LIST_IMAGE_PRODUCT',
  UPLOAD_LIST_IMAGE_PRODUCT_SUCCESS: 'UPLOAD_LIST_IMAGE_PRODUCT_SUCCESS',
  UPLOAD_LIST_IMAGE_PRODUCT_FAILURE: 'UPLOAD_LIST_IMAGE_PRODUCT_FAILURE',

  START_UPLOAD_META_IMAGE_PRODUCT: 'START_UPLOAD_META_IMAGE_PRODUCT',
  UPLOAD_META_IMAGE_PRODUCT_SUCCESS: 'UPLOAD_META_IMAGE_PRODUCT_SUCCESS',
  UPLOAD_META_IMAGE_PRODUCT_FAILURE: 'UPLOAD_META_IMAGE_PRODUCT_FAILURE',

  START_UPLOAD_THUMBNAIL_IMAGE_PRODUCT: 'START_UPLOAD_THUMBNAIL_IMAGE_PRODUCT',
  UPLOAD_THUMBNAIL_IMAGE_PRODUCT_SUCCESS: 'UPLOAD_THUMBNAIL_IMAGE_PRODUCT_SUCCESS',
  UPLOAD_THUMBNAIL_IMAGE_PRODUCT_FAILURE: 'UPLOAD_THUMBNAIL_IMAGE_PRODUCT_FAILURE',

  START_UPLOAD_ICON_CATEGORY: 'START_UPLOAD_ICON_CATEGORY',
  UPLOAD_ICON_CATEGORY_SUCCESS: 'UPLOAD_ICON_CATEGORY_SUCCESS',
  UPLOAD_ICON_CATEGORY_FAILURE: 'UPLOAD_ICON_CATEGORY_FAILURE',

  START_UPLOAD_META_IMAGE_CATEGORY: 'START_UPLOAD_META_IMAGE_CATEGORY',
  UPLOAD_META_IMAGE_CATEGORY_SUCCESS: 'UPLOAD_META_IMAGE_CATEGORY_SUCCESS',
  UPLOAD_META_IMAGE_CATEGORY_FAILURE: 'UPLOAD_META_IMAGE_CATEGORY_FAILURE',

  START_UPLOAD_FILES_CDN: 'START_UPLOAD_FILES_CDN',
  UPLOAD_FILES_CDN_SUCCESS: 'UPLOAD_FILES_CDN_SUCCESS',
  UPLOAD_FILES_CDN_FAILURE: 'UPLOAD_FILES_CDN_FAILURE',

}

export default uploadTypes;