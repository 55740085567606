
import { IAction } from '../interfaces/root.interfaces';
import pyramidNumberTypes from './types/pyramidNumbers.types';

export const getPyramidNumbers = (params: any): IAction => {
  
  const action: IAction = {
    type: pyramidNumberTypes.START_GET_PYRAMID_NUMBER,
    payload: { params },
  }
  return action;
};