import { Reducer } from 'redux';
import arrowIndexTypes from '../actions/types/arrowIndex.types';
import { IArrowIndexState } from '../interfaces/arrowIndex.interfaces';

import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

const initState: IArrowIndexState = {
  stateGetArrowIndex: { ...rootState },
};

const arrowIndexReducer: Reducer<IArrowIndexState, ISyntheticAction>
  = (state: IArrowIndexState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       get pyramid Number                    */

      case arrowIndexTypes.START_GET_ARROW_INDEX: {
        const stateReducer: IArrowIndexState = {
          ...state,
          stateGetArrowIndex: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case arrowIndexTypes.GET_ARROW_INDEX_SUCCESS: {
        const stateReducer: IArrowIndexState = {
          ...state,
          stateGetArrowIndex: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case arrowIndexTypes.GET_ARROW_INDEX_FAILURE: {
        const stateReducer: IArrowIndexState = {
          ...state,
          stateGetArrowIndex: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default arrowIndexReducer;