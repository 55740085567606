import { Reducer } from 'redux';
import numberOfRepetitionTypes from '../actions/types/numberOfRepetition.types';
import { INumberOfRepetitionState } from '../interfaces/numberOfRepetition.interfaces';

import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

const initState: INumberOfRepetitionState = {
  stateGetNumberOfRepetition: { ...rootState },
};

const numberOfRepetitionReducer: Reducer<INumberOfRepetitionState, ISyntheticAction>
  = (state: INumberOfRepetitionState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       get pyramid Number                    */

      case numberOfRepetitionTypes.START_GET_NUMBER_OF_REPETITION: {
        const stateReducer: INumberOfRepetitionState = {
          ...state,
          stateGetNumberOfRepetition: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case numberOfRepetitionTypes.GET_NUMBER_OF_REPETITION_SUCCESS: {
        const stateReducer: INumberOfRepetitionState = {
          ...state,
          stateGetNumberOfRepetition: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case numberOfRepetitionTypes.GET_NUMBER_OF_REPETITION_FAILURE: {
        const stateReducer: INumberOfRepetitionState = {
          ...state,
          stateGetNumberOfRepetition: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default numberOfRepetitionReducer;