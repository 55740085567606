/* eslint-disable */
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notifyError } from "src/components/notification";
import CustomTable from "src/components/table/CustomTable";
import { getInfoAccount } from "src/services/actions/account.actions";
import { getHistorySearch } from "src/services/actions/historySearch.actions";
import { setDataNumerology } from "src/services/actions/searchNumerology.actions";
import { AppState } from "src/types";
import routerNames from "src/utils/data/routerName";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsHistorySearch, defaultDataHistorySearch } from "./data";

const HistorySearch = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMount = useIsMount();
  const [paging, setPaging] = useState<object>({});
  const [loading, setLoading] = useState<any>(true);
  const [dataHistory, setDataHistory] = useState<any>([]);
  
  const {stateGetInfoAccount} = useSelector((state: AppState) => state.accountReducer);
  const {stateGetHistorySearch} = useSelector((state: AppState) => state.historySearchReducer);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetInfoAccount;
    if (success) {
      dispatch(getHistorySearch(data.id))
    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateGetInfoAccount.isLoading]);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetHistorySearch;
    setLoading(false)
    if (success) {
      const _dataHistory = data.map((item: any, idx: number) => {
        return({
          rank: idx+1,
          full_name: item.full_name,
          birthday: item.birthday,
          common_name: item.common_name,
          gender_id: item.gender_id,
          datetime: item.created_at,
          type_name: item.type_name,
          url_download: "",
        })
      })
      setDataHistory(_dataHistory)
    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateGetHistorySearch.isLoading]);

  useEffect(() => {
    dispatch(getInfoAccount({}))
  }, []);
  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */
  const btnSearchNumgolory = (data: any) => {
    const _data = {
      full_name: data.full_name,
      birthday: data.birthday,
      common_name: data.common_name,
      gender_id: data.gender_id,
    }
    dispatch(setDataNumerology(_data))
    history.push({
      pathname: routerNames.RE_SEARCH,
    });
  };

  const btnDownloadPDF = () => {};

  // const onChangeTable = (page: any) => {
  //   const _filter = {
  //     ...paramsFilter,
  //     currentPage: page.current,
  //     sizePage: page.pageSize,
  //     isDispatch: true,
  //   };
  //   setParamsFilter(_filter);
  // };

  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    
    <div className="mt-3 mb-3">
      <Spin spinning={loading}>
        <Row gutter={[8,8]}>
          <Col span={24} className='flex justify-center'>
            <div className="w-4/5 px-2 py-2">
              <CustomTable
                rowKey={"bill_code"}
                bordered
                paging={paging}
                columns={columnsHistorySearch({
                  btnDownloadPDF: btnDownloadPDF,
                  btnSearchNumgolory
                })}
                dataSource={dataHistory}
                loading={false}
                // pagination={true}
                className="pth-table-history pth-table-reponse"
                // scroll={{ x: 400 }}
                // onChange={onChangeTable}
              />
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );

  /**************************** END **************************/
};

export default HistorySearch;
