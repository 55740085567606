
import { IAction } from '../interfaces/root.interfaces';
import personalMonthYear from './types/personalMonthYear.types';

export const getpersonalMonthYear = (params: any): IAction => {
  
  const action: IAction = {
    type: personalMonthYear.START_GET_PERSONAL_MONTH_YEAR,
    payload: { params },
  }
  return action;
};