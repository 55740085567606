/* eslint-disable */


export const columnsYearIndex = (props?: any) => {
  return [
    {
      title: "Năm",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "80%",
      render: (text: string) => {
        return <span className="font-medium">{text}</span>;
      },
    },
    {
      title: "Chỉ số",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "20%",
      render: (text: string) => {
        return <span className="font-medium text-accent_color_2_1">{text}</span>;
      },
    },
  ];
};

export const columnsMonthIndex = (props?: any) => {
  return [
    {
      title: "Tháng",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "80%",
      render: (text: string) => {
        return <span className="font-medium">{text}</span>;
      },
    },
    {
      title: "Chỉ số",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "20%",
      render: (text: string) => {
        return <span className="font-medium text-accent_color_2_1">{text}</span>;
      },
    },
  ];
};

export const columnsChartIndex = (props?: any) => {
  return [
    {
      title: "column number 1",
      dataIndex: "col_1",
      key: "col_1",
      align: "center",
      width: "33%",
      render: (text: string) => {
        return <span className="font-medium">{text}</span>;
      },
    },
    {
      title: "column number 1",
      dataIndex: "col_2",
      key: "col_2",
      align: "center",
      width: "33%",
      render: (text: string) => {
        return <span className="font-medium">{text}</span>;
      },
    },
    {
      title: "column number 3",
      dataIndex: "col_3",
      key: "col_3",
      align: "center",
      render: (text: string) => {
        return <span className="font-medium">{text}</span>;
      },
    },
  ];
};

export const defaultDataYearIndex = [
  {
    title: "Năm 2022",
    number: 2,
  },
  {
    title: "Năm 2023",
    number: 3,
  },
  {
    title: "Năm 2024",
    number: 4,
  },
];

export const defaultDataMonthIndex = [
  {
    title: "Tháng 07/2022",
    number: 9,
  },
  {
    title: "Tháng 08/2022",
    number: 1,
  },
  {
    title: "Tháng 09/2022",
    number: 2,
  },
];

export const defaultDataStrengthIndex = [
  {
    col_1: null,
    col_2: null,
    col_3: null,
  },
  {
    col_1: null,
    col_2: null,
    col_3: null,
  },
  {
    col_1: null,
    col_2: null,
    col_3: null,
  },
];

export const setColStrengthIndex = [
  {
    number: 3,
    name: 'col_1'
  },
  {
    number: 6,
    name: 'col_2'
  },
  {
    number: 9,
    name: 'col_3'
  },
  {
    number: 2,
    name: 'col_1'
  },
  {
    number: 5,
    name: 'col_2'
  },
  {
    number: 8,
    name: 'col_3'
  },
  {
    number: 1,
    name: 'col_1'
  },
  {
    number: 4,
    name: 'col_2'
  },
  {
    number: 7,
    name: 'col_3'
  },
];
export const arrowStrength = [
  [1,2,3],
  [4,5,6],
  [7,8,9],
  [1,4,7],
  [2,5,8],
  [3,6,9],
  [1,5,9],
  [3,5,7],
];
export const masterArrayNumber = [
  {
    number: 1,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [2,4,5]
  },
  {
    number: 2,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [1,2,3,4,5,6]
  },
  {
    number: 3,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [2,5,6]
  },
  {
    number: 4,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [1,2,5,7,8]
  },
  {
    number: 5,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [1,2,3,4,6,7,8,9]
  },
  {
    number: 6,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [2,3,5,8,9]
  },
  {
    number: 7,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [4,5,8]
  },
  {
    number: 8,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [4,5,6,7,9]
  },
  {
    number: 9,
    countExits: 0,
    isAlone: true,
    arrAroundNumber: [5,6,8]
  },
];
export const keyDecryptionArrayNumber:any = {
  0: 'missing_number',
  1: 'one_time',
  2: 'two_times',
  3: 'three_times',
  4: 'four_times',
  5: 'five_times',
}
// export const dataNumberShowChart:any = {
//   1: 4,
//   2: 3,
//   3: 2,
//   4: 1,
//   5: 2,
//   6: 3,
//   7: 2,
//   8: 4,
//   9: 5,
// }
export const dataNumberShowChart:any = {
  1: 3.5,
  2: 2.5,
  3: 1.5,
  4: 1,
  5: 1.5,
  6: 2.3,
  7: 1.5,
  8: 2.5,
  9: 4.5,
}
export const defaultRankRender = [
  'LifePathIndex', // chỉ số chủ đạo
  'MissionIndex', // sứ mệnh
  'SoulIndex', // linh hồn
  'CharacterIndex', // nhân cách
  'AttitudeIndex',  // thái độ
  'NaturalCapacityIndex', // năng lực tự nhiên
  'GrownUpIndex', // chỉ số trưởng thành
  'CircleOfLife', // chu kỳ đường đời
  'Pyramid', // kim tự tháp
  'CycleOfFortunes', // chu kỳ vận số
  'PersonalYear', // chỉ số năm
  'PersonalMonth', // chỉ số tháng
  'PowerChart', // biểu đồ sức mạnh
  'NameAndSumChart', // biểu đồ tên và biểu đồ tổng hợp
  //'Personality', // tính cách
]
