import * as React from "react"

const SvgBanEye = (props) => (
    <svg
    width={19}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 8.121a2.62 2.62 0 1 0 0-5.24 2.62 2.62 0 0 0 0 5.24Z"
      fill={props.fill ? props.fill : "#AB1D88"}
    />
    <path
      d="M17.747 4.776C15.734 2.342 12.685 0 9.5 0 6.314 0 3.265 2.344 1.253 4.776a1.14 1.14 0 0 0 0 1.451c.506.611 1.566 1.792 2.983 2.823 3.568 2.597 6.952 2.603 10.528 0a17.053 17.053 0 0 0 2.983-2.823 1.14 1.14 0 0 0 0-1.451ZM9.5 1.834A3.672 3.672 0 0 1 13.168 5.5 3.672 3.672 0 0 1 9.5 9.17a3.672 3.672 0 0 1-3.668-3.668A3.672 3.672 0 0 1 9.5 1.834Z"
      fill={props.fill ? props.fill : "#AB1D88"}
    />
  </svg>
)

export default SvgBanEye
