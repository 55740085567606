import { Col, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCircleOfLife } from "src/services/actions/circleOfLife.actions";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

import "./styles.less";

const CardCircleOfLife = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [dataCircleOfLife, setDataCircleOfLife] = useState<any>({});
  const [dataDescriptionCircleOfLife, setDataDescriptionCircleOfLife] = useState<any>([]);

  const isMount = useIsMount();
  const dispatch = useDispatch();
  const {stateGetCircleOfLife} = useSelector((state: AppState) => state.circleOfLifeReducer);
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    getDataCircleOfLife();
  }, [props.data]);

  useEffect(() => {
    if (isMount)
      return

    const { 
      top1Age,
      top1Year,
      top4Age,
      top4Year 
    } = props.dataCircleOfLife;
    const _dataCircleOfLife = {
      titleFirst: `Đầu đời - ${top1Age} tuổi (${top1Year})`,
      titleSecond: `${top1Age + 1} - ${top4Age} tuổi (${top1Year + 1} - ${top4Year})`,
      titleThird: `${top4Age + 1} tuổi về sau (${top4Year + 1} trở đi)`,
    }
    setDataCircleOfLife(_dataCircleOfLife);

  }, [props.dataCircleOfLife]);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetCircleOfLife;
    if (success) {
      const _descriptionFirst = data.find((item: any) => item.number === props.data.resultDay);
      const _descriptionSecond = data.find((item: any) => item.number === props.data.resultMonth);
      const _descriptionThird = data.find((item: any) => item.number === props.data.resultYear);
      setDataDescriptionCircleOfLife([
        {
          title: `Chu kỳ đầu tiên (GIEO HẠT) từ ${dataCircleOfLife.titleFirst}, ứng với số ${props.data.resultDay}`,
          description: _descriptionFirst?.circle_1
        },
        {
          title: `Chu kỳ 2 (CHÍN) từ ${dataCircleOfLife.titleSecond}, ứng với số ${props.data.resultMonth}`,
          description: _descriptionSecond?.circle_2
        },
        {
          title: `Chu kỳ cuối (THU HOẠCH) từ ${dataCircleOfLife.titleThird}, ứng với số ${props.data.resultYear}`,
          description: _descriptionThird?.circle_3
        },
      ])
    }
  }, [stateGetCircleOfLife.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const getDataCircleOfLife = () => {
    const _params = [props.data.resultDay, props.data.resultMonth, props.data.resultYear];
    dispatch(getCircleOfLife(_params));
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHU KỲ ĐƯỜNG ĐỜI</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <div>
                <i>
                Cuộc đời mỗi người thường sẽ trải qua 3 giai đoạn tuyệt vời trên đường đời. Về mặt số học, 
                ba thời kỳ này (Gieo hạt, Chín, Thu hoạch) và các đặc điểm chính của chúng được mô tả bằng 
                các con số của chu kỳ đường đời. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                </i>
              </div>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md py-2 pth-circle">
          <div className="px-10">
            <Row gutter={[8,8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} className="flex justify-center">
                <div style={{ ["--s" as any]: "#e91e63", ["--e" as any]: "#673ab7"}}>
                  <div className="skill">
                    <div className="outer">
                      <div className="inner">
                      {props.data.resultDay}
                      </div>
                    </div>
                    <div className="dot"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="250px" height="250px">
                      <defs>
                          <linearGradient id="GradientColor">
                            <stop offset="0%" stop-color="#e91e63" />
                            <stop offset="100%" stop-color="#673ab7" />
                          </linearGradient>
                      </defs>
                      <circle className="circle" cx="110" cy="110" r="90" stroke-linecap="round" />
                    </svg>
                  </div>
                  <div className="text-center">
                    <div><span className="pth-text-desc">Chu kỳ 1</span></div>
                    <div><span className="pth-text-desc">{'Gieo hạt'.toUpperCase()}</span></div>
                    <div><span>{dataCircleOfLife?.titleFirst}</span></div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} className="flex justify-center">
                <div style={{ ["--s" as any]: "#7B3DD9", ["--e" as any]: "#00B59A"}}>
                  <div className="skill">
                    <div className="outer">
                      <div className="inner">
                      {props.data.resultMonth}
                      </div>
                    </div>
                    <div className="dot"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="250px" height="250px">
                      <defs>
                          <linearGradient id="GradientColor1">
                            <stop offset="0%" stop-color="#7B3DD9" />
                            <stop offset="100%" stop-color="#00B59A" />
                          </linearGradient>
                      </defs>
                      <circle className="circle1" cx="110" cy="110" r="90" stroke-linecap="round" />
                    </svg>
                  </div>
                  <div className="text-center">
                    <div><span className="pth-text-desc">Chu kỳ 2</span></div>
                    <div><span className="pth-text-desc">{'chín'.toUpperCase()}</span></div>
                    <div><span>{dataCircleOfLife?.titleSecond}</span></div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} className="flex justify-center">
                <div style={{ ["--s" as any]: "#00B59A", ["--e" as any]: "#FF9D00"}}>
                  <div className="skill">
                    <div className="outer">
                      <div className="inner">
                      {props.data.resultYear}
                      </div>
                    </div>
                    <div className="dot"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="250px" height="250px">
                      <defs>
                          <linearGradient id="GradientColor2">
                            <stop offset="0%" stop-color="#00B59A" />
                            <stop offset="100%" stop-color="#FF9D00" />
                          </linearGradient>
                      </defs>
                      <circle className="circle2" cx="110" cy="110" r="90" stroke-linecap="round" />
                    </svg>
                  </div>
                  <div className="text-center">
                    <div><span className="pth-text-desc">Chu kỳ 3</span></div>
                    <div><span className="pth-text-desc">{'thu hoạch'.toUpperCase()}</span></div>
                    <div><span>{dataCircleOfLife?.titleThird}</span></div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mx-2 mt-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích chi tiết</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div>
                {
                  dataDescriptionCircleOfLife.map((item: any) => {
                    const _description = item.description ? item.description.split('\n') : [];
                    return(
                      <div>
                        <div key={geneUniqueKey()}>
                          <i><b>{item.title}</b></i>
                        </div>
                        {
                          _description.map((itemDesc: any) => {
                            return(
                              <div key={geneUniqueKey()}>
                                <i>
                                {itemDesc}
                                </i>
                              </div>
                            );
                          })
                        }
                      </div>
                    );
                  })
                }
                   
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardCircleOfLife;
