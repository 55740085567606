import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInAccount } from '../../../services/actions/account.actions';
import { AppState } from '../../../types';
import FormInput from 'src/components/form/FormInput';

import { IFormSignIn, IPropsSignIn } from '../interfaces';
import ButtonPTH from '../../../components/buttons/ButtonPTH';
import { notifyError, notifySuccess } from 'src/components/notification';
import { useIsMount } from 'src/utils/helpers/functions/useIsMount';
import { getMessageV1 } from 'src/utils/helpers/functions/getMessage';
import { localGetFormLogin, localSaveAccount, localSaveFormLogin, localSaveToken } from 'src/utils/localStorage';
import { useHistory } from 'react-router-dom';
import '../styles.less';
import { useAuth } from 'src/services/authorRouter';
import { textVersion } from 'src/utils/text';
import { typeScreenManagerSignIn, screenSignIn } from "src/constants";
import routerNames from 'src/utils/data/routerName';

const SignIn = (props:IPropsSignIn) => {

  const [signInForm] = Form.useForm();

  const auth = useAuth();
  const dispatch = useDispatch();
  const isMount = useIsMount();
  let history = useHistory();
  const {stateSignIn, phone, email} = useSelector((state: AppState) => state.accountReducer);

  /****************************START**************************/
  /*                         Life Cycle                      */

  useEffect(() => {
    let _formLogin = localGetFormLogin();
    if (!phone && !email && _formLogin) {
      let _dataUser = JSON.parse(_formLogin);
      signInForm.setFieldsValue({
        username: _dataUser.username,
        // password: _dataUser.password
      });
    }
  }, []);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateSignIn;
    if (success) {
      localSaveAccount(JSON.stringify(data));
      localSaveToken(data.access_token || '');
      localSaveFormLogin(JSON.stringify({
        username: phone || email || signInForm.getFieldValue('username'),
        // password: signInForm.getFieldValue('password'),
      }));
      auth.signIn(data.access_token || '', JSON.stringify(data), () => {
        console.log('Save authorization!')
        notifySuccess('Đăng nhập thành công');
        history.push({
          pathname: routerNames.HOME
        });
      });

    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateSignIn.isLoading]);

  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */

  const btnSignIn = (values: IFormSignIn) => {
    let params;
    if (values.username.search('@') !== -1) {
      params = {
        email: values.username.trim(),
        password: values.password.trim(),
        username: values.username.trim()
      };
    } else {
      params = {
        phone: `${values.username.trim()}`,
        password: values.password.trim(),
        username: `${values.username.trim()}@gmail.com`
      };
    }
    dispatch(signInAccount(params));
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const changeDirector = (values: number) => {
      if(stateSignIn.isLoading) return;
      props.changeType(values)
  }

  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/


  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div
      className='px-8 py-8 pth-content-1-3'
    >
      {/* <LogoSignIn /> */}
      <Form
        form={signInForm}
        requiredMark={false}
        layout='vertical'
        onFinish={btnSignIn}
        onFinishFailed={onFinishFailed}
        initialValues={{
          username: phone || email || '',
        }}
        className='w-full h-full flex justify-between flex-col pth-form-item-label'>
        <div>
          <FormInput
            id='form-username'
            name='username'
            label='Số điện thoại'
            type='phoneAndEmail'
            placeholder="Nhập số điện thoại"
            disabled={stateSignIn.isLoading}
            className='mt-5 mb-0 w-full'
            required
          />
          <FormInput
            id='form-password'
            name='password'
            label='Mật khẩu'
            placeholder="Nhập mật khẩu"
            classItem='mt-3 mb-0 input-pass w-full'
            disabled={stateSignIn.isLoading}
            type='password'
            required
          />

          {/* <div className='w-full mt-1 mb-4'>
            <a className='text-sm text-accent_color_3_2 text-left font-normal cursor-pointer'
            onClick={(e) => changeDirector(typeScreenManagerSignIn.FORGET_PASSWORD)}
            >
              Quên mật khẩu
            </a>
          </div> */}
          <div className='w-full mt-1 mb-4'>
            <a className='text-sm a-login text-left font-normal cursor-pointer'
            onClick={(e) => changeDirector(typeScreenManagerSignIn.SEND_OTP_CHANGEPASS)}
            >
              Quên mật khẩu
            </a>
          </div>
        </div>

        <ButtonPTH
          id='form-button-signin'
          type='transparent'
          htmlType='submit'
          loading={stateSignIn.isLoading}>
          Đăng nhập
        </ButtonPTH>
        {/* <div className='t-a-end'>
          <span className='text-14 font-medium text-neutral_color_1_4'>{textVersion}</span>
        </div> */}
        <hr className='mt-2 mb-2 border-accent_color_3_2'/>
      </Form>
      <div className='t-a-end'>
        <span className='text-14 font-medium text-accent_color_3_2'>
        Bạn  chưa có tài khoản ? <a className='text-sm a-login text-left font-bold cursor-pointer' onClick={(e) => changeDirector(typeScreenManagerSignIn.REGISTER)}> Đăng ký tại đây</a>
        </span>
      </div>
    </div>
  )

  /**************************** END **************************/

}

export default SignIn;