import { Col, Tag } from "antd";
import { useState } from "react";
import colors from "src/utils/colors";

import "./styles.less";

const CardNaturalCapacityIndex = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHỈ SỐ NĂNG LỰC TỰ NHIÊN CỦA BẠN LÀ: <span className="text-accent_color_2_1">
          {props.data?.number}</span></strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Chỉ số này cho bạn biết năng khiếu bẩm sinh, thứ mà bạn có thể làm dễ dàng, cũng như những 
                  tài năng và năng lực cụ thể sẽ hỗ trợ bạn trên đường đời. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích về số <b>{props.data?.number}</b> này của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                {
                  (props.data?.description || []).map((item: any) => {
                    return(
                      <div>
                        <i>{item}</i>
                      </div>
                    );
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardNaturalCapacityIndex;
