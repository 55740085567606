import { call, put, takeLatest } from 'redux-saga/effects';
import { API_ARROW_INDEX } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import arrowIndexTypes from '../types/arrowIndex.types';

function* getArrowIndex(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        // filter: { "axis": {"_in": payload.params}}
      }
      return api.get(`${API_URL}/${API_ARROW_INDEX}`, _filter)
    });
    yield put(actionSuccess(arrowIndexTypes.GET_ARROW_INDEX_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(arrowIndexTypes.GET_ARROW_INDEX_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(arrowIndexTypes.START_GET_ARROW_INDEX, getArrowIndex),
]

