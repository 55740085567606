
import { IAction } from '../interfaces/root.interfaces';
import meanOfIndexTypes from './types/meanOfIndex.types';

export const getMeanOfIndex = (params: any): IAction => {
  
  const action: IAction = {
    type: meanOfIndexTypes.START_GET_MEAN_OF_INDEX,
    payload: { params },
  }
  return action;
};