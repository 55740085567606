
import { IAction } from '../interfaces/root.interfaces';
import numberOfRepetitionTypes from './types/numberOfRepetition.types';

export const getNumberOfRepetition = (params: any): IAction => {
  
  const action: IAction = {
    type: numberOfRepetitionTypes.START_GET_NUMBER_OF_REPETITION,
    payload: { params },
  }
  return action;
};