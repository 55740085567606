import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  //...
  apiKey: 'AIzaSyAIbLe4R_KiSTBYe1tpGBedpVkfDnWcXrc',
  authDomain: 'numerology-dev-3329d.firebaseapp.com',
  projectId: 'numerology-dev-3329d'
  // local
  // apiKey: 'AIzaSyA5kqvw-FdZjCENgNNFC3BVOBVowTIyIUU',
  // authDomain: 'hieupham-e9dc0.firebaseapp.com',
  // projectId: 'hieupham-e9dc0'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;