import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import moment from "moment";
import "./styles.less";
import { nameWebsite, textVersion } from "src/utils/text";
import { Link } from "react-router-dom";
import routerNames from "src/utils/data/routerName";

import Logo from "src/assets/images/favicon.png";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter className="pth-footer">
      <Row className="p-5">
        <Col xs={24} sm={24} md={9} lg={9} xl={9} className="flex justify-center items-start">
          <div className="flex items-center">
            <img src={Logo} className="img-logo"/>
            <span className="text-xl text-accent_color_3_5 ml-1"><strong>Thương thần số học</strong></span>
          </div>
          <div></div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}  className="pth-footer-left">
          <div className="mb-1">
            <span className="text-xl"><strong>Thông tin chung</strong></span>
          </div>
          <div>
            <Link
              to={routerNames.PRIVACY_POLICY}
            >
              <strong>Chính sách bảo mật</strong>
            </Link>
          </div>
          <div>
            <Link
              to={routerNames.TERM_AND_CONDITION}
            >
              <strong>Điều khoản sử dụng</strong>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9} className="pth-footer-left">
          <div className="mb-1">
            <span className="text-xl"><strong>Liên hệ</strong></span>
          </div>
          <div className="mb-1">
            <strong>Địa chỉ: <span className="text-accent_color_2_1">2225 Phạm Thế Hiển, P.6, Q.8, Hồ Chí Minh</span></strong>
          </div>
          <div className="mb-1">
            <strong>Điện thoại: <span className="text-accent_color_2_1">0349682853</span></strong>
          </div>
          <div className="mb-1">
            <strong>Liên hệ hợp tác: <span className="text-accent_color_2_1">{nameWebsite.toLowerCase()}@gmail.com</span></strong>
          </div>
          {/* <div>
            <span>
              <i>
              Chưa ở đâu có trang web như {nameWebsite.toLowerCase()}. Trang web sử dụng phần mềm tính toán quá phức tạp, 
              quá hay. Thông tin cực kì chi tiết!
              </i>
            </span>
          </div> */}
        </Col>
      </Row>
      <Row className="bg-neutral_color_1_3">
        <div
          className="copyright ml-5 pb-1"
        >
          © {moment().format('YYYY')}, made with
          {<HeartFilled />} by
          <a href="#pablo" className="font-weight-bold" target="_blank">
            {nameWebsite}
          </a>
          for a better web.
        </div>
      </Row>
    </AntFooter>
  );
}

export default Footer;
