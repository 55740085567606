import React, { } from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Numerology",
          data: props.dataSeries || []
        },
      ],
      options: {
        chart: {
          height: 200,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: 'transparent',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          },
          background: 'transparent',
        },
        tooltip: {
          enabled: false,
          enabledOnSeries: undefined,
        },
        colors: ['#4CAF50'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const {dataPointIndex} = opts
            const length = props.dataSeries ? props.dataSeries.length - 1 : 0;
            const _val = props.dataSerieShow[dataPointIndex];
            if(dataPointIndex === 0 || dataPointIndex === length)
              return '';
            return _val
          },
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          show: false,
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: props.dataCategories || [],
          labels: {
            show: true,
            align: 'center',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: props.colorCategories,
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => { return value },
        },
        },
        yaxis: {
          min: 0,
          max: props.maxNum + 2,
          show: false,
        },
        // legend: {
        //   position: 'top',
        //   horizontalAlign: 'right',
        //   floating: true,
        //   offsetY: -25,
        //   offsetX: -5
        // }
      },
    
    
    };
  }



  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={200} />
      </div>
    )
  }
}

export default ApexChart;
