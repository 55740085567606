import { Reducer } from 'redux';
import meanOfIndexTypes from '../actions/types/meanOfIndex.types';
import { IMeanOfIndexState } from '../interfaces/meanOfIndex.interfaces';

import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

const initState: IMeanOfIndexState = {
  stateMeanOfIndex: { ...rootState },
};

const meanOfIndexReducer: Reducer<IMeanOfIndexState, ISyntheticAction>
  = (state: IMeanOfIndexState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       get pyramid Number                    */

      case meanOfIndexTypes.START_GET_MEAN_OF_INDEX: {
        const stateReducer: IMeanOfIndexState = {
          ...state,
          stateMeanOfIndex: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case meanOfIndexTypes.GET_MEAN_OF_INDEX_SUCCESS: {
        const stateReducer: IMeanOfIndexState = {
          ...state,
          stateMeanOfIndex: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case meanOfIndexTypes.GET_MEAN_OF_INDEX_FAILURE: {
        const stateReducer: IMeanOfIndexState = {
          ...state,
          stateMeanOfIndex: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default meanOfIndexReducer;