import routerNames from "src/utils/data/routerName";

export const PHONE = "phone";
export const TEXT = "text";
export const PASSWORD = "password";
export const REPASSWORD = "rePassword";
export const EMAIL = "email";
export const URL = "url";
export const EMPTY = "empty";
export const NEW_PASSWORD = "new-password";
export const PHONEANDEMAIL = 'phoneAndEmail';
export const NUMBER = 'number';
export const OFF = "off";

export const EXCEPT_NUMEROLOGY = [11,22];

export const DATE = "DD/MM/YYYY";
export const DATETIME = "DD/MM/YYYY HH:mm";
export const DATEFULLTIME = "DD/MM/YYYY HH:mm:ss";

export const MESSAGE_VALIDATE = {
  number: {
    type: "number",
    placeholder: "",
    message: "Không được bỏ trống!",
  },
  text: {
    type: "text",
    placeholder: "",
    message: "Không được bỏ trống!",
  },
  phone: {
    type: "number",
    placeholder: "Nhập số điện thoại",
    message: "Vui lòng nhập số điện thoại!",
    errorMess: "Số điện thoại chưa đúng định dạng!",
  },
  email: {
    placeholder: "Nhập email",
    message: "Vui lòng nhập email!",
    errorMess: "Email chưa đúng định dạng!",
  },
  phoneAndEmail: {
    // placeholder: "Nhập số điện thoại hoặc email",
    message: "Vui lòng nhập số điện thoại!",
    // errorMess: "Email chưa đúng định dạng!",
  },
  password: {
    placeholder: "Nhập password",
    message: "Vui lòng nhập password!",
    atleast: "Mật khẩu tối thiểu tám ký tự",
    character:
      "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt",
  },
  rePassword: {
    placeholder: "Nhập lại password",
    message: "Vui lòng nhập password!",
    atleast: "Mật khẩu tối thiểu tám ký tự",
    character:
      "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt",
  },
  url: {
    placeholder: "Nhập url",
    message: "Vui lòng nhập url!",
    errorMess: "URL chưa đúng định dạng",
  },
  empty: {
    placeholder: '',
    message: "Vui lòng nhập đầy đủ!",
  }
};
export const dataTypeCustomer=[
  {value: 1,name: 'Cá nhân'},
  {value: 2,name: 'Doanh nghiệp'},
];
export const dataGroupCustomer=[
  {value: 1,name: 'Khách hàng chuẩn'},
];
export const dataGender = [
  { name: 'Nữ', value: '0' },
  { name: 'Nam', value: '1' },
  { name: 'Khác', value: '2' },
]

export const dataDate = [
  { name: 'Ngày 01', value: '01' },
  { name: 'Ngày 02', value: '02' },
  { name: 'Ngày 03', value: '03' },
  { name: 'Ngày 04', value: '04' },
  { name: 'Ngày 05', value: '05' },
  { name: 'Ngày 06', value: '06' },
  { name: 'Ngày 07', value: '07' },
  { name: 'Ngày 08', value: '08' },
  { name: 'Ngày 09', value: '09' },
  { name: 'Ngày 10', value: '10' },
  { name: 'Ngày 11', value: '11' },
  { name: 'Ngày 12', value: '12' },
  { name: 'Ngày 13', value: '13' },
  { name: 'Ngày 14', value: '14' },
  { name: 'Ngày 15', value: '15' },
  { name: 'Ngày 16', value: '16' },
  { name: 'Ngày 17', value: '17' },
  { name: 'Ngày 18', value: '18' },
  { name: 'Ngày 19', value: '19' },
  { name: 'Ngày 20', value: '20' },
  { name: 'Ngày 21', value: '21' },
  { name: 'Ngày 22', value: '22' },
  { name: 'Ngày 23', value: '23' },
  { name: 'Ngày 24', value: '24' },
  { name: 'Ngày 25', value: '25' },
  { name: 'Ngày 26', value: '26' },
  { name: 'Ngày 27', value: '27' },
  { name: 'Ngày 28', value: '28' },
  { name: 'Ngày 29', value: '29' },
  { name: 'Ngày 30', value: '30' },
  { name: 'Ngày 31', value: '31' },
];
export const dataMonth = [
  { name: 'Tháng 01', value: '01' },
  { name: 'Tháng 02', value: '02' },
  { name: 'Tháng 03', value: '03' },
  { name: 'Tháng 04', value: '04' },
  { name: 'Tháng 05', value: '05' },
  { name: 'Tháng 06', value: '06' },
  { name: 'Tháng 07', value: '07' },
  { name: 'Tháng 08', value: '08' },
  { name: 'Tháng 09', value: '09' },
  { name: 'Tháng 10', value: '10' },
  { name: 'Tháng 11', value: '11' },
  { name: 'Tháng 12', value: '12' },
];
export const dataPakage = [
  { 
    id: 1, 
    name: 'Gói 1 lượt VIP (180,000đ)' 
  },
  { 
    id: 2, 
    name: 'Gói 2 lượt VIP (354,000đ)' 
  },
  { 
    id: 3, 
    name: 'Gói 3 lượt VIP (500,000đ), tặng 1 lượt online' 
  },
  { 
    id: 4, 
    name: 'Gói 5 lượt VIP (800,000đ), tặng 2 lượt online' 
  },
  { 
    id: 5, 
    name: 'Gói 10 lượt VIP (1,500,000đ), tặng 4 lượt online' 
  },
  { 
    id: 6, 
    name: 'Gói 20 lượt VIP (2,60,000đ), tặng 8 lượt online' 
  },
];

export const dataMeanAlphabetChangeToNumber = [
  {
    alphabet: 'A',
    number: 1,
    isVowel: true, // là nguyền âm
  },
  {
    alphabet: 'J',
    number: 1,
    isVowel: false,
  },
  {
    alphabet: 'S',
    number: 1,
    isVowel: false,
  },
  {
    alphabet: 'B',
    number: 2,
    isVowel: false,
  },
  {
    alphabet: 'K',
    number: 2,
    isVowel: false,
  },
  {
    alphabet: 'T',
    number: 2,
    isVowel: false,
  },
  {
    alphabet: 'C',
    number: 3,
    isVowel: false,
  },
  {
    alphabet: 'L',
    number: 3,
    isVowel: false,
  },
  {
    alphabet: 'U',
    number: 3,
    isVowel: true,
  },
  {
    alphabet: 'D',
    number: 4,
    isVowel: false,
  },
  {
    alphabet: 'M',
    number: 4,
    isVowel: false,
  },
  {
    alphabet: 'V',
    number: 4,
    isVowel: false,
  },
  {
    alphabet: 'E',
    number: 5,
    isVowel: true,
  },
  {
    alphabet: 'N',
    number: 5,
    isVowel: false,
  },
  {
    alphabet: 'W',
    number: 5,
    isVowel: false,
  },
  {
    alphabet: 'F',
    number: 6,
    isVowel: false,
  },
  {
    alphabet: 'O',
    number: 6,
    isVowel: true,
  },
  {
    alphabet: 'X',
    number: 6,
    isVowel: false,
  },
  {
    alphabet: 'G',
    number: 7,
    isVowel: false,
  },
  {
    alphabet: 'P',
    number: 7,
    isVowel: false,
  },
  {
    alphabet: 'Y',
    number: 7,
    isVowel: false,
    isCheckBefore: true, // check xem trước nó là phụ âm => nguyên âm
  },
  {
    alphabet: 'H',
    number: 8,
    isVowel: false,
  },
  {
    alphabet: 'W',
    number: 8,
    isVowel: false,
  },
  {
    alphabet: 'Z',
    number: 8,
    isVowel: false,
  },
  {
    alphabet: 'I',
    number: 9,
    isVowel: true,
  },
  {
    alphabet: 'R',
    number: 9,
    isVowel: false,
  },
];

export const dataMeanAlphabetChangeToNumberIsVowel = dataMeanAlphabetChangeToNumber.filter((item: any) => item.isVowel);
export const dataMeanAlphabetChangeToNumberIsNotVowel = dataMeanAlphabetChangeToNumber.filter((item: any) => !item.isVowel);
export const dataMeanAlphabetChangeToNumberSpecial = dataMeanAlphabetChangeToNumber.filter((item: any) => item.isCheckBefore);

export const selectOptionsId = {
  name: 'name',
  value: 'id'
}

export const selectOptionsValue = {
  name: 'name',
  value: 'value'
}
export const screenSignIn = 445;
export const typeScreenManagerSignIn={
  SIGN_IN: 1,
  REGISTER: 2,
  FORGET_PASSWORD: 3,
  CREATE_ACCOUNT_SUCCESS: 4,
  ACTIVE_ACCOUNT_SUCCESS: 5,
  OTP: 6,
  CHANGE_PASSWORD: 7,
  CHANGE_PASSWORD_SUCCESS: 8,
  SEND_OTP: 9,
  SEND_OTP_CHANGEPASS: 10,
};
export const PAGE_SIZE_DEFAULT = 10;
export const dataOptionsStatus = [{ name: 'Hiển thị', value: 'A' }, { name: 'Ẩn', value: 'D' }];
export const routerNotAuth = [
  routerNames.ACTIVE_OTP,
  routerNames.HOME,
  routerNames.SEARCH,
  routerNames.RE_SEARCH
];

export const routerNotFullScreen = [
  routerNames.RE_SEARCH
]
export const statusTag: any = {
  1: {
    color: '#808A94',
    name: 'Chờ lấy hàng'
  },
  2: {
    color: '#38C173',
    name: 'Đã lấy hàng'
  }
}