import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import imageBG from '../../assets/images/BGLogin.jpg';
import SignInPage from './components/signIn';
import Register from './components/register';
import ForgetPassword from './components/forgetPassword';
import CreateAccountSuccess from './components/createAccountSuccess';
import ActiveAccountSuccess from './components/activeAccountSuccess';
import ChangeAccountSuccess from './components/changeAccountSuccess';
import ChangePassword from './components/changePassword';
// import Otp from './components/otp';
import SendOTP from './components/sendOTP';
import './styles.less';
import { typeScreenManagerSignIn } from "src/constants";


const ManagerSignIn = (props: any) => {
  const [renderType, setRenderType] = useState(typeScreenManagerSignIn.SIGN_IN);
  // const refForm = createRef<FormInstance>();

  // const auth = useAuth();
  // const dispatch = useDispatch();
  // const isMount = useIsMount();
  // let history = useHistory();
  // const { width, height } = useWindowDimensions();

  /****************************START**************************/
  /*                         Life Cycle                      */


  useEffect(() => {
    const _checkConfirm = props.location && props.location.state && props.location.state.typeScreen;
    console.log(_checkConfirm);
    if(_checkConfirm){
      setRenderType(+_checkConfirm);
    }
    
  }, []);

  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */

  
  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */
  const changeType = (values: number) => {
    setRenderType(values);
  }

  const _renderForm = () => {
    switch (renderType) {
      case typeScreenManagerSignIn.REGISTER:
        return <Register changeType={changeType}/>
      case typeScreenManagerSignIn.FORGET_PASSWORD:
        return <ForgetPassword changeType={changeType}/>
      case typeScreenManagerSignIn.CREATE_ACCOUNT_SUCCESS:
        return <CreateAccountSuccess/>
      // case typeScreenManagerSignIn.OTP:
      //   return <Otp/>
      case typeScreenManagerSignIn.CHANGE_PASSWORD:
        return <ChangePassword changeType={changeType}/>
      case typeScreenManagerSignIn.ACTIVE_ACCOUNT_SUCCESS:
        return <ActiveAccountSuccess changeType={changeType}/>
      case typeScreenManagerSignIn.CHANGE_PASSWORD_SUCCESS:
        return <ChangeAccountSuccess changeType={changeType}/>
      case typeScreenManagerSignIn.SEND_OTP:
        return <SendOTP isChangePass={false} changeType={changeType}/>
      case typeScreenManagerSignIn.SEND_OTP_CHANGEPASS:
        return <SendOTP isChangePass={true} changeType={changeType}/>
        
      default:
        return <SignInPage changeType={changeType}/>
    }
  }

  /**************************** END **************************/


  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className='flex-col flex items-center justify-start pth-bg-img-login h-screen'>
      {_renderForm()}
    </div>
  )

  /**************************** END **************************/

}

export default ManagerSignIn;