import { call, put, takeLatest } from 'redux-saga/effects';
import { API_CHANGE_PASSWORD, API_CONFIRM_ACCOUNT, API_FORGET_PASSWORD, API_LOGIN, API_REGISTER } from 'src/services/api/url.index';
import { localGetFormLogin } from 'src/utils/localStorage';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import accountTypes from '../types/account.types';

function* signInAccount(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const _params = {
      email: payload.params && payload.params.username,
      password: payload.params && payload.params.password
    }
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_LOGIN}`, _params)
    });
    yield put(actionSuccess(accountTypes.SIGNIN_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.SIGNIN_FAILURE, err));
  }
};
function* registerAccount(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_REGISTER}`, payload.params)
    });
    
    yield put(actionSuccess(accountTypes.REGISTER_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.REGISTER_FAILURE, err));
  }
};

function* forgetAccount(action: IAction) {
  try {
    const { payload } = action;
    const _params = {
      username: payload.params && payload.params.username
    }
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_FORGET_PASSWORD}`, _params)
    });
    yield put(actionSuccess(accountTypes.FORGET_PASSWORD_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.FORGET_PASSWORD_FAILURE, err));
  }
};

function* changeAccount(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_CHANGE_PASSWORD}`, payload.params)
    });
    
    yield put(actionSuccess(accountTypes.CHANGE_PASSWORD_SUCCESS, res));
  } catch (err: ICatchError | any) {
    
    yield put(actionFailure(accountTypes.CHANGE_PASSWORD_FAILURE, err));
  }
};
function* confimAccount(action: IAction) {
  try {
    const { payload } = action;
    
    const res: IRootResponse = yield call(() => {
      return api.patch(`${API_URL}/${API_CONFIRM_ACCOUNT}/${payload.params.id}`, {status: "active"})
    });
    yield put(actionSuccess(accountTypes.CONFIRM_ACCOUNT_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.CONFIRM_ACCOUNT_FAILURE, err));
  }
};
function* getInfoAccount(action: IAction) {
  try {
    let _formLogin = localGetFormLogin();
    let _userName = ''
    if (_formLogin) {
      const _dataUser = JSON.parse(_formLogin);
      _userName = _dataUser.username
    }
    const _params = {
      search: _userName
    }
    
    const res: IRootResponse = yield call(() => {
      return api.get(`${API_URL}/${API_CONFIRM_ACCOUNT}`, _params)
    });
    yield put(actionSuccess(accountTypes.GET_INFO_ACCOUNT_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.GET_INFO_ACCOUNT_FAILURE, err));
  }
};
function* updateInfoAccount(action: IAction) {
  try {
    const { payload } = action;

    const _params = {
      keys: [payload.params?.id],
      data: {
        full_name: payload.params?.full_name
      }
    }    
    
    const res: IRootResponse = yield call(() => {
      return api.patch(`${API_URL}/${API_CONFIRM_ACCOUNT}`, _params)
    });
    yield put(actionSuccess(accountTypes.UPDATE_INFO_ACCOUNT_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(accountTypes.UPDATE_INFO_ACCOUNT_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(accountTypes.START_SIGNIN, signInAccount),
  takeLatest(accountTypes.START_REGISTER, registerAccount),
  takeLatest(accountTypes.START_FORGET_PASSWORD, forgetAccount),
  takeLatest(accountTypes.START_CHANGE_PASSWORD, changeAccount),
  takeLatest(accountTypes.START_CONFIRM_ACCOUNT, confimAccount),
  takeLatest(accountTypes.START_GET_INFO_ACCOUNT, getInfoAccount),
  takeLatest(accountTypes.START_UPDATE_INFO_ACCOUNT, updateInfoAccount),
]

