/* eslint-disable */
import { Col, Row } from "antd";
import { nameWebsite } from "src/utils/text";

const TermAndCondition = () => {
  
  /****************************START**************************/
  /*                         Life Cycle                      */

  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className="mt-3 mb-3">
      <Row gutter={[8,8]}>
        <Col span={24} className='flex justify-center'>
          <div className="w-4/5 px-2 py-2">
            <div className="py-2">
              <span className="text-xl"><strong>ĐIỀU KHOẢN & ĐIỀU KIỆN SỬ DỤNG của {nameWebsite}.VN</strong></span>
            </div>
            <div className="py-2">
              <span>
              Các điều khoản và điều kiện sau đây chi phối tất cả việc sử dụng trang web <strong>{nameWebsite}.VN 
              (Trang web)</strong> và tất cả nội dung, dịch vụ và sản phẩm có sẵn tại hoặc thông qua trang web 
              <strong> (Sản phẩm / Dịch vụ liên quan)</strong>. Trang web được sở hữu và điều hành bởi Team 
              <strong> {nameWebsite}</strong>, LLC (sau đây được gọi chung là <strong> {nameWebsite}</strong>), và các Sản phẩm / 
              Dịch vụ liên quan của nó được cung cấp theo sự chấp nhận của bạn mà không cần sửa đổi tất cả các 
              điều khoản và điều kiện có trong đây cũng như tất cả các quy tắc, chính sách hoạt động khác 
              (bao gồm, nhưng không giới hạn, Chính sách quyền riêng tư của <strong> {nameWebsite}</strong>) và các thủ tục có thể 
              được <strong> {nameWebsite}</strong> công bố bất cứ lúc nào trên Trang web này (gọi chung là <strong>Thỏa thuận</strong>).
              </span>
            </div>
            <div className="py-2">
              <span>
              Vui lòng đọc kỹ Thỏa thuận này trước khi truy cập hoặc sử dụng Trang web và các Sản phẩm / Dịch vụ 
              liên quan. Bằng cách truy cập hoặc sử dụng bất kỳ phần nào của Trang web hoặc sử dụng các dịch vụ 
              của Trang web, bạn (<strong>Người dùng</strong> hoặc <strong>Bạn</strong>) đồng ý bị ràng buộc bởi 
              các điều khoản và điều kiện của Thỏa thuận này. Nếu bạn không đồng ý với tất cả các điều khoản và 
              điều kiện của Thỏa thuận này, thì bạn không thể truy cập Trang web hoặc sử dụng bất kỳ dịch vụ nào 
              của Trang web. Nếu các điều khoản và điều kiện này được <strong> {nameWebsite}</strong> coi là một 
              đề nghị, thì việc chấp nhận được giới hạn rõ ràng đối với các điều khoản này. Trang web và các dịch 
              vụ của nó chỉ dành cho những cá nhân từ 13 tuổi trở lên.
              </span>
            </div>
            <div className="py-2">
              <strong><span className="text-accent_color_4_2">
              TỪ CHỐI TRÁCH NHIỆM: <strong>{nameWebsite}</strong> CHỈ CUNG CẤP DỊCH VỤ ĐỌC SỐ CHO MỤC ĐÍCH GIẢI 
              TRÍ. THÔNG TIN CÓ TRÊN TRANG WEB HOẶC TRONG BẤT KỲ EMAIL HOẶC TÀI LIỆU NÀO NHẬN ĐƯỢC TỪ TRANG WEB 
              KHÔNG ĐƯỢC ĐẢM BẢO BẤT CỨ CÁCH NÀO. <strong>{nameWebsite}.VN</strong> KHÔNG THUỘC VỀ BẤT KỲ THÔNG 
              TIN NÀO NÓ CUNG CẤP ĐƯỢC ĐỘC QUYỀN LIÊN QUAN ĐẾN TRỞ LÊN VÀ KHÔNG CHỊU TRÁCH NHIỆM VỀ VIỆC BẠN ĐƠN 
              XIN LỖI HOẶC GIẢI THÍCH THÔNG TIN NÀY. NGƯỜI SỬ DỤNG TRANG WEB NÀY CÓ TRÁCH NHIỆM VỀ VIỆC SỬ DỤNG 
              Ý NGHĨA THÔNG DỤNG CỦA MÌNH KHI ĐỌC BẤT KỲ THÔNG TIN NÀO ĐƯỢC CUNG CẤP CHO HỌ, VÀ ĐỂ TƯ VẤN CHUYÊN 
              NGÀNH Y TẾ HOẶC PHÁP LUẬT PHÊ DUYỆT CHO BẤT KỲ VẬT LÝ, TÂM LÝ HOẶC PHÁP LÝ NGHIÊM TRỌNG NÀO CŨNG CÓ 
              THỂ PHÁT HIỆN ĐƯỢC.
              </span></strong>
            </div>
            <div className="py-2">
              <ol>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Sở hữu trí tuệ. </span></strong>
                    Thỏa thuận này không chuyển từ <strong>{nameWebsite}</strong> cho bạn bất kỳ quyền sở hữu trí 
                    tuệ nào của <strong>{nameWebsite}</strong> hoặc bên thứ ba. Tất cả các quyền, quyền sở hữu và 
                    lợi ích đối với và bất kỳ tài sản trí tuệ nào sẽ chỉ thuộc về <strong>{nameWebsite}</strong>. 
                    Tên <strong>{nameWebsite}.VN</strong> và tất cả các nhãn hiệu, nhãn hiệu dịch vụ, đồ họa và 
                    biểu trưng khác, được sử dụng liên quan đến <strong>{nameWebsite}</strong> là tài sản độc quyền 
                    của <strong>{nameWebsite}</strong>. Các nhãn hiệu, nhãn hiệu dịch vụ, đồ họa và biểu tượng khác 
                    được sử dụng trên Trang web có thể là tài sản của các bên thứ ba. Việc bạn sử dụng Trang web 
                    không cấp cho bạn quyền hoặc giấy phép để tái sản xuất hoặc sử dụng bất kỳ Dấu hiệu số học hoặc 
                    bên thứ ba nào khác.
                    <div className="pl-2 pt-1">
                      <strong>
                      - <span className="text-accent_color_4_2">Tuyên bố từ chối trách nhiệm về bản quyền: </span>
                      {nameWebsite} giữ bản quyền đối với tất cả văn bản, dữ liệu, hình ảnh, âm thanh, video, mẫu 
                      hoặc các tài liệu khác trên trang web này. Bạn không được phân phối, sửa đổi, truyền tải, tái 
                      sử dụng, báo cáo hoặc sử dụng nội dung của trang web, bao gồm văn bản, dữ liệu, hình ảnh, âm 
                      thanh, video và các tài liệu khác, cho mục đích công cộng hoặc thương mại mà không có sự cho 
                      phép bằng văn bản của {nameWebsite}.
                      </strong>
                    </div>
                    <div className="pl-2 pt-1">
                      <strong>
                      - <span className="text-accent_color_4_2">Tuyên bố từ chối trách nhiệm về nhãn hiệu: </span>
                      Tên sản phẩm, biểu tượng, nhãn hiệu và các nhãn hiệu khác được giới thiệu hoặc đề cập trong 
                      trang web hoặc các sản phẩm và dịch vụ khác của {nameWebsite} là tài sản của chủ sở hữu nhãn 
                      hiệu tương ứng của họ. Các chủ sở hữu nhãn hiệu này không liên kết với {nameWebsite}, các sản 
                      phẩm của chúng tôi hoặc trang web của chúng tôi. Họ đã không tài trợ hoặc xác nhận tài liệu 
                      của chúng tôi
                      </strong>
                    </div>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Các thay đổi. </span></strong>
                    <strong>{nameWebsite}</strong> bảo lưu quyền, theo quyết định riêng của mình, sửa đổi hoặc 
                    thay thế bất kỳ phần nào của Thỏa thuận này. Bạn có trách nhiệm kiểm tra tạm thời Thỏa thuận 
                    này để biết các thay đổi. Việc bạn tiếp tục sử dụng hoặc truy cập vào Trang web sau khi đăng 
                    bất kỳ thay đổi nào đối với Thỏa thuận này đồng nghĩa với việc bạn chấp nhận những thay đổi đó. 
                    <strong>{nameWebsite}</strong> cũng có thể, trong tương lai, cung cấp các dịch vụ và / hoặc tính 
                    năng mới thông qua Trang web (bao gồm cả việc phát hành các công cụ và tài nguyên mới). Các tính 
                    năng và / hoặc dịch vụ mới như vậy sẽ tuân theo các điều khoản và điều kiện của Thỏa thuận này.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Chấm dứt. </span></strong>
                    <strong>{nameWebsite}</strong> có thể chấm dứt quyền truy cập của bạn vào tất cả hoặc bất kỳ 
                    phần nào của Trang web và Sản phẩm / Dịch vụ liên quan bất kỳ lúc nào, có hoặc không có nguyên 
                    nhân, có hoặc không có thông báo, có hiệu lực ngay lập tức. Nếu bạn muốn chấm dứt Thỏa thuận này, 
                    bạn có thể chỉ cần ngừng sử dụng Trang web và Sản phẩm / Dịch vụ có liên quan và cho chúng tôi 
                    biết bằng cách gửi email cho chúng tôi như được cung cấp trong Chính sách bảo mật. <strong>{nameWebsite}</strong> 
                    có thể chấm dứt Trang web và hoặc Sản phẩm / Dịch vụ liên quan ngay lập tức như một phần của 
                    việc ngừng cung cấp dịch vụ của chúng tôi. <strong>{nameWebsite}</strong> có quyền ngừng (toàn 
                    bộ hoặc một phần) quyền truy cập của bạn vào Trang web nếu có lý do để tin rằng hành động của 
                    bạn có thể gây ra trách nhiệm pháp lý đối với <strong>{nameWebsite}</strong> hoặc Trang web. 
                    Ngoài ra, <strong>{nameWebsite}</strong> có quyền ngừng (toàn bộ hoặc một phần) quyền truy cập 
                    của bạn vào Trang web nếu không thể xác minh thông tin bạn đã cung cấp cho trang web hoặc nếu 
                    họ cho rằng bạn đã cung cấp thông tin sai lệch hoặc gây hiểu lầm. Tất cả Thỏa thuận này, về bản 
                    chất vẫn có hiệu lực sau khi chấm dứt, sẽ vẫn còn hiệu lực sau khi chấm dứt, bao gồm nhưng không 
                    giới hạn, quyền sở hữu, tuyên bố từ chối trách nhiệm, bồi thường và giới hạn trách nhiệm pháp lý. 
                    Việc vi phạm bất kỳ điều khoản nào của Thỏa thuận Điều khoản Dịch vụ này hoặc Chính sách Bảo mật 
                    của Trang web cũng sẽ dẫn đến việc bạn bị chấm dứt quyền truy cập vào Trang web.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Từ chối bảo hành. </span></strong>
                    Trong phạm vi luật hiện hành cho phép, Trang web và Sản phẩm / Dịch vụ có liên quan được cung 
                    cấp <strong>nguyên trạng</strong>. <strong>{nameWebsite}</strong> không có trách nhiệm pháp lý 
                    nào đối với nội dung của trang web. <strong>{nameWebsite}</strong> và các đối tác cũng như người 
                    cấp phép của nó từ chối mọi bảo đảm dưới bất kỳ hình thức nào, rõ ràng hay ngụ ý, bao gồm nhưng 
                    không giới hạn, bảo đảm về khả năng bán được, tính phù hợp cho một mục đích cụ thể và không vi 
                    phạm. Cả <strong>{nameWebsite}</strong> cũng như các nhà cung cấp và người cấp phép của nó đều 
                    không đưa ra bất kỳ bảo đảm nào rằng Trang web và Sản phẩm / Dịch vụ có liên quan sẽ không có lỗi 
                    hoặc việc truy cập vào đó sẽ liên tục hoặc không bị gián đoạn. Bạn hiểu rằng bạn tải xuống từ hoặc 
                    lấy nội dung hoặc dịch vụ thông qua Trang web theo quyết định và rủi ro của riêng bạn. 
                    <strong>{nameWebsite}</strong> không đảm bảo hoặc tuyên bố về việc sử dụng hoặc kết quả của việc sử 
                    dụng các tài liệu và thông tin trên trang web này về tính đúng đắn, chính xác, độ tin cậy của chúng 
                    hoặc các hình thức khác. Không có lời khuyên, thông tin, dự đoán, đánh giá, gợi ý, khuyến nghị hoặc 
                    thông tin liên lạc khác từ Trang web sẽ tạo ra bất kỳ bảo hành nào dưới bất kỳ hình thức nào. 
                    <strong><span className="text-accent_color_4_2">TẤT CẢ THÔNG TIN CÓ TRONG TRANG WEB NÀY CHỈ LÀ MỤC ĐÍCH 
                    GIẢI TRÍ. SỰ CHẤP NHẬN, SỰ TIN CẬY HOẶC BẤT KỲ NGHIÊM TRỌNG CỦA BẤT KỲ CỘNG TÁC VIÊN NÀO TỪ TRANG WEB 
                    ĐỀU CÓ RỦI RO CỦA RIÊNG BẠN.</span></strong>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Trách nhiệm hữu hạn. </span></strong>
                    Trong phạm vi được luật hiện hành cho phép, trong mọi trường hợp, <strong>{nameWebsite}</strong>, 
                    hoặc các nhà cung cấp hoặc người cấp phép của <strong>{nameWebsite}</strong>, sẽ không chịu 
                    trách nhiệm pháp lý đối với bất kỳ vấn đề nào của Thỏa thuận này theo bất kỳ hợp đồng nào, sơ 
                    suất, trách nhiệm pháp lý nghiêm ngặt hoặc lý thuyết pháp lý hoặc công bằng khác đối với: 
                    <div className="pl-2 pt-1">
                      <strong>
                      (i) bất kỳ thiệt hại đặc biệt, ngẫu nhiên hoặc do hậu quả nào;
                      </strong>
                    </div>
                    <div className="pl-2 pt-1">
                      <strong>
                      (ii) chi phí mua sắm hoặc sản phẩm hoặc dịch vụ thay thế;
                      </strong>
                    </div>
                    <div className="pl-2 pt-1">
                      <strong>
                      (iii) để gián đoạn việc sử dụng hoặc làm mất hoặc hỏng dữ liệu;
                      </strong>
                    </div>
                    <div className="pl-2 pt-1">
                      <strong>
                      (iv) đối với bất kỳ số tiền nào vượt quá phí mà bạn đã trả cho {nameWebsite} theo thỏa thuận 
                      này trong khoảng thời gian mười hai (12) tháng trước khi xảy ra hành động.
                      </strong>
                    </div>
                    <div className="pt-1">
                      <span>
                      Hơn nữa, <strong>{nameWebsite}</strong> cũng như các đơn vị liên kết hoặc có liên quan hoặc 
                      các nhà cung cấp nội dung của nó đều không chịu trách nhiệm hoặc nghĩa vụ pháp lý đối với 
                      bất kỳ cá nhân hoặc tổ chức nào (bao gồm nhưng không giới hạn những người có thể sử dụng 
                      hoặc dựa vào dữ liệu / tài liệu đó hoặc người cung cấp dữ liệu / tài liệu đó cho ) đối với 
                      bất kỳ tổn thất, thiệt hại nào (cho dù thực tế, do hậu quả, trừng phạt hay cách khác), 
                      thương tích, yêu cầu bồi thường, trách nhiệm pháp lý hoặc nguyên nhân khác của bất kỳ loại 
                      đặc điểm nào dựa trên hoặc kết quả từ bất kỳ thông tin, tài liệu hoặc ý kiến ​​nào được cung 
                      cấp trên trang web. Trong mọi trường hợp, <strong>{nameWebsite}</strong> sẽ không chịu trách 
                      nhiệm pháp lý đối với các thiệt hại đặc biệt, gián tiếp, ví dụ hoặc do hậu quả hoặc bất kỳ 
                      thiệt hại nào, bao gồm nhưng không giới hạn, mất quyền sử dụng, dữ liệu hoặc lợi nhuận, bất 
                      kể hình thức của bất kỳ hành động nào, bao gồm nhưng không giới hạn hợp đồng , sơ suất, hoặc 
                      các hành động quanh co khác, tất cả phát sinh từ hoặc liên quan đến việc sử dụng, sao chép 
                      hoặc hiển thị hoặc nội dung của trang web này. <strong>{nameWebsite}</strong> sẽ không có 
                      trách nhiệm đối với bất kỳ sự thất bại hoặc chậm trễ nào do những vấn đề nằm ngoài tầm kiểm 
                      soát hợp lý của họ. Những điều đã nói ở trên sẽ không áp dụng trong phạm vi bị cấm bởi luật 
                      hiện hành.
                      </span>
                    </div>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Đại diện và Bảo hành chung. </span></strong>
                    Bạn tuyên bố và đảm bảo rằng 
                    <div className="pl-2 pt-1">
                      <strong>
                      (i) việc bạn sử dụng Trang web và Sản phẩm / Dịch vụ có liên quan sẽ tuân thủ nghiêm ngặt 
                      Chính sách bảo mật của {nameWebsite}.VN, với Thỏa thuận này và với tất cả các luật và quy 
                      định hiện hành (bao gồm nhưng không giới hạn bất kỳ luật hoặc quy định địa phương nào ở 
                      quốc gia, tiểu bang, thành phố hoặc khu vực chính phủ khác của bạn, về hành vi trực tuyến 
                      và nội dung được chấp nhận, và bao gồm tất cả các luật hiện hành liên quan đến việc truyền 
                      dữ liệu kỹ thuật được xuất từ ​​Hoa Kỳ hoặc quốc gia mà bạn cư trú)
                      </strong>
                    </div>
                    <div className="pl-2 pt-1">
                      <strong>
                      (ii) việc sử dụng của bạn của Trang web và Sản phẩm / Dịch vụ có liên quan sẽ không vi 
                      phạm hoặc chiếm đoạt quyền sở hữu trí tuệ của bất kỳ bên thứ ba nào.
                      </strong>
                    </div>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Sự bồi thường. </span></strong>
                    Bạn đồng ý bồi thường và giữ cho <strong>{nameWebsite}</strong> vô hại, các đối tác, công ty 
                    con, nhà thầu, người cấp phép và giám đốc, cán bộ, nhân viên và đại lý tương ứng của họ khỏi 
                    và chống lại bất kỳ và tất cả các khiếu nại và chi phí, bao gồm cả phí luật sư, phát sinh từ 
                    việc bạn sử dụng Trang web và Sản phẩm / Dịch vụ có liên quan, bao gồm nhưng không giới hạn 
                    việc bạn vi phạm Thỏa thuận này.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Điều khoản khác. </span></strong>
                    Thỏa thuận này cấu thành toàn bộ thỏa thuận giữa <strong>{nameWebsite}</strong> và bạn liên 
                    quan đến chủ đề của hợp đồng này và chúng chỉ có thể được sửa đổi bằng văn bản sửa đổi được 
                    ký bởi người điều hành được ủy quyền của <strong>{nameWebsite}</strong> hoặc bằng việc 
                    <strong>{nameWebsite}</strong> đăng phiên bản sửa đổi. Ngoại trừ phạm vi luật hiện hành, nếu 
                    có, quy định khác, Thỏa thuận này, mọi quyền truy cập hoặc sử dụng Trang web và Sản phẩm / 
                    Dịch vụ có liên quan sẽ chịu sự điều chỉnh của luật pháp của bang California, Hoa Kỳ, ngoại trừ 
                    xung đột của các quy định pháp luật, và địa điểm thích hợp cho bất kỳ tên nào phát sinh từ hoặc 
                    liên quan đến bất kỳ tên nào giống nhau sẽ là các tòa án liên bang và tiểu bang đặt tại Hạt San 
                    Diego, California. Ngoại trừ các yêu cầu bồi thường theo lệnh hoặc công bằng hoặc các khiếu nại 
                    liên quan đến quyền sở hữu trí tuệ (có thể được đưa ra bất kỳ tòa án có thẩm quyền nào mà không 
                    cần đăng ký kết), mọi tranh chấp phát sinh theo Thỏa thuận này cuối cùng sẽ được giải quyết theo 
                    Quy tắc Trọng tài Toàn diện của Dịch vụ Hòa giải và Trọng tài Tư pháp, Inc. <strong>(JAMS)</strong> 
                    bởi ba trọng tài viên được chỉ định theo Quy tắc đó. Trọng tài sẽ diễn ra tại San Diego, California, 
                    bằng tiếng Anh và quyết định của trọng tài có thể được thi hành tại bất kỳ tòa án nào. Bất kể kết quả 
                    của bất kỳ hành động hoặc thủ tục nào, bạn sẽ không được hưởng chi phí hoặc phí luật sư vào thời điểm 
                    nào. Nếu bất kỳ phần nào của Thỏa thuận này không hợp lệ hoặc không thể thi hành, các phần còn lại sẽ 
                    vẫn có hiệu lực đầy đủ. Việc một bên từ bỏ bất kỳ điều khoản hoặc điều kiện nào của Thỏa thuận này 
                    hoặc bất kỳ vi phạm nào trong đó, trong bất kỳ trường hợp nào, sẽ không từ bỏ điều khoản hoặc điều 
                    kiện đó hoặc bất kỳ vi phạm nào sau đó. Bạn không được chuyển nhượng các quyền của mình theo Thỏa thuận 
                    này cho bất kỳ bên nào; <strong>{nameWebsite}</strong> có thể chuyển nhượng các quyền của mình theo Thỏa 
                    thuận này mà không cần điều kiện. Thỏa thuận này sẽ ràng buộc và có hiệu lực vì lợi ích của các bên, 
                    những người kế thừa của họ và những người được chuyển nhượng được phép.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Trang web và nội dung của bên thứ ba. </span></strong>
                    Trang web có thể chứa các liên kết đến các trang web khác <strong>(Trang web của bên thứ ba)</strong> 
                    dưới dạng văn bản, đồ họa, bài báo biểu tượng, ảnh, hình ảnh, thiết kế, âm nhạc, âm thanh, video, 
                    thông tin, phần mềm và các nội dung khác thuộc về hoặc có nguồn gốc từ thứ ba các bữa tiệc. Thông tin 
                    và nội dung từ Trang web của Bên thứ ba không được <strong>{nameWebsite}</strong> xác minh hoặc xem 
                    xét trước khi đưa vào Trang web. <strong>{nameWebsite}</strong> không chịu trách nhiệm về tính chính 
                    xác, đầy đủ hoặc đúng đắn của bất kỳ nội dung nào từ các trang web của Bên thứ ba. Bạn chấp nhận rằng 
                    việc sử dụng Trang web của bên thứ ba là quyết định của riêng bạn và do bạn tự chịu rủi ro. Việc bao 
                    gồm bất kỳ thông tin nào từ Trang web của bên thứ ba không phải là sự chấp thuận hoặc xác nhận của 
                    Trang web đó bởi <strong>{nameWebsite}</strong>. Nếu bạn rời khỏi <strong>{nameWebsite}</strong>.VN và 
                    chuyển sang Trang web của Bên thứ ba, Điều khoản Dịch vụ và Chính sách Bảo mật của Trang web này sẽ 
                    không còn được áp dụng nữa. Bạn có trách nhiệm xem xét các điều khoản và chính sách hiện hành, bao gồm 
                    chính sách thu thập / chia sẻ dữ liệu, của bất kỳ Trang web của Bên thứ ba nào mà bạn chọn để điều 
                    hướng đến.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Các bên khác. </span></strong>
                    Bạn chấp nhận rằng, với tư cách là một pháp nhân trách nhiệm hữu hạn, <strong>{nameWebsite}</strong> 
                    có lợi ích trong việc giới hạn trách nhiệm cá nhân của các cán bộ và nhân viên của mình. Bạn đồng ý 
                    rằng bạn sẽ không đưa ra bất kỳ yêu cầu cá nhân nào chống lại các cán bộ hoặc nhân viên của 
                    <strong>{nameWebsite}</strong> về bất kỳ tổn thất nào mà bạn phải chịu liên quan đến trang web. Không 
                    ảnh hưởng đến đoạn trên, bạn đồng ý rằng các giới hạn của bảo đảm và trách nhiệm pháp lý được nêu 
                    trong Thỏa thuận này sẽ bảo vệ các cán bộ, nhân viên, đại lý, người kế nhiệm, người được giao và nhà 
                    thầu phụ của <strong>{nameWebsite}</strong>.
                    <div className="pt-1">
                      <strong>
                      Thanh toán định kỳ. Khách hàng sẽ nhận được thông báo qua email mỗi khi tính phí thanh toán định kỳ 
                      hàng tháng, khi mua các sản phẩm hoặc dịch vụ dựa trên đăng ký.
                      </strong>
                    </div>
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  /**************************** END **************************/
};

export default TermAndCondition;
