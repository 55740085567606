import { call, put, takeLatest } from 'redux-saga/effects';
import { API_HISTORY_SEARCH } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import historySearchTypes from '../types/historySearch.types';

function* getHistorySearch(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        filter: { user_id: payload.params},
        sort: "-created_at",
      }
      return api.get(`${API_URL}/${API_HISTORY_SEARCH}`, _filter)
    });
    yield put(actionSuccess(historySearchTypes.GET_HISTORY_SEARCH_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(historySearchTypes.GET_HISTORY_SEARCH_FAILURE, err));
  }
};

function* getOneHistorySearch(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.get(`${API_URL}/${API_HISTORY_SEARCH}/${payload.id}`)
    });
    yield put(actionSuccess(historySearchTypes.GET_ONE_HISTORY_SEARCH_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(historySearchTypes.GET_ONE_HISTORY_SEARCH_FAILURE, err));
  }
};

function* createHistorySearch(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      return api.post(`${API_URL}/${API_HISTORY_SEARCH}`,payload.params)
    });
    yield put(actionSuccess(historySearchTypes.CREATE_HISTORY_SEARCH_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(historySearchTypes.CREATE_HISTORY_SEARCH_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(historySearchTypes.START_GET_HISTORY_SEARCH, getHistorySearch),
  takeLatest(historySearchTypes.START_GET_ONE_HISTORY_SEARCH, getOneHistorySearch),
  takeLatest(historySearchTypes.START_CREATE_HISTORY_SEARCH, createHistorySearch),
]

