
import { IAction } from '../interfaces/root.interfaces';
import historySearchTypes from './types/historySearch.types';

export const getHistorySearch = (params: any): IAction => {
  
  const action: IAction = {
    type: historySearchTypes.START_GET_HISTORY_SEARCH,
    payload: { params },
  }
  return action;
};

export const getOneHistorySearch = (id: any): IAction => {
  
  const action: IAction = {
    type: historySearchTypes.START_GET_ONE_HISTORY_SEARCH,
    payload: { id },
  }
  return action;
};

export const createOneHistorySearch = (params: any): IAction => {
  
  const action: IAction = {
    type: historySearchTypes.START_CREATE_HISTORY_SEARCH,
    payload: { params },
  }
  return action;
};