const historySearchTypes = {
  START_GET_HISTORY_SEARCH: 'START_GET_HISTORY_SEARCH',
  GET_HISTORY_SEARCH_SUCCESS: 'GET_HISTORY_SEARCH_SUCCESS',
  GET_HISTORY_SEARCH_FAILURE: 'GET_HISTORY_SEARCH_FAILURE',
  // create history search
  START_CREATE_HISTORY_SEARCH: 'START_CREATE_HISTORY_SEARCH',
  CREATE_HISTORY_SEARCH_SUCCESS: 'CREATE_HISTORY_SEARCH_SUCCESS',
  CREATE_HISTORY_SEARCH_FAILURE: 'CREATE_HISTORY_SEARCH_FAILURE',
  // get one history search
  START_GET_ONE_HISTORY_SEARCH: 'START_GET_ONE_HISTORY_SEARCH',
  GET_ONE_HISTORY_SEARCH_SUCCESS: 'GET_ONE_HISTORY_SEARCH_SUCCESS',
  GET_ONE_HISTORY_SEARCH_FAILURE: 'GET_ONE_HISTORY_SEARCH_FAILURE',
}

export default historySearchTypes;