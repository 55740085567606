import axios from "axios";
import { localGetToken } from "src/utils/localStorage";
import {
  postMethod,
  removeFalsy,
} from "../../utils/helpers/functions/textUtils";
import { ICatchError } from "../interfaces/root.interfaces";
import { API_URL } from "./config";
import fs from "fs";
import configureStore from "../store";
import { putSignIn } from "../actions/global.actions";

const addHeaders = async (
  url: string,
  options: object,
  dataHeaders: any,
  isAuth: boolean
) => {
  let headers: any = {
    "Content-Type": "application/json",
  };
  let token = localGetToken();
  if (isAuth && token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (dataHeaders) {
    headers = { ...headers, ...dataHeaders };
  }

  return axios({ url: `${url}`, headers: headers, ...options });
};

const apiWithPayload =
  (method: string, isAuth: boolean) =>
    (url: string, payload?: object, token?: string) => {
      return new Promise((resolve, reject) => {
        addHeaders(
          url,
          {
            method,
            data: method !== "GET" ? payload : postMethod(payload),
            params: method === "GET" ? payload || "" : "",
          },
          undefined,
          isAuth || false
        )
          .then((response) => {
            const _data = response.data ? response.data : {
              data: null,
              message: 'Thành công',
              statusCode: 200
            }
            resolve(_data);
          })
          .catch((err) => {
            let catchError: ICatchError = handleError({ ...err.response, url: url });
            reject(catchError);
          });
      });
    };

const apiWithFormData =
  (method: string, isAuth: boolean) =>
    async (url: string, formData: object) => {
      let _data = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value?.length > 0) {
          value.forEach((item: any) => {
            _data.append(key, item);
          });
        } else _data.append(key, value);
      });
      
      return new Promise((resolve, reject) => {
        addHeaders(
          url,
          {
            method,
            data: _data,
          },
          {
            // 'Accept': 'multipart/form-data',
            "content-type": "multipart/form-data",
            // 'accept-encoding': 'gzip',
          },
          isAuth || false
        )
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            let catchError: ICatchError = handleError(err.response);
            reject(catchError);
          });
      });
    };

const handleError = (err: any) => {
  let resultError;
  if (err?.data) {
    // resultError = {
    //   message: err?.data?.message,
    //   statusCode: err?.data?.statusCode,
    // };
    resultError = {
      message: err?.data?.errors ? err?.data?.errors.map((e: any) => e.message).join(', ') : 'Xảy ra lỗi',
      statusCode: err?.status,
    };
  } else {
    resultError = {
      message: `${err?.url ? (err?.url + ', lỗi hệ thống !') : 'Lỗi hệ thống !'}`,
      statusCode: 500,
    };
  }
  //token exprired
  if (err?.status === 408)
    configureStore().dispatch(putSignIn(false));
  return resultError;
};

export const api = {
  get: apiWithPayload("GET", true),
  getNoAuth: apiWithPayload("GET", false),
  delete: apiWithPayload("DELETE", false),
  post: apiWithPayload("POST", true),
  put: apiWithPayload("PUT", true),
  postMultipart: apiWithFormData("POST", true),
  patch: apiWithPayload("PATCH", true),
  // putMultipart: apiWithFormData('PUT')
};
