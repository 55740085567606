import { Col, Tag } from "antd";
import { useState } from "react";
import CustomTable from "src/components/table/CustomTable";
import colors from "src/utils/colors";
import { columnsYearIndex } from "./data";

import "./styles.less";

const CardPersonalYear = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center' id='card_index_year'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CÁC CHỈ SỐ NĂM</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của những con số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Những con số này cho biết ở mỗi năm bạn nên tập trung định hướng phát triển theo con số nào. 
                  Thường thì cuộc đời sẽ tự đẩy bạn đi theo những con số này. Nếu đi lệch ra bạn thường sẽ bị 
                  cảm thấy cuộc sống mất cân bằng hoặc bất an. Còn nếu đi đúng hướng bạn thường cảm thấy 
                  rất bình an và thuận lợi.
                  </i>
                </div>
                <div>
                  <i>
                    Lưu ý: Mỗi năm bạn vào lại website tra cứu hoặc tải lại file để xem luận giải {props.amount} năm tiếp 
                    theo và các nội dung luận giải mới nếu có! <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
          <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
            <div className="flex justify-center">
              <div className="pth-content-1-3 text-center">
                <CustomTable
                  rowKey={"id"}
                  bordered
                  showHeader={false}
                  columns={columnsYearIndex()}
                  dataSource={props.dataTable}
                  loading={false}
                  pagination={false}
                  className="pth-table-history"
                />
              </div>
            </div>
            {
              !showDescription && (
                <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                  onClick={() => setShowDescription(true)}>
                  <span>Xem giải thích con số năm của bạn</span>
                </Tag>
              )
            }
            {
              showDescription && (
                <div className="mb-2">
                  {
                    props.dataDesc.map((item: any) => {
                      return(
                        <>
                          <div>
                            <h3><strong>{item.title} <span className="text-accent_color_2_1 ml-2">{item.number}</span></strong></h3>
                          </div>
                          {
                            item.description.map((itemChild: any) => {
                              return(
                                <div>
                                  <i>{itemChild}</i>
                                </div>
                              )
                            })
                          }
                        </>
                      )
                    })
                  }
                  
                  <div className="mb-1">
                    <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                      onClick={() => setShowDescription(false)}>
                      <span>Ẩn giải thích</span>
                    </Tag>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardPersonalYear;
