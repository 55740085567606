
import moment from 'moment';
import { dataMeanAlphabetChangeToNumber, dataMeanAlphabetChangeToNumberIsNotVowel, dataMeanAlphabetChangeToNumberIsVowel, dataMeanAlphabetChangeToNumberSpecial, EXCEPT_NUMEROLOGY } from "src/constants";
import { arrowStrength, dataNumberShowChart, masterArrayNumber, setColStrengthIndex } from "src/pages/re-search/components/data";
import { removeSign, removeSpaceInString } from "./textUtils";

export const calNumberData = (data: any) => {
  const _data: number[] = getArrayNumberByNumber(data);
  
  const _desc: string = _data.join('+');
  const _number = _data.reduce((sum, value) => sum + value,0);

  return {
    number: _number,
    desc: _desc
  }
}

export const getDayMonthYear = (birthday: any) => {
  const _dateTime = birthday.split('/');
  const _day = _dateTime.shift();
  const _month = _dateTime.shift();
  const _year = _dateTime.shift();

  return {
    _day,
    _month,
    _year
  }
}

export const calcNumerology = (numerology: any,type?: number) => {
  let _numerology = +numerology;
  
  switch (type) {
    case 1: {
      while (_numerology > 9) {
        const _resultNumber = calNumberData(_numerology);
        _numerology = _resultNumber.number;
      }
      break;
    }
    case 2: {
      while (_numerology > 11) {
        const _resultNumber = calNumberData(_numerology);
        _numerology = _resultNumber.number;
      }
      break;
    }
  
    default:{
      while (_numerology > 9 && !EXCEPT_NUMEROLOGY.includes(_numerology)) {
        const _resultNumber = calNumberData(_numerology);
        _numerology = _resultNumber.number;
      }
      break;
    }
  }
  
  return _numerology
}

const getNameByTypeCalc = (name: string, type?: number) => {// lấy ra tên hoặc họ và tên để tính toán theo từng chỉ số
  switch (type) {
    case 1: // chỉ lấy các ký tự đầu trong họ và tên => => tạo ra mảng các ký tự
    {
      const _name = name ? removeSign(name).toUpperCase().split(' ') : [];
      const arrStr = _name.map((item: any) => {
        return(item[0])
      });
      return arrStr;
    }
    case 2: // chỉ lấy họ => => tạo ra mảng các ký tự
    {
      const _name = name ? removeSign(name).toUpperCase().split(' ')[0] : '';
      const arrStr = [..._name];
      return arrStr;
    }
  
    default: // lấy cả họ và tên => tạo ra mảng các ký tự
    {
      const _name = name ? removeSign(removeSpaceInString(name)).toUpperCase() : '';
      const arrStr = [..._name];
      return arrStr;
    }
  }
}

const calcNumberByName = (name: string, type?: number) => {
  const _getDataSpecial = dataMeanAlphabetChangeToNumberSpecial.map((item: any) => item.alphabet);
  switch (type) {
    case 1: { // chỉ lấy ký tự là nguyên âm U E O A I
      const arrStr = getNameByTypeCalc(name);
      return arrStr.reduce((result: number[], charName: string, currentIndex: number) => {
        if(_getDataSpecial.includes(charName) && currentIndex > 0){ // nếu là ký  tự đặc biệt: Y
          // kiểm tra ký  tự trước nó nếu là phụ âm
          const _findBefore = dataMeanAlphabetChangeToNumberIsNotVowel.find((item: any) => item.alphabet === arrStr[currentIndex-1]);
          const _findSpecial = dataMeanAlphabetChangeToNumber.find((item: any) => item.alphabet === charName);
          if(_findBefore && _findSpecial){
            return [...result,_findSpecial.number];
          }
        }

        const _find = dataMeanAlphabetChangeToNumberIsVowel.find((item: any) => item.alphabet === charName);
        
        if(_find){
          return [...result,_find.number];
        }
        return result;
      }, [])
    }
    case 2: { // chỉ lấy ký tự là phụ âm
      const arrStr = getNameByTypeCalc(name);
      return arrStr.reduce((result: number[], charName: string, currentIndex: number) => {
        if(_getDataSpecial.includes(charName) && currentIndex > 0){ // nếu là ký  tự đặc biệt: Y
          // kiểm tra ký  tự trước nó nếu là nguyên âm
          const _findBefore = dataMeanAlphabetChangeToNumberIsVowel.find((item: any) => item.alphabet === arrStr[currentIndex-1]);
          const _findSpecial = dataMeanAlphabetChangeToNumber.find((item: any) => item.alphabet === charName);
          if(_findBefore && _findSpecial){
            return [...result,_findSpecial.number];
          }
        }

        const _find = dataMeanAlphabetChangeToNumberIsNotVowel.find((item: any) => item.alphabet === charName);
        
        if(_find){
          return [...result,_find.number];
        }
        return result;
      }, [])
    }
    case 3: { // chỉ lấy ký tự đầu tiên trong họ và tên
      const arrStr = getNameByTypeCalc(name, 1);
      return arrStr.reduce((result: number[], charName: string) => {
        const _find = dataMeanAlphabetChangeToNumber.find((item: any) => item.alphabet === charName);
        
        if(_find){
          return [...result,_find.number];
        }
        return result;
      }, [])
    }
  
    default:{ // lấy tất các các ký tự
      const arrStr = getNameByTypeCalc(name);
      return arrStr.reduce((result: number[], charName: string) => {
        const _find = dataMeanAlphabetChangeToNumber.find((item: any) => item.alphabet === charName);
        
        if(_find){
          return [...result,_find.number];
        }
        return result;
      }, [])
    }
  }
}
export const getNumberByName = (name: string, type?: number) => {
  const resultNumber = calcNumberByName(name, type);
  const result = resultNumber.reduce((total: number, current: number) => {
    return total + current;
  }, 0)
  
  return result;
}

export const getArrayNumberByName = (name: string, type?: number) => {
  const resultNumber = calcNumberByName(name, type);
  
  return resultNumber;
}

export const getArrayNumberByNumber = (data: any) => {
  const resultNumber: number[] = `${data}`.split('').map(number => {return +number});
  
  return resultNumber;
}

export const chunkArray = (myArray: any, chunk_size:number) => {
  const results = [];

  while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
  }

  return results;
}


export const getArrayNumberByBirthDay = (birthday: string) => {
  const resultBirthDay = getDayMonthYear(birthday);
  const data = Object.values(resultBirthDay);

  let result: any = data.reduce((_result, value) => {
    return [
      ..._result,
      ...getArrayNumberByNumber(value)
    ]
  }, []);
  
  return result;
}

export const calcDataTableStrength = (arrNumber: any) => {
  const resultArrNumber = setColStrengthIndex.map((item: any) => {
    const _result: any = arrNumber.filter((_number: any) => +_number === item.number)
    return {
      [item.name]: `${_result.join('')}`
    }
  })  

  let _dataStrengthIndex = chunkArray(resultArrNumber, 3);
  _dataStrengthIndex = _dataStrengthIndex.map(element => {
    const _result: any = element.reduce((colNumber: any, value: any) => {
      return {
        ...colNumber,
        ...value
      }
    }, {});
    
    return _result;
  })

  return _dataStrengthIndex;
}

export const checkArrowStrength = (arrayNumber: number[]) => {
  const result = arrowStrength.map((arrow: any) => {
    const key = arrow.join('-');
    let _arrow = arrow;
    const _count = arrayNumber.reduce((count: number, currentValue: number) => {
      if(_arrow.includes(currentValue)){
        _arrow = _arrow.filter((item: any) => item !== currentValue);
        return count+1;
      }
      return count;
    }, 0)
    
    return {
      key: key,
      count: _count,
    }
  })
  return result;
}

export const getDecryptionArrayNumber = (arrayNumber: number[]) => {
  const result = masterArrayNumber.map((item: any) => {
    const _count = arrayNumber.filter(_number => +_number === item.number);
    const _checkAlone = arrayNumber.find(_number => item.arrAroundNumber.includes(+_number));
    return({
      number: item.number,
      count: _count.length,
      isAlone: _checkAlone ? false : true,
    })
  })

  return result;
}
// export const genDataChart = (attitudeNumber: number, type: number) => {
//   const nowYear = moment().format('YYYY');
//   const _countYear = 5;
//   const _attitudeNumber = +attitudeNumber;
//   const _dataSeries = [];
//   const _dataSerieShow = [];
//   const _dataCategories = [];
//   const _colorCategories = [];
//   const _yearIndexNumber: any = calcNumerology(_attitudeNumber + +nowYear, 1);
//   let _max = 0;
//   const color = type === 1 ? '#fff' : '#000'; 
//   for (let index = 0; index <= _countYear; index++) {
//     if(index === 0){
//       _dataSeries.push(dataNumberShowChart[_yearIndexNumber]);
//       _dataSerieShow.push(_yearIndexNumber);
//       _dataCategories.push(nowYear);
//       _colorCategories.push('#2B908F');
//       _max = dataNumberShowChart[_yearIndexNumber];
//     }else{
//       let _numberBefore = _yearIndexNumber - index;
//       let _numberBeforeShow = _numberBefore <= 0 ? _numberBefore + 9 : _numberBefore;
//       const yearIndexNumberBeforeShow = calcNumerology(_numberBeforeShow, 1);
//       let yearIndexNumberBefore = _numberBefore <= 0 ? dataNumberShowChart[yearIndexNumberBeforeShow] - 1 : dataNumberShowChart[yearIndexNumberBeforeShow];
//       _max = yearIndexNumberBefore > _max ? yearIndexNumberBefore : _max;

//       _dataSeries.unshift(yearIndexNumberBefore);
//       _dataSerieShow.unshift(yearIndexNumberBeforeShow);
//       _dataCategories.unshift(index === _countYear ? +nowYear - index: '');
//       _colorCategories.unshift(color);
      
//       let _numberAfterShow = _yearIndexNumber + index;
//       const yearIndexNumberAfterShow = calcNumerology(_numberAfterShow, 1);
//       let yearIndexNumberAfter = _numberAfterShow >= 10 ? dataNumberShowChart[yearIndexNumberAfterShow] + 1 : dataNumberShowChart[yearIndexNumberAfterShow];
//       _max = yearIndexNumberAfter > _max ? yearIndexNumberAfter : _max;

//       _dataSeries.push(yearIndexNumberAfter);
//       _dataSerieShow.push(yearIndexNumberAfterShow);
//       _dataCategories.push(index === _countYear ? +nowYear + index : '');
//       _colorCategories.push(color);
//     }
//   }

//   return {
//     dataSeries: _dataSeries,
//     dataSerieShow: _dataSerieShow,
//     dataCategories: _dataCategories,
//     colorCategories: _colorCategories,
//     maxNum: _max,
//   }
// }

export const genDataChart = (attitudeNumber: number, type: number) => {
  const nowYear = moment().format('YYYY');
  const _attitudeNumber = +attitudeNumber;
  // const _dataSeries = [2, 3.5, 3.5, 2.5, 1.5, 1, 1.5, 2.3, 1.5, 2.6, 4.5, 4.5, 3.5];
  const _dataSeries = [2.5, 4, 4, 3, 2, 1, 2, 3, 2, 4, 5, 5, 3.5];
  const _dataSerieShow = [8,9,1,2,3,4,5,6,7,8,9,1,2];
  const _dataCategories = [];
  const _colorCategories = [];
  const _yearIndexNumber: any = calcNumerology(_attitudeNumber + +nowYear, 1);
  const _findIdx = _dataSerieShow.find(e => e === _yearIndexNumber) || 0;
  
  const color = type === 1 ? '#fff' : '#000';
  const arrShowCate = [0,_dataSerieShow.length-1] 
  for (let index = 0; index < _dataSerieShow.length; index++) {
    if(index === _findIdx){
      _dataCategories.push(nowYear);
      _colorCategories.push('#2B908F');
    }else{
      _dataCategories.push(arrShowCate.includes(index) ? +nowYear + index - _findIdx : '');
      _colorCategories.push(color);
    }
  }

  return {
    dataSeries: _dataSeries,
    dataSerieShow: _dataSerieShow,
    dataCategories: _dataCategories,
    colorCategories: _colorCategories,
    maxNum: 5,
  }
}