import { Col, Tag } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomTable from "src/components/table/CustomTable";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { calcDataTableStrength, checkArrowStrength, getArrayNumberByBirthDay, getDecryptionArrayNumber } from "src/utils/helpers/functions/helpFunctions";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsChartIndex, defaultDataStrengthIndex, keyDecryptionArrayNumber } from "./data";

import "./styles.less";

const CardPowerChart = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [dataStrengthIndex, setDataStrengthIndex] = useState(defaultDataStrengthIndex);

  const [dataDecryptionArrayNumber, setDataDecryptionArrayNumber] = useState<any>([]);
  const [dataDecryptionArrayNumberDesc, setDataDecryptionArrayNumberDesc] = useState<any>([]);

  const [dataArrowIndex, setDataArrowIndex] = useState<any>([]);
  const [dataArrowIndexDesc, setDataArrowIndexDesc] = useState<any>([]);

  const isMount = useIsMount();
  const {stateGetArrowIndex} = useSelector((state: AppState) => state.arrowIndexReducer);
  const {stateGetNumberOfRepetition} = useSelector((state: AppState) => state.numberOfRepetitionReducer);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    calcDataStrengthIndex(props.birthday);
  }, [props.birthday]);

  useEffect(() => {
    if (isMount)
      return
    const { success, data } = stateGetNumberOfRepetition;
    if (success) {
      const _dataDecryptionArrayNumber = dataDecryptionArrayNumber.filter((item: any) => item.count > 0);
      const _description:any = [];
      _dataDecryptionArrayNumber.forEach((element: any) => {
        const _find:any = data.find((itemData: any) => itemData.number === element.number);
        const _desc = _find[keyDecryptionArrayNumber[element.count]];
        _description.push({
          title: `- Bạn có ${element.count} số ${element.number} trong biểu đồ sức mạnh`,
          description: _desc ? _desc.split('\n') : [],
        });
        if(element.isAlone){
          _description.push({
            title: `- Bạn có số ${element.number} 'lẻ loi' (Isolated ${element.number}'s) trong biểu đồ sức mạnh`,
            description: _find.lonely ? _find.lonely.split('\n') : [],
          });
        }
      });

      setDataDecryptionArrayNumberDesc(_description)
    }
  }, [stateGetNumberOfRepetition.isLoading, dataDecryptionArrayNumber]);

  useEffect(() => {
    if (isMount)
      return
    const { success, data } = stateGetArrowIndex;
    if (success) {
      const _dataArrowLost = dataArrowIndex.filter((item: any) => item.count === 0);
      const _dataArrowFull = dataArrowIndex.filter((item: any) => item.count === 3);

      const _descriptionLost = _dataArrowLost.map((item: any) => {
        const _find = data.find((itemData: any) => itemData.axis === item.key);
        return ({
          title: `- Bạn thiếu trục ${_find.axis_name} (${_find.axis})`,
          description: _find.meaning_without_axis ? _find.meaning_without_axis.split('\n') : [],
        })
      });

      const _descriptionFull = _dataArrowFull.map((item: any) => {
        const _find = data.find((itemData: any) => itemData.axis === item.key);
        return ({
          title: `- Bạn có trục ${_find.axis_name} (${_find.axis})`,
          description: _find.meaning_with_axis ? _find.meaning_with_axis.split('\n') : [],
        })
      });

      setDataArrowIndexDesc([..._descriptionFull, ..._descriptionLost])
    }
  }, [stateGetArrowIndex.isLoading, dataArrowIndex]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */

  const calcDataStrengthIndex = (birthday: string) => {
    const arrNumber: any = getArrayNumberByBirthDay(birthday);
    const decryptionArrayNumber = getDecryptionArrayNumber(arrNumber);
    setDataDecryptionArrayNumber(decryptionArrayNumber);
    
    const resultArrNumber = calcDataTableStrength(arrNumber);
    setDataStrengthIndex(resultArrNumber);

    const checkArrow = checkArrowStrength(arrNumber);
    setDataArrowIndex(checkArrow);
    
  }
  
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center' id='card_index_year'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. BIỂU ĐỒ SỨC MẠNH CỦA BẠN (rất quan trọng)</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của những con số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Biểu đồ này còn được gọi là biểu đồ ngày sinh do được tạo ra từ ngày sinh, biểu đồ 
                  này thể hiện sức mạnh nguyên thủy của bạn. Nó cho thấy tổng quan các ưu nhược điểm 
                  về năng lực, sức mạnh của bạn (thể chất, tinh thần, trí tuệ) và cho biết rất nhiều 
                  các điểu yếu điểm mạnh của bạn.
                  </i>
                </div>
                <div>
                  <i>
                  Tóm lại biểu đồ này giống như đề bài toán mà vũ trụ gửi cho bạn, bạn cần hiểu đề 
                  để giải được bài toán thì cuộc sống của bạn sẽ cân bằng và tốt đẹp hơn nhiều.
                  </i>
                </div>
                <div>
                  <i>
                    Lưu ý rằng tên thường dùng của bạn có thể bù trừ vào những điểm yếu của biểu đồ này, 
                    vì vậy hãy tìm hiểu thêm biểu đồ tổng hợp để đặt tên mới cho bạn nếu cần thiết. Tên mới 
                    này có thể dùng ở facebook, zalo,... <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
          <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
            <div className="flex justify-center">
              <div>
                <div>
                  <CustomTable
                    rowKey={"id"}
                    bordered
                    showHeader={false}
                    columns={columnsChartIndex()}
                    dataSource={dataStrengthIndex}
                    loading={false}
                    pagination={false}
                    className="pth-table-history pth-cell-h45"
                  />
                </div>
                <div className="text-center">
                  <span className="font-medium">Biểu đồ sức mạnh (Biểu đồ ngày sinh) của bạn</span>
                </div>
              </div>
            </div>
            {
              !showDescription && (
                <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                  onClick={() => setShowDescription(true)}>
                  <span>Xem giải thích biểu đồ sức mạnh của bạn</span>
                </Tag>
              )
            }
            {
              showDescription && (
                <div className="mb-2">
                  {
                    dataArrowIndexDesc.map((item: any) => {
                      return(
                        <>
                          <div>
                            <h3><strong>{item.title.toUpperCase()}</strong></h3>
                          </div>
                          {
                            item.description.map((itemChild: any) => {
                              return(
                                <div>
                                  <i>{itemChild}</i>
                                </div>
                              )
                            })
                          }
                        </>
                      )
                    })
                  }
                  {
                    dataDecryptionArrayNumberDesc.map((item: any) => {
                      return(
                        <>
                          <div>
                            <h3><strong>{item.title}</strong></h3>
                          </div>
                          {
                            item.description.map((itemChild: any) => {
                              return(
                                <div>
                                  <i>{itemChild}</i>
                                </div>
                              )
                            })
                          }
                        </>
                      )
                    })
                  }
                  
                  <div className="mb-1">
                    <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                      onClick={() => setShowDescription(false)}>
                      <span>Ẩn giải thích</span>
                    </Tag>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardPowerChart;
