import { Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "src/components/table/CustomTable";
import { getArrowIndex } from "src/services/actions/arrowIndex.actions";
import { getMeanOfIndex } from "src/services/actions/meanOfIndex.actions";
import { getNumberOfRepetition } from "src/services/actions/numberOfRepetition.actions";
import { getpersonalMonthYear } from "src/services/actions/personalMonthYear.actions";
import { AppState } from "src/types";
import { calcNumerology, getNumberByName } from "src/utils/helpers/functions/helpFunctions";

import { removeSign } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import CardAttitudeIndex from "./cardAttitudeIndex";
import CardCharacterIndex from "./cardCharacterIndex";
import CardCircleOfLife from "./cardCircleOfLife";
import CardCycleOfFortunes from "./cardCycleOfFortunes";
import CardGrownUpIndex from "./cardGrownUpIndex";
import CardLifePathIndex from "./cardLifePathIndex";
import CardMissionIndex from "./cardMissionIndex";
import CardNameAndSumChart from "./cardNameAndSumChart";
import CardNaturalCapacityIndex from "./cardNaturalCapacityIndex";
import CardPersonality from "./cardPersonality";
import CardPersonalMonth from "./cardPersonalMonth";
import CardPersonalYear from "./cardPersonalYear";
import CardPowerChart from "./cardPowerChart";
import CardPyramid from "./cardPyramid";
import CardSoulIndex from "./cardSoulIndex";
import { defaultDataStrengthIndex, defaultRankRender } from "./data";
import "./styles.less";

const ReSearchDetail = (props: any) => {
  const [dataCircleOfLife, setDataCircleOfLife] = useState<any>({});
  const [dataYearIndex, setDataYearIndex] = useState<any>([]);
  const [dataYearIndexDesc, setDataYearIndexDesc] = useState<any>([]);
  const [dataMonthIndex, setDataMonthIndex] = useState<any>([]);
  const [dataMonthIndexDesc, setDataMonthIndexDesc] = useState<any>([]);
  const [dataMeanOfIndex, setDataMeanOfIndex] = useState<any>({});
  const [dataMeanOfIndexDesc, setDataMeanOfIndexDesc] = useState<any>({});

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const {stateGetPersonalMonthYear} = useSelector((state: AppState) => state.personalMonthYearReducer);
  const {stateMeanOfIndex} = useSelector((state: AppState) => state.meanOfIndexReducer);
  const amountYear = 3;// lấy 3 năm từ năm hiện tại
  const amountMonth = 4;// lấy 4 tháng từ năm hiện tại
  /****************************START**************************/
  /*                         Life Cycle                      */
  
  useEffect(() => {
    // const { resultDay, resultMonth } = props.defaultData;
    // calcAttitude(resultDay, resultMonth);
    calcMeanOfIndexs();
    dispatch(getArrowIndex({}));
    dispatch(getNumberOfRepetition({}));
  }, []);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetPersonalMonthYear;
    if (success) {
      const _dataYearIndexDesc = dataYearIndex.map((itemYear: any) => {
        const _find = data.find((item: any) => item.number === itemYear.number);
        return({
          title: `Vận số ${itemYear.title} của bạn là:`.toUpperCase(),
          number: itemYear.number,
          description: _find && _find.year ? _find.year.split('\n') : []
        })
      });
      setDataYearIndexDesc(_dataYearIndexDesc);

      const _dataMonthIndexDesc = dataMonthIndex.map((itemMonth: any) => {
        const _find = data.find((item: any) => item.number === itemMonth.number);
        return({
          title: `- Chỉ số ${itemMonth.title} của bạn là:`.toUpperCase(),
          number: itemMonth.number,
          description: _find && _find.month ? _find.month.split('\n') : []
        })
      });
      setDataMonthIndexDesc(_dataMonthIndexDesc);
    }
  }, [stateGetPersonalMonthYear.isLoading]);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateMeanOfIndex;
    if (success) {
      const _missionNumber = data.find((item: any) => item.number === dataMeanOfIndex.missionNumber);
      const _soulNumber = data.find((item: any) => item.number === dataMeanOfIndex.soulNumber);
      const _characterNumber = data.find((item: any) => item.number === dataMeanOfIndex.characterNumber);

      const _naturalCapacityNumber = data.find((item: any) => item.number === dataMeanOfIndex.naturalCapacityNumber);
      const _attitudeNumber = data.find((item: any) => item.number === dataMeanOfIndex.attitudeNumber);
      const _grownUpNumber = data.find((item: any) => item.number === dataMeanOfIndex.grownUpNumber);
      setDataMeanOfIndexDesc(
        {
          mission: {
            number: dataMeanOfIndex.missionNumber,
            description: _missionNumber ? _missionNumber.mission.split('\n') : [],
          },
          soul: {
            number: dataMeanOfIndex.soulNumber,
            description: _soulNumber ? _soulNumber.soul.split('\n') : [],
          },
          character: {
            number: dataMeanOfIndex.characterNumber,
            description: _characterNumber ? _characterNumber.character.split('\n') : [],
          },
          naturalCapacity: {
            number: dataMeanOfIndex.naturalCapacityNumber,
            description: _naturalCapacityNumber ? _naturalCapacityNumber.natural_capacity.split('\n') : [],
          },
          attitude: {
            number: dataMeanOfIndex.attitudeNumber,
            description: _attitudeNumber ? _attitudeNumber.attitude.split('\n') : [],
          },
          grownUp: {
            number: dataMeanOfIndex.grownUpNumber,
            description: _grownUpNumber ? _grownUpNumber.grown_up.split('\n') : [],
          },
        })
    }
  }, [stateMeanOfIndex.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const calcMeanOfIndexs = () => {
    const { resultDay, resultMonth } = props.defaultData;
     // lấy sô tất cả ký tự trong họ tên
    const _getNumberByFullName = getNumberByName(props.defaultData.full_name);
     // lấy sô tất cả ký tự nguyên âm trong họ tên
    const _getNumberByIsVowelInName = getNumberByName(props.defaultData.full_name, 1);
     // lấy sô tất cả ký tự phụ âm trong họ tên
    const _getNumberByIsNotVowelInName = getNumberByName(props.defaultData.full_name, 2);
    
    const _missionNumber = calcNumerology(_getNumberByFullName); // tính chỉ số sứ mệnh
    
    const _soulNumber = calcNumerology(_getNumberByIsVowelInName); // tính chỉ số linh hồn
    const _characterNumber = calcNumerology(_getNumberByIsNotVowelInName); // tính chỉ số nhân cách
    const _naturalCapacityNumber = calcNumerology(resultDay, 1); // tính chỉ số năng lực tự nhiên
    const _attitudeNumber = calcNumerology(resultDay + resultMonth, 1); // tính chỉ số thái độ
    calcYearAndMonthIndex(_attitudeNumber); // tính chỉ số năm và tháng
    const _grownUpNumber = calcNumerology(props.defaultData.resultNumber + _missionNumber); // tính chỉ số trưởng thành

    // lưu lại các chỉ số mean of index
    const _dataMeanOfIndex = {
      missionNumber: _missionNumber,
      soulNumber: _soulNumber,
      characterNumber: _characterNumber,
      naturalCapacityNumber: _naturalCapacityNumber,
      attitudeNumber: _attitudeNumber,
      grownUpNumber: _grownUpNumber,
    }
    
    setDataMeanOfIndex(_dataMeanOfIndex)
    
    dispatch(getMeanOfIndex([
      _missionNumber,
      _soulNumber,
      _characterNumber,
      _naturalCapacityNumber,
      _attitudeNumber,
      _grownUpNumber,
    ]));
  }
  
  const calcYearAndMonthIndex = (_attitudeNumber: number) => { // tính chỉ số năm và tháng
    const _nowYear = moment().format('YYYY');
    const _nowMonth = moment().format('MM');
    const _yearIndexNumber = calcNumerology(_attitudeNumber + +_nowYear, 1);
    const _dataYearIndex = setDataYearAndMonthIndex(_yearIndexNumber, amountYear);
    const _monthIndexNumber = calcNumerology(_yearIndexNumber + +_nowMonth, 1);
    const _dataMonthIndex = setDataYearAndMonthIndex(_monthIndexNumber, amountMonth, 1);

    setDataYearIndex(_dataYearIndex);
    setDataMonthIndex(_dataMonthIndex);
    const allNumber = [..._dataYearIndex.map((item: any) => item.number),..._dataMonthIndex.map((item: any) => item.number)];
    dispatch(getpersonalMonthYear(allNumber));
  }

  const setDataYearAndMonthIndex = (idxNumber: number, amount: number, type: number = 0) => {
    const result: any = []; 
    for (let index = 0; index < amount; index++) {
      let title = `Năm ${moment().add(index, 'year').format('YYYY')}`;
      if(type){
        title = `Tháng ${moment().add(index, 'month').format('M/YYYY')}`;
      }
      const value = calcNumerology(idxNumber + index, 1);
      result.push({
        title,
        number: value,
      })
    }

    return result;
  }
  /**************************** END **************************/

  
  /****************************START**************************/
  /*                         Component                       */

  const getCardFunc = (key: string, rank: number) => {
    switch (key) {
      case 'CycleOfFortunes': // chu kỳ vận số
      {
        return <CardCycleOfFortunes rank={rank} data={dataMeanOfIndexDesc?.attitude}/>;
      }
      case 'Personality': // tính cách
      {
        return <CardPersonality rank={rank}/>;
      }
      case 'LifePathIndex': // chỉ số chủ đạo
      {
        return <CardLifePathIndex 
          rank={rank} 
          data={props.data}
        />;
      }
      case 'MissionIndex': // chỉ số sứ mệnh
      {
        return <CardMissionIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.mission}
        />;
      }
      case 'SoulIndex': // chỉ số linh hồn
      {
        return <CardSoulIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.soul}
        />;
      }
      case 'CharacterIndex': // chỉ số nhân cách
      {
        return <CardCharacterIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.character}
        />;
      }
      case 'AttitudeIndex': // chỉ số thái độ
      {
        return <CardAttitudeIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.attitude}
        />;
      }
      case 'NaturalCapacityIndex': // chỉ số năng lực tự nhiên
      {
        return <CardNaturalCapacityIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.naturalCapacity}
        />;
      }
      case 'GrownUpIndex': // chỉ số trưởng thành
      {
        return <CardGrownUpIndex 
          rank={rank} 
          data={dataMeanOfIndexDesc?.grownUp}
        />;
      }
      case 'CircleOfLife': // chu kỳ đường đời
      {
        return <CardCircleOfLife 
          rank={rank} 
          data={props.defaultData}
          dataCircleOfLife={dataCircleOfLife}
        />;
      }
      case 'Pyramid': // kim tự tháp
      {
        return <CardPyramid 
          rank={rank} 
          data={props.defaultData}
          setDataCircleOfLife={setDataCircleOfLife}
        />;
      }
      case 'PersonalYear': // chỉ số năm
      {
        return <CardPersonalYear
          rank={rank} 
          dataTable={dataYearIndex}
          dataDesc={dataYearIndexDesc}
          amount={amountYear}
        />;
      }
      case 'PersonalMonth': // chỉ số tháng
      {
        return <CardPersonalMonth
          rank={rank} 
          dataTable={dataMonthIndex}
          dataDesc={dataMonthIndexDesc}
          amount={amountMonth}
        />
      }
      case 'PowerChart': // biểu đồ sức mạnh
      {
        return <CardPowerChart
          rank={rank} 
          birthday={props.defaultData.birthday}
        />
      }
      case 'NameAndSumChart': // biểu đồ tên và tổng hợp
      {
        return <CardNameAndSumChart
          rank={rank} 
          common_name={props.defaultData.common_name}
          birthday={props.defaultData.birthday}
        />
      }
      default:
        return <div>Không tìm thấy thông tin</div>;
    }
  }

  const renderCards = () => {
    const result = defaultRankRender.map((item: string, idx: number) => {
      const _rank = idx + 1;
      return getCardFunc(item, _rank);
    })

    return result;
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className='w-full h-full pl-4 pr-4'>
      <Col className='mt-5'>
        <div className="mb-2">
          <Row gutter={[8,8]}>
            <Col span={24} className='flex justify-center'>
              <span className="pth-text-24 font-bold text-neutral_color_1_8">BÁO CÁO THẦN SỐ HỌC</span> 
            </Col>
            <Col span={24} className='flex justify-center'>
              <span className="pth-text-24 font-bold text-accent_color_3_2 ml-2">{removeSign(props.defaultData.full_name).toUpperCase()}</span>
            </Col>
            <Col span={24} className='flex justify-center'>
              <span className="pth-text-24 font-bold text-accent_color_3_2 ml-2">{props.defaultData.birthday}</span>
            </Col>
            {
              renderCards()
            }
          </Row>
        </div>
      </Col>
    </div>
  );

  /**************************** END **************************/

};

export default ReSearchDetail;
