import { call, put, takeLatest } from 'redux-saga/effects';
import { API_PERSONAL_MONTH_YEAR } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import personalMonthYearTypes from '../types/personalMonthYear.types';

function* getpersonalMonthYear(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        filter: { "number": {"_in": payload.params}}
      }
      return api.get(`${API_URL}/${API_PERSONAL_MONTH_YEAR}`, _filter)
    });
    yield put(actionSuccess(personalMonthYearTypes.GET_PERSONAL_MONTH_YEAR_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(personalMonthYearTypes.GET_PERSONAL_MONTH_YEAR_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(personalMonthYearTypes.START_GET_PERSONAL_MONTH_YEAR, getpersonalMonthYear),
]

