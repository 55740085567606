import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import OverlaySpinner from "src/components/typingAnimation/OverlaySpinner";
import { calcNumerology } from "src/utils/helpers/functions/helpFunctions";
import { removeSign } from "src/utils/helpers/functions/textUtils";
import { typeProps } from "./interfaces";

const ReSearch = (props: typeProps) => {
  const [attitudeNumber, setAttitudeNumber] = useState<any>(false);
  /****************************START**************************/
  /*                         Life Cycle                      */

  useEffect(() => {
    const { resultDay, resultMonth } = props.defaultData;
    const _attitudeNumber = calcNumerology(resultDay + resultMonth, 1);
    setAttitudeNumber(_attitudeNumber)
  }, [props.defaultData]);
  
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const onChangeType = () => {
    props.onChangeType(2)
  }
  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className='w-full h-full pl-4 pr-4'>
      <Col className='mt-5 mb-2'>
        <div className="mb-2">
          <Row gutter={[8,8]} className="pth-animation-type-1">
            <Col span={24} className='flex justify-center'>
              <span className="pth-animation-content pth-animation-content-first">
                <span className="pth-text-24 font-bold text-neutral_color_1_8">Họ và tên: </span> 
                <span className="pth-text-24 font-bold text-accent_color_3_2 ml-2">{removeSign(props.defaultData.full_name).toUpperCase()}</span>
              </span>
            </Col>
            <Col span={24} className='flex justify-center'>
              <span className="pth-animation-content pth-animation-delay">
                <span className="pth-text-24 font-bold text-neutral_color_1_8">Ngày sinh:</span> 
                <span className="pth-text-24 font-bold text-accent_color_3_2 ml-2">{props.defaultData.birthday}</span>
              </span>
            </Col>
          </Row>
        </div>
        <OverlaySpinner 
          text={props.defaultData.resultNumber} 
          position="unset" 
          data={props.defaultData.data}
          time={props.defaultData.time}
          onChangeProps={onChangeType}
          dataDesc={props.data}
          attitudeNumber={attitudeNumber}
        />
      </Col>
    </div>
  );

  /**************************** END **************************/

};

export default ReSearch;
