/* eslint-disable */
import { Col, Row } from "antd";
import { nameWebsite } from "src/utils/text";

const PrivacyPolicy = () => {
  
  /****************************START**************************/
  /*                         Life Cycle                      */

  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div className="mt-3 mb-3">
      <Row gutter={[8,8]}>
        <Col span={24} className='flex justify-center'>
          <div className="w-4/5 px-2 py-2">
            <div className="py-2">
              <span className="text-xl"><strong>Chính sách bảo mật</strong></span>
            </div>
            <div className="py-2">
              <span>
                Khi sử dụng dịch vụ của chúng tôi, bạn tin tưởng cung cấp thông tin của bạn cho chúng tôi. 
                Chúng tôi hiểu rằng đây là một trách nhiệm lớn và chúng tôi nỗ lực bảo vệ thông tin của bạn 
                cũng như để bạn nắm quyền kiểm soát.
              </span>
            </div>
            <div className="py-2">
              <span>
              Chính sách bảo mật này nhằm mục đích giúp bạn hiểu rõ những thông tin chúng tôi thu thập, lý do 
              chúng tôi thu thập và cách bạn có thể cập nhật, quản lý, xuất và xóa thông tin của mình. 
              </span>
            </div>
            <div className="py-2">
              <strong><span className="text-accent_color_2_1">Có hiệu lực từ 1 tháng 10, 2022</span></strong>
            </div>
            <div className="py-2">
              <ol>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Phạm Vi. </span></strong>
                    Chính sách Bảo mật thông tin cá nhân này áp dụng đối với mọi thiết bị, trang web 
                    hoặc ứng dụng trực tuyến của <strong>{nameWebsite}</strong> tham chiếu đến hoặc 
                    liên kết đến Chính sách Bảo mật thông tin cá nhân (gọi chung là <strong>Dịch vụ</strong>). 
                    Chính sách Bảo mật thông tin cá nhân áp dụng bất kể bạn sử dụng máy vi tính, điện thoại 
                    di động, máy tính bảng, TV hay thiết bị khác để truy cập Dịch vụ của chúng tôi.
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Thông tin chúng tôi thu thập. </span></strong>
                    Chúng tôi thu thập nhiều loại thông tin khác nhau có liên quan đến Dịch vụ, bao gồm:
                    <div className="pl-2"><strong>- Thông tin bạn cung cấp trực tiếp cho chúng tôi;</strong></div>
                    <div className="pl-2"><strong>- Thông tin chúng tôi thu thập về việc bạn sử dụng Dịch vụ của chúng tôi;</strong></div>
                    <div className="pl-2"><strong>- Thông tin chúng tôi thu được từ các nguồn của bên thứ ba;</strong></div>
                    <div className="pt-1"><strong>Chúng tôi cũng có thể tìm kiếm sự đồng ý của bạn để thu thập thông tin không được mô tả 
                      trong Chính sách Bảo mật thông tin cá nhân.</strong></div>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong><span className="text-accent_color_4_2">Sử dụng và chia sẻ thông tin. </span></strong>
                    <span className="text-accent_color_4_3">Chúng tôi sử dụng thông tin mà chúng tôi thu thập (và có 
                    thể kết hợp chúng với các thông tin khác về bạn), trong số những thứ khác, để:</span>
                    <div className="pl-2"><strong>- Cung cấp Dịch vụ mà bạn yêu cầu;</strong></div>
                    <div className="pl-2"><strong>- Hiểu cách bạn sử dụng Dịch vụ để chúng tôi có thể cải thiện trải nghiệm của bạn;</strong></div>
                    <div className="pl-2"><strong>- Cung cấp nội dung và quảng cáo theo đối tượng khách hàng.</strong></div>
                    <div className="pt-1"><strong>Chúng tôi cũng sử dụng thông tin mà chúng tôi thu thập theo các cách được mô tả 
                      cho bạn tại thời điểm thu thập hoặc với sự đồng ý của bạn</strong></div>
                  </span>
                </li>
                <li className="py-2">
                  <span>
                    <strong>Email: </strong>
                    thông qua phần <strong>Liên hệ với chúng tôi</strong> trên trang
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  /**************************** END **************************/
};

export default PrivacyPolicy;
