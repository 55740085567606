/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "antd/dist/antd.css";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route, Switch, useHistory, useLocation, useParams
} from "react-router-dom";
import "tailwindcss/tailwind.css";
import DefaultLayout from "./components/layout/DefaultLayout";
import { routerNotAuth } from "./constants";
import "./index.css";
import HistoryBuy from "./pages/account/historyBuy";
import HistorySearch from "./pages/account/historySearch";
import Profile from "./pages/account/profile";
import Home from "./pages/home/index";
import PrivacyPolicy from "./pages/info-public/privacyPolicy";
import TermAndCondition from "./pages/info-public/termAndCondition";
import SignIn from "./pages/manager-sign-in";
import ReSearch from "./pages/re-search/index";
import Search from "./pages/search/index";
import { putSignIn } from "./services/actions/global.actions";
import { useAuth } from "./services/authorRouter";
import "./styles.less";
import "./styles/main.css";
import "./styles/responsive.css";
import { AppState, RouteParams } from "./types";
import routerNames from "./utils/data/routerName";
import { useIsMount } from "./utils/helpers/functions/useIsMount";
import { localGetAccount, localGetToken } from "./utils/localStorage";

function App() {
  const history = useHistory();
  const auth = useAuth();
  const paramsURL = useParams<RouteParams>();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const localToken = localGetToken();
  const isMount = useIsMount();

  const globalReducer = useSelector((e: AppState) => e.globalReducer);
  const stateSignIn = useSelector(
    (e: AppState) => e.accountReducer.stateSignIn
  );
  
  useLayoutEffect(() => {
    function checkAuthor() {
      let token = localGetToken();
      let account = localGetAccount();
      const _rootPathname = pathname.split('/');
      if (token && account) {
        dispatch(putSignIn(true));
        auth.signIn(token, account, () => {
          if (pathname === "/" || pathname === routerNames.SIGN_IN)
            history.push(routerNames.HOME);
          else history.push(pathname);
        });
      }
      else if(_rootPathname[1] && routerNotAuth.includes(`/${_rootPathname[1]}`)) {
        history.push(pathname);
      }
      else {
        history.push({
          pathname: routerNames.HOME,
        });
      }
    }
    checkAuthor(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //init state isSigin=null
    //then check authen => false or true
    if (globalReducer.isSignIn === false) {
      auth.signOut(() => {
        history.push({
          pathname: routerNames.SIGN_IN,
        });
      });
    }
  }, [globalReducer.isSignIn]);

  useEffect(() => {
    if (isMount) return;
    const { isLoading, success, message, error, data } = stateSignIn;
    if (success) {
      dispatch(putSignIn(true));
    }
  }, [stateSignIn.isLoading]);

  return (
    <div className="App">
      <Switch>
        <Route path={routerNames.SIGN_IN} exact component={SignIn} />
        {/* <RequireAuth>
          <Main>
            <Route exact path={routerNames.PROFILE} component={Profile} />
          </Main>
        </RequireAuth> */}
        <DefaultLayout>
          <Route exact path={routerNames.HOME} component={Home} />
          {/* <Route exact path={routerNames.SEARCH} component={Search} /> */}
          
          <Route exact path={routerNames.RE_SEARCH} component={ReSearch} />
          <Route exact path={routerNames.HISTORY_SEARCH} component={HistorySearch} />
          <Route exact path={routerNames.HISTORY_BUY} component={HistoryBuy} />
          
          <Route exact path={routerNames.PROFILE} component={Profile} />
          <Route exact path={routerNames.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={routerNames.TERM_AND_CONDITION} component={TermAndCondition} />
        </DefaultLayout>
      </Switch>
    </div>
  );
}

export default App;
