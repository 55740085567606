
import { IAction } from '../interfaces/root.interfaces';
import circleOfLifeTypes from './types/circleOfLife.types';

export const getCircleOfLife = (params: any): IAction => {
  
  const action: IAction = {
    type: circleOfLifeTypes.START_GET_CICLE_OF_LIFE,
    payload: { params },
  }
  return action;
};