import { Reducer } from 'redux';

import searchNumerologyTypes from '../actions/types/searchNumerology.types';
import { ISyntheticAction } from '../interfaces/root.interfaces';
import { ISearchNumerologyState } from '../interfaces/searchNumerology.interfaces';
import { rootState } from './state/root.states';

const initState: ISearchNumerologyState = {
  stateSearchNumerology: { ...rootState },
  stateSetDataNumerology: { ...rootState },
};

const searchNumerologyReducer: Reducer<ISearchNumerologyState, ISyntheticAction>
  = (state: ISearchNumerologyState = initState, action: ISyntheticAction) => {
    const { type, payload, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       SEARCH NUMEROGORY                     */

      case searchNumerologyTypes.START_SEARCH_NUMEROLOGY: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSearchNumerology: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case searchNumerologyTypes.SEARCH_NUMEROLOGY_SUCCESS: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSearchNumerology: {
            ...rootState,
            isLoading: false,
            data: response.data && response.data[0],
            message: response.data && response.data[0],
            success: response.data && response.data[0] ? true : false,
          }
        };
        return stateReducer;
      }
      case searchNumerologyTypes.SEARCH_NUMEROLOGY_FAILURE: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSearchNumerology: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/
      /*                       SET DATA NUMEROGORY                     */

      case searchNumerologyTypes.START_SET_DATA_NUMEROLOGY: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSetDataNumerology: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case searchNumerologyTypes.SET_DATA_NUMEROLOGY_SUCCESS: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSetDataNumerology: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: response.message,
            success: response.success,
          }
        };
        return stateReducer;
      }
      case searchNumerologyTypes.SET_DATA_NUMEROLOGY_FAILURE: {
        const stateReducer: ISearchNumerologyState = {
          ...state,
          stateSetDataNumerology: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default searchNumerologyReducer;