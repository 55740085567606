import { Reducer } from 'redux';
import historySearchTypes from '../actions/types/historySearch.types';
import { IHistorySearchState } from '../interfaces/historySearch.interfaces';

import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

const initState: IHistorySearchState = {
  stateGetHistorySearch: { ...rootState },
  stateGetOneHistorySearch: { ...rootState },
  stateCreateHistorySearch: { ...rootState },
};

const historySearchReducer: Reducer<IHistorySearchState, ISyntheticAction>
  = (state: IHistorySearchState = initState, action: ISyntheticAction) => {
    const { type, response } = action;

    switch (type) {

      /*************************** START *************************/

      case historySearchTypes.START_GET_HISTORY_SEARCH: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetHistorySearch: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case historySearchTypes.GET_HISTORY_SEARCH_SUCCESS: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetHistorySearch: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case historySearchTypes.GET_HISTORY_SEARCH_FAILURE: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetHistorySearch: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/

      case historySearchTypes.START_GET_ONE_HISTORY_SEARCH: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetOneHistorySearch: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case historySearchTypes.GET_ONE_HISTORY_SEARCH_SUCCESS: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetOneHistorySearch: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case historySearchTypes.GET_ONE_HISTORY_SEARCH_FAILURE: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateGetOneHistorySearch: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/
      /*************************** START *************************/

      case historySearchTypes.START_CREATE_HISTORY_SEARCH: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateCreateHistorySearch: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case historySearchTypes.CREATE_HISTORY_SEARCH_SUCCESS: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateCreateHistorySearch: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case historySearchTypes.CREATE_HISTORY_SEARCH_FAILURE: {
        const stateReducer: IHistorySearchState = {
          ...state,
          stateCreateHistorySearch: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default historySearchReducer;