
import { IAction } from '../interfaces/root.interfaces';
import searchNumerologyTypes from './types/searchNumerology.types';

export const searchNumerology = (params: any): IAction => {
  
  const action: IAction = {
    type: searchNumerologyTypes.START_SEARCH_NUMEROLOGY,
    payload: { params },
  }
  return action;
};

export const setDataNumerology = (params: any): IAction => {
  
  const action: IAction = {
    type: searchNumerologyTypes.START_SET_DATA_NUMEROLOGY,
    payload: { params },
  }
  return action;
};