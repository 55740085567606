const searchNumerologyTypes = {
  // search numerology
  START_SEARCH_NUMEROLOGY: 'START_SEARCH_NUMEROLOGY',
  SEARCH_NUMEROLOGY_SUCCESS: 'SEARCH_NUMEROLOGY_SUCCESS',
  SEARCH_NUMEROLOGY_FAILURE: 'SEARCH_NUMEROLOGY_FAILURE',

  // set data numerology
  START_SET_DATA_NUMEROLOGY: 'START_SET_DATA_NUMEROLOGY',
  SET_DATA_NUMEROLOGY_SUCCESS: 'SET_DATA_NUMEROLOGY_SUCCESS',
  SET_DATA_NUMEROLOGY_FAILURE: 'SET_DATA_NUMEROLOGY_FAILURE',
}

export default searchNumerologyTypes;