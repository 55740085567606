/* eslint-disable */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import moment from 'moment';
import LineChart from "src/pages/re-search/components/lineChart";
import ApexChart from "src/pages/re-search/components/lineChartCycleOfFortunes";
import colors from "src/utils/colors";
import { calcNumerology, genDataChart } from "src/utils/helpers/functions/helpFunctions";
import ButtonPTH from "../buttons/ButtonPTH";
import "./styles.less";
import { dataNumberShowChart } from "src/pages/re-search/components/data";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
interface OverlaySpinnerInterfaces {
  spin?: any;
  onClickCallback?: any;
  position?: any;
  text?: any;
  data?: any;
  time?: number;
  onChangeProps?: (value: any) => void,
  dataDesc?: any,
  attitudeNumber: number
}
const OverlaySpinner = ({ spin, text, position, data, time = 7, onChangeProps, dataDesc, attitudeNumber }: OverlaySpinnerInterfaces) => {
  let _timeEnd = time + data.length + 2;
  let _timeResultShow = time + data.length + 3;
  const strengths = dataDesc && dataDesc.strengths.split('-');
  if(strengths) strengths.shift();
  const weaknesses = dataDesc && dataDesc.weaknesses.split('-');
  if(weaknesses) weaknesses.shift();
  
  const [dataSeries, setDataSeries] = useState<any>(false);
  const [dataSerieShow, setDataSerieShow] = useState<any>(false);
  const [dataCategories, setDataCategories] = useState<any>(false);
  const [colorCategories, setColorCategories] = useState<any>(false);
  const [maxNum, setMaxNum] = useState<any>(0);
  
  const isMount = useIsMount();
  
  useEffect(() => {
    if(isMount) return;
    const result = genDataChart(attitudeNumber, 1);
    
    setDataSeries(result.dataSeries);
    setDataSerieShow(result.dataSerieShow);
    setDataCategories(result.dataCategories);
    setColorCategories(result.colorCategories);
    setMaxNum(result.maxNum);
    
  }, [attitudeNumber]);

  return (
    <>
    <div
      className="overlaySpinner12"
      style={{ position: position ? position : "fixed" }}
    >
      <div>{spin && <Spin size="large" />}</div>
      <>
        {
          data.map((e: any, idx: number) => {
            if (e.isSign) {
              return(
                <div className="waveLoading12 pth-sign-research" key={idx}>
                  <div style={{ ["--c" as any]: ++time,["--e" as any]: _timeEnd }} className="text-center">{e.day}</div>
                  <div style={{ ["--c" as any]: time,["--e" as any]: _timeEnd }} className="text-center">{e.month}</div>
                  <div style={{ ["--c" as any]: time,["--e" as any]: _timeEnd }} className="text-center">{e.year}</div>
                </div>
              )
            } else {
              return(
                <div className="waveLoading12" key={idx}>
                  <div style={{ ["--c" as any]: ++time,["--e" as any]: _timeEnd }} className="text-center bg-primary_color_1_3">
                    <span className="font-bold text-accent_color_3_2">{e.day}</span> 
                  </div>
                  <div style={{ ["--c" as any]: time,["--e" as any]: _timeEnd }}  className="text-center bg-primary_color_1_3">
                    <span className="font-bold text-accent_color_3_2">{e.month}</span>
                  </div>
                  <div style={{ ["--c" as any]: time,["--e" as any]: _timeEnd }}  className="text-center bg-primary_color_1_3">
                    <span className="font-bold text-accent_color_3_2">{e.year}</span>
                  </div>
                </div>
              )
            }
          })
        }
        <div className="waveLoading12 pth-sign-research">
          <div style={{ ["--c" as any]: ++time,["--e" as any]: _timeEnd }} className="text-center">
            <span className="text-accent_color_3_2">{"="}</span>
          </div>
        </div>
        <div className="waveLoading12">
          <div style={{ ["--c" as any]: time,["--e" as any]: _timeEnd }} className="text-center pth-bg-result-research">
            <span className="font-bold text-neutral_color_1_8 bg-accent_color_3_2 pr-1 pl-1">{text}</span>
          </div>
        </div>
        <div className="finishAnimation" style={{ ["--s" as any]: _timeResultShow }}>{text}</div>
      </>
    </div>
    <div className='flex justify-center finishAnimationChart' style={{ ["--s" as any]: _timeResultShow }}>
      <div className="pth-content-2-3 px-8 py-8">
        {/* <LineChart 
          number={attitudeNumber}
        /> */}
        {
          dataSeries && 
          <ApexChart 
            dataSeries={dataSeries} 
            dataCategories={dataCategories}
            dataSerieShow={dataSerieShow}
            colorCategories={colorCategories}
            maxNum={maxNum}
          />
        }
        <div className="mt-3">
          <span className="text-neutral_color_1_8">TÍNH CÁCH NỔI TRỘI CỦA BẠN</span>
        </div>
        <div>
          <i className="text-neutral_color_1_8"><b>Điểm mạnh: </b></i>
        </div>
        {
          (strengths || []).map((item: any) => {
            return(
              <div>
                <i className="text-neutral_color_1_8">- {item}</i>
              </div>
            );
          })
        }
        <div>
          <i className="text-neutral_color_1_8"><b>Điểm yếu: </b></i>
        </div>
        {
          (weaknesses || []).map((item: any) => {
            return(
              <div>
                <i className="text-neutral_color_1_8">- {item}</i>
              </div>
            );
          })
        }
        <ButtonPTH
          type='default'
          fillIcon={colors.neutral_color_1_8}
          onClick={onChangeProps}
          className="mt-3 w-full"
          >
          <span>Xem chi tiết luận giải</span>
        </ButtonPTH>
      </div>
    </div>
    </>
  );
};

export default OverlaySpinner;
