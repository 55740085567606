import { Reducer } from 'redux';
import pyramidNumberTypes from '../actions/types/pyramidNumbers.types';
import { IPyramidNumberState } from '../interfaces/pyramidNumber.interfaces';

import { ISyntheticAction } from '../interfaces/root.interfaces';
import { rootState } from './state/root.states';

const initState: IPyramidNumberState = {
  stateGetPyramidNumber: { ...rootState },
};

const pyramidNumberReducer: Reducer<IPyramidNumberState, ISyntheticAction>
  = (state: IPyramidNumberState = initState, action: ISyntheticAction) => {
    const { type, payload, response } = action;

    switch (type) {

      /*************************** START *************************/
      /*                       get pyramid Number                    */

      case pyramidNumberTypes.START_GET_PYRAMID_NUMBER: {
        const stateReducer: IPyramidNumberState = {
          ...state,
          stateGetPyramidNumber: {
            ...rootState,
            isLoading: true
          },
        };
        return stateReducer;
      }
      case pyramidNumberTypes.GET_PYRAMID_NUMBER_SUCCESS: {
        const stateReducer: IPyramidNumberState = {
          ...state,
          stateGetPyramidNumber: {
            ...rootState,
            isLoading: false,
            data: response.data,
            message: 'Thành công',
            success: true,
          }
        };
        return stateReducer;
      }
      case pyramidNumberTypes.GET_PYRAMID_NUMBER_FAILURE: {
        const stateReducer: IPyramidNumberState = {
          ...state,
          stateGetPyramidNumber: {
            ...rootState,
            isLoading: false,
            message: response.message,
            error: true,
          },
        };
        return stateReducer;
      }

      /**************************** END **************************/

      default:
        return state;
    }

  };

export default pyramidNumberReducer;