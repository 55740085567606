import { Col, Tag } from "antd";
import { useState } from "react";
import colors from "src/utils/colors";

import "./styles.less";

const CardAttitudeIndex = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHỈ SỐ THÁI ĐỘ CỦA BẠN LÀ: <span className="text-accent_color_2_1">
          {props.data?.number}</span></strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Số thái độ là một thành phần quan trọng của số học. Nó mô tả các bạn thể hiện mình với phần còn lại 
                  của thế giới một cách tự nhiên, đặc biệt là trong những lần gặp gỡ đầu tiên.
                  </i>
                </div>
                <div>
                  <i>
                  Mặc dù khong phải lúc nào bạn cũng có thể nhận thức được hành động của mình, nhưng con số thái độ 
                  của bạn mô tả cách bạn hành động với người khác một cách tự nhiên (có thể hiểu giống như bản năng 
                  của bạn).
                  </i>
                </div>
                <div>
                  <i>
                  Một khi bạn hiểu về cách bạn cư xử tự nhiên với người khác và trong cuộc sống nói chung, bạn có thể 
                  thay đổi nó nếu bạn tháy thích. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích về số <b>{props.data?.number}</b> này của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                {
                  (props.data?.description || []).map((item: any) => {
                    return(
                      <div>
                        <i>{item}</i>
                      </div>
                    );
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardAttitudeIndex;
