import { call, put, takeLatest } from 'redux-saga/effects';
import { API_CONFIRM_ACCOUNT, API_HISTORY_SEARCH, API_MEANING_OF_NUMBER } from 'src/services/api/url.index';
import { dateFormatYMD_hms, nowDate } from 'src/utils/helpers/functions/date';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import searchNumerologyTypes from '../types/searchNumerology.types';

function* searchNumerology(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        filter: payload.params
      }
      return api.get(`${API_URL}/${API_MEANING_OF_NUMBER}`, _filter)
    });
    yield put(actionSuccess(searchNumerologyTypes.SEARCH_NUMEROLOGY_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(searchNumerologyTypes.SEARCH_NUMEROLOGY_FAILURE, err));
  }
};
function* setDataNumerology(action: IAction) {
  try {
    const { payload } = action;
    if(payload.params.isSearch){
      const _paramsUpdateAccount = {
        keys: [payload.params.id],
        data: {
          count_vip_search: payload.params.count_vip_search
        }
      }   
      api.patch(`${API_URL}/${API_CONFIRM_ACCOUNT}`, _paramsUpdateAccount)
      const _paramsCreateHistory = {
        full_name: payload.params.full_name,
        birthday: payload.params.birthday,
        common_name: payload.params.common_name,
        gender_id: payload.params.gender_id,
        created_at: nowDate(dateFormatYMD_hms),
        user_id: payload.params.id,
        type_name: payload.params.type_name,
      }   
      api.post(`${API_URL}/${API_HISTORY_SEARCH}`,_paramsCreateHistory)
    }
    const res: IRootResponse = {
      data: payload.params,
      message: 'Thành công',
      statusCode: 200,
    };
    yield put(actionSuccess(searchNumerologyTypes.SET_DATA_NUMEROLOGY_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(searchNumerologyTypes.SET_DATA_NUMEROLOGY_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(searchNumerologyTypes.START_SEARCH_NUMEROLOGY, searchNumerology),
  takeLatest(searchNumerologyTypes.START_SET_DATA_NUMEROLOGY, setDataNumerology),
]

