import { Col, Tag } from "antd";
import { useState } from "react";
import colors from "src/utils/colors";

import "./styles.less";

const CardMissionIndex = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHỈ SỐ SỨ MỆNH CỦA BẠN LÀ: <span className="text-accent_color_2_1">
          {props.data?.number}</span></strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Trong thần số học, chỉ số sứ mệnh giúp bạn biết cách đạt được mục tiêu của bạn, lớn và nhỏ. 
                  Sứ mệnh khác với số đường đời. Con số đường đời của bạn ám chỉ đến mục đích tổng thể lớn hơn 
                  của bạn. Chỉ số sứ mệnh của bạn tập trung nhiều hơn vào các đặc điểm, tính cách của bạn. Nhưng 
                  số đường đời và số mệnh của bạn có thể đi đôi với nhau. Theo thần số học, số đường đời của bạn 
                  cho bạn biết bạn đến cuộc đời này để làm gì, số sứ mệnh của bạn mô tả cách bạn tiếp tục thực hiện nó.
                  </i>
                </div>
                <div>
                  <i>
                  Phép tính đơn giản của chúng tôi xác định số sứ mệnh giúp bạn xác định các đặc điểm chính của mình và 
                  nơi mà bạn xuất sắc trong cuộc sống. Không chỉ một giai đoạn của cuộc đời, mà con số này tác động vào 
                  mọi giai đoạn của cuộc đời bạn.
                  </i>
                </div>
                <div>
                  <i>
                  Bạn có thể chọn một con đường đi, nhưng nó có thể không phải lúc nào cũng đúng. Chỉ số này giúp 
                  xác định con đường nào là con đường phù hợp sẽ khiến bạn hài lòng và mãn nguyện. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích về số <b>{props.data?.number}</b> này của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                {
                  (props.data?.description || []).map((item: any) => {
                    return(
                      <div>
                        <i>{item}</i>
                      </div>
                    );
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardMissionIndex;
