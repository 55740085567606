import { Col } from "antd";
import { useState } from "react";

import "./styles.less";

const CardPersonality = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */

  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. NHÓM TÍNH CÁCH THEO BẢN NGÃ CỦA BẠN (có thể thay đổi do luyện tập)</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của các dao động này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <div>
                <i>
                Đây là các nhóm tính cách có trong bản ngã (tính cách khi sinh ra đã có) của bạn. 
                Bạn nên tập trung luyện tập những nhóm tính cách có % dao động thấp nhất. Dao động 
                tính cách đẹp nhất khi các chỉ số % gần bằng nhau. Bạn hoàn toàn có thể luyện tập 
                để cân bằng và kiểm soát các dao động tính cách của bạn. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                </i>
              </div>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          <div className="mb-2">
            <div><span className="font-medium">1.1. Mạnh mẽ - Độc lập - Tự tin</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#CB0000", ["--w" as any]: "10%"}}>
              <span>5%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.2. Lắng nghe - Khéo léo - Nhạy cảm</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#FF9900", ["--w" as any]: "10%"}}>
              <span>5%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.3. Sáng tạo - Hoạt bát - Lạc quan</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#CCCC00", ["--w" as any]: "20%"}}>
              <span>10%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.4. Cẩn thận - Cầu toàn - Thực tế</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#339900", ["--w" as any]: "6%"}}>
              <span>3%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.5. Năng động - Linh hoạt - Tò mò</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#0066CC", ["--w" as any]: "30%"}}>
              <span>15%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.6. Qua tâm - Yêu thương - Kiểm soát</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#2C1EE6", ["--w" as any]: "6%"}}>
              <span>3%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.7. Thông thái - Khám phá - Truyền đạt</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#996699", ["--w" as any]: "30%"}}>
              <span>15%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.8. Công bằng - Tập trung - Lý tưởng</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#F26283", ["--w" as any]: "70%"}}>
              <span>35%</span>
            </div>
          </div>
          <div className="mb-2">
            <div><span className="font-medium">1.9. Trách nhiệm - Rộng lượng - Hào phóng</span></div>
            <div className="pth-chart-bar" style={{ ["--c" as any]: "#FFCC00", ["--w" as any]: "20%"}}>
              <span>10%</span>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardPersonality;
