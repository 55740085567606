import { Col, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomTable from "src/components/table/CustomTable";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { calcDataTableStrength, checkArrowStrength, getArrayNumberByBirthDay, getArrayNumberByName, getDecryptionArrayNumber } from "src/utils/helpers/functions/helpFunctions";
import { removeSign } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsChartIndex, defaultDataStrengthIndex, keyDecryptionArrayNumber } from "./data";

import "./styles.less";

const CardNameAndSumChart = (props: any) => {
  const [showMoreNameChart, setShowMoreNameChart] = useState(false);
  const [showMoreSumChart, setShowMoreSumChart] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [dataCommonNameChart, setDataCommonNameChart] = useState(defaultDataStrengthIndex);
  const [dataSumChart, setDataSumChart] = useState(defaultDataStrengthIndex);
  
  const [dataSumDecryptionArrayNumber, setDataSumDecryptionArrayNumber] = useState<any>([]);
  const [dataSumDecryptionArrayNumberDesc, setDataSumDecryptionArrayNumberDesc] = useState<any>([]);

  const [dataArrowIndexBirthday, setDataArrowIndexBirthday] = useState<any>([]);
  const [dataArrowIndex, setDataArrowIndex] = useState<any>([]);
  const [dataArrowIndexLostDesc, setDataArrowIndexLostDesc] = useState<any>([]);
  const [dataArrowIndexFullDesc, setDataArrowIndexFullDesc] = useState<any>([]);
  
  const isMount = useIsMount();
  const {stateGetArrowIndex} = useSelector((state: AppState) => state.arrowIndexReducer);
  const {stateGetNumberOfRepetition} = useSelector((state: AppState) => state.numberOfRepetitionReducer);
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    calcSumChart(props.common_name, props.birthday);
  }, [props.common_name]);

  useEffect(() => {
    if (isMount)
      return
    const { success, data } = stateGetNumberOfRepetition;
    if (success) {
      const _dataSumDecryptionArrayNumber = dataSumDecryptionArrayNumber.filter((item: any) => item.count === 0);
      const _dataSumDecryptionArrayNumberAlone = dataSumDecryptionArrayNumber.filter((item: any) => item.count > 0);
      const _description:any = [];

      _dataSumDecryptionArrayNumber.forEach((element: any) => {
        const _find:any = data.find((itemData: any) => itemData.number === element.number);
        const _desc = _find[keyDecryptionArrayNumber[element.count]];
        _description.push(`- Chưa bù trừ được số ${element.number} bị thiếu - ${_desc}`);
      });

      _dataSumDecryptionArrayNumberAlone.forEach((element: any) => {
        if(element.isAlone){
          _description.push(`- Chưa phá vỡ được số ${element.number} lẻ loi (Xem lại biểu đồ sức mạnh)`);
        }
      });

      const _newData = [
        ..._description,
        ...dataSumDecryptionArrayNumberDesc,
      ]
      setDataSumDecryptionArrayNumberDesc(_newData)
    }
  }, [stateGetNumberOfRepetition.isLoading, dataSumDecryptionArrayNumber]);

  useEffect(() => {
    if (isMount)
      return
    const { success, data } = stateGetArrowIndex;
    if (success) {
      const _dataArrowLost = dataArrowIndex.filter((item: any) => item.count !== 3).sort((a: any, b: any) => a.count - b.count);
      const _dataArrowFull = dataArrowIndex.filter((item: any) => item.count === 3);
      let dataArrowFullFromBirthDay = dataArrowIndexBirthday.filter((item: any) => item.count === 3);
      dataArrowFullFromBirthDay = dataArrowFullFromBirthDay.map((element: any) => element.key);

      const _descriptionLost = _dataArrowLost.map((item: any) => {
        const _find = data.find((itemData: any) => itemData.axis === item.key);
        const _descStr = item.count === 0 ? 'Thiếu trục' : 'Chưa đủ trục';
        return(`- ${_descStr} ${_find.axis_name} (${_find.axis}) - ${_find.meaning_without_axis}`);
      });
      setDataArrowIndexLostDesc(_descriptionLost)

      let _descriptionFull = _dataArrowFull.map((item: any) => {
        const _find = data.find((itemData: any) => itemData.axis === item.key);
        const _descStr = dataArrowFullFromBirthDay.includes(item.key) ? 'Biểu đồ sức mạnh đã có trục' : 'Tên của bạn đã bổ sung trục';
        const type = dataArrowFullFromBirthDay.includes(item.key) ? 1 : 2;
        return({
          type: type,
          description: `- ${_descStr} ${_find.axis_name} (${_find.axis}) - ${_find.meaning_with_axis}`
        });
      });
      
      _descriptionFull = _descriptionFull.sort((a: any, b: any) => a.type - b.type);
      
      setDataArrowIndexFullDesc(_descriptionFull)
    }
  }, [stateGetArrowIndex.isLoading, dataSumDecryptionArrayNumber]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const calcSumChart = (common_name: string, birthday: string) => {
    const arrNumberByName:any = getArrayNumberByName(common_name);
    const _dataCommonNameChart = calcDataTableStrength(arrNumberByName);
    
    setDataCommonNameChart(_dataCommonNameChart)
    
    const arrNumberByBirthDay: any = getArrayNumberByBirthDay(birthday);
    const arrSumChart: any = [
      ...arrNumberByName,
      ...arrNumberByBirthDay
    ];
    const _dataSumChart = calcDataTableStrength(arrSumChart);
    setDataSumChart(_dataSumChart);

    const sumDecryptionArrayNumber = getDecryptionArrayNumber(arrSumChart);
    setDataSumDecryptionArrayNumber(sumDecryptionArrayNumber);
    
    const checkArrowBirtday = checkArrowStrength(arrNumberByBirthDay);
    setDataArrowIndexBirthday(checkArrowBirtday);

    const checkArrow = checkArrowStrength(arrSumChart);
    setDataArrowIndex(checkArrow);
  }

  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center' id='card_index_year'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. BIỂU ĐỒ TÊN VÀ BIỂU ĐỒ TỔNG HỢP</strong></h1>
          <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
            <Row gutter={[8,8]}>
              <Col span={12}>
                <div>
                  <div className="flex justify-center">
                    <CustomTable
                      rowKey={"id"}
                      bordered
                      showHeader={false}
                      columns={columnsChartIndex()}
                      dataSource={dataCommonNameChart}
                      loading={false}
                      pagination={false}
                      className="pth-table-history pth-cell-h45"
                    />
                  </div>
                  <div className="text-center">
                    <strong>{removeSign(props.common_name).toUpperCase()}</strong>
                  </div>
                  {
                    !showMoreNameChart && (
                      <div className="text-center">
                        <i>
                        Biểu đồ này cho biết ... <span onClick={() => setShowMoreNameChart(true)}
                          className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                        </i>
                      </div>
                    )
                  }
                  {
                    showMoreNameChart && (
                      <div className="text-center">
                        <i>
                        Biểu đồ này cho biết những sức mạnh do tên của bạn mang lại. Chủ yếu dùng tên đó để gộp 
                        với ngày sinh tạo ra biểu đồ tổng hợp bên cạnh. <span onClick={() => setShowMoreNameChart(false)}
                        className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                        </i>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <div className="flex justify-center">
                    <CustomTable
                      rowKey={"id"}
                      bordered
                      showHeader={false}
                      columns={columnsChartIndex()}
                      dataSource={dataSumChart}
                      loading={false}
                      pagination={false}
                      className="pth-table-history pth-cell-h45"
                    />
                  </div>
                  <div className="text-center">
                    <strong>Biểu đồ tổng hợp</strong>
                  </div>
                  {
                    !showMoreSumChart && (
                      <div className="text-center">
                        <i>
                        Biểu đồ này cho biết ... <span onClick={() => setShowMoreSumChart(true)}
                          className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                        </i>
                      </div>
                    )
                  }
                  {
                    showMoreSumChart && (
                      <div className="text-center">
                        <i>
                        Biểu đồ này thể hiện bù trừ của tên vào ngày sinh của bạn. Các con số của tên 
                        lấp đầy các khoảng trống trong biểu đồ ngày sinh là đẹp nhất. <span onClick={() => setShowMoreSumChart(false)}
                        className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                        </i>
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
            {
              !showDescription && (
                <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                  onClick={() => setShowDescription(true)}>
                  <span>Xem giải thích 2 biểu đồ này</span>
                </Tag>
              )
            }
            {
              showDescription && (
                <>
                  <div className="mb-2">
                    {
                      dataArrowIndexFullDesc.map((item: any) => {
                        return(
                          <div>
                            <span className="text-accent_color_2_1 ml-2">{item.description}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="mb-2">
                    {
                      dataSumDecryptionArrayNumberDesc.length > 0 &&
                      <div>
                        <h3><strong className="text-accent_color_3_1 ml-2">TÊN CỦA BẠN CÒN CÁC YẾU ĐIỂM SAU:</strong></h3>
                      </div>
                    }
                    {
                      dataSumDecryptionArrayNumberDesc.map((item: any) => {
                        return(
                          <div>
                            <span className="text-accent_color_3_1 ml-2">{item}</span>
                          </div>
                        )
                      })
                    }
                    {
                      dataArrowIndexLostDesc.map((item: any) => {
                        return(
                          <div>
                            <span className="text-accent_color_3_1 ml-2">{item}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                  
                  <div className="mb-1">
                    <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                      onClick={() => setShowDescription(false)}>
                      <span>Ẩn giải thích</span>
                    </Tag>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardNameAndSumChart;
