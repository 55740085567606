import { Reducer } from "react";
import { combineReducers } from "redux";
import { IRootReducers } from "../interfaces/root.interfaces";
import globalReducer from "./global.reducer";
import accountReducer from "./account.reducer";
import uploadReducer from "./upload.reducer";
import searchNumerologyReducer from "./searchNumerology.reducer";
import pyramidNumberReducer from "./pyramidNumber.reducer";
import circleOfLifeReducer from "./circleOfLife.reducer";
import personalMonthYearReducer from "./personalMonthYear.reducer";
import meanOfIndexReducer from "./meanOfIndex.reducer";
import arrowIndexReducer from "./arrowIndex.reducer";
import numberOfRepetitionReducer from "./numberOfRepetition.reducer";
import historySearchReducer from "./historySearch.reducer";

const rootReducers = combineReducers<IRootReducers>({
  globalReducer,
  accountReducer,
  uploadReducer,
  searchNumerologyReducer,
  pyramidNumberReducer,
  circleOfLifeReducer,
  personalMonthYearReducer,
  meanOfIndexReducer,
  arrowIndexReducer,
  numberOfRepetitionReducer,
  historySearchReducer,
});

export default rootReducers;
