import { call, put, takeLatest } from 'redux-saga/effects';
import { API_MEANING_OF_INDEX } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import meanOfIndexTypes from '../types/meanOfIndex.types';

function* getMeanOfIndex(action: IAction) {
  try {
    // yield delay(500, true);
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {
        filter: { "number": {"_in": payload.params}}
      }
      return api.get(`${API_URL}/${API_MEANING_OF_INDEX}`, _filter)
    });
    yield put(actionSuccess(meanOfIndexTypes.GET_MEAN_OF_INDEX_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(meanOfIndexTypes.GET_MEAN_OF_INDEX_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(meanOfIndexTypes.START_GET_MEAN_OF_INDEX, getMeanOfIndex),
]

