import { Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonPTH from "src/components/buttons/ButtonPTH";
import { notifyError } from "src/components/notification";
import { EXCEPT_NUMEROLOGY } from "src/constants";
import { searchNumerology } from "src/services/actions/searchNumerology.actions";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import routerNames from "src/utils/data/routerName";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { calcNumerology, calNumberData, getDayMonthYear } from "src/utils/helpers/functions/helpFunctions";
import { removeSign } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import ReSearch from "./components/re-search";
import ReSearchDetail from "./components/re-search-detail";

const MainReSearch = () => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [typeScreen, setTypeScreen] = useState(1);
  const [dataSearch, setDataSearch] = useState<any>(false);
  const [defaultData, setDefaultData] = useState<any>(false);

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const history = useHistory();
  const {stateSearchNumerology, stateSetDataNumerology} = useSelector((state: AppState) => state.searchNumerologyReducer);
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    const { success, message, error, data } = stateSetDataNumerology;
    if (success) {
      onSearchNumgolory(data)
    } else {
      history.push({
        pathname: routerNames.HOME,
      });
    }
  }, [stateSetDataNumerology.isLoading]);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateSearchNumerology;
    if (success) {
      setLoading(false);
      setDataSearch(data)
    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateSearchNumerology.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const onSearchNumgolory = (data: any) => {
    const _dataCalc = calNumber(data.birthday);
    const _data = {
      full_name: data.full_name,
      birthday: data.birthday,
      common_name: data.common_name,
      gender_id: data.gender_id,
      data: _dataCalc.data,
      resultNumber: _dataCalc.resultNumber,
      resultDay: _dataCalc.resultDay,
      resultMonth: _dataCalc.resultMonth,
      resultYear: _dataCalc.resultYear,
      time:7
    }
    
    getResultSearch(_dataCalc.resultNumber)
    setDefaultData(_data)
  }

  const calNumber = (params: any) => {
    const { _day, _month, _year } = getDayMonthYear(params);
    const _data = [
      {
        day: `Ngày ${_day}`,
        month: `Tháng ${_month}`,
        year: `Năm ${_year}`,
        isSign: false,
      },
      {
        day: "->",
        month: "->",
        year: "->",
        isSign: true,
      },
    ]
    let conditionDate = _day;
    let conditionMonth = _month;
    let conditionYear = _year;
    while ((+conditionDate > 9 && !EXCEPT_NUMEROLOGY.includes(+conditionDate)) || 
    (+conditionMonth > 9 && !EXCEPT_NUMEROLOGY.includes(+conditionMonth)) || 
    (+conditionYear > 9 && !EXCEPT_NUMEROLOGY.includes(+conditionYear))) {
      const _resultDate = calNumberData(conditionDate);
      const _resultMonth = calNumberData(conditionMonth);
      const _resultYear = calNumberData(conditionYear);

      conditionDate = _resultDate.number;
      conditionMonth = _resultMonth.number;
      conditionYear = _resultYear.number;
      const _dataAdd = {
        day: _resultDate.desc,
        month: _resultMonth.desc,
        year: _resultYear.desc,
        isSign: false,
      }
      const _sign = {
        day: "->",
        month: "->",
        year: "->",
        isSign: true,
      }

      _data.push(_dataAdd);
      _data.push(_sign);
    }
    _data.pop();
    
    let _numerology: any = +conditionDate + +conditionMonth + +conditionYear;
    _numerology = calcNumerology(_numerology);
    const ressult = {
      data: _data,
      resultDay: conditionDate,
      resultMonth: conditionMonth,
      resultYear: conditionYear,
      resultNumber: _numerology,
    }
    return ressult;
  }

  const onOk = () => {
    setVisible(false)
  }
  const getResultSearch = (number: any) => {
    const _params = {
      number
    }
    dispatch(searchNumerology(_params));
  }
  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */

  const _renderForm = () => {
    switch (typeScreen) {
      case 2:
        return <ReSearchDetail data={dataSearch} defaultData={defaultData}/>
        
      default:
        return <ReSearch defaultData={defaultData} data={dataSearch} onChangeType={() => setTypeScreen(2)}/>
    }
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Spin spinning={loading}>
      <div className={`flex-col flex items-center justify-start pth-bg-img-login pth-screen`}>
        <Modal closable={false} visible={visible}
          footer={null}
          width="50%"
          centered
          className="pth-modal-transparent"
        >
          {
            defaultData && (
            <div className="flex flex-col justify-center items-center">
              <div className="text-center">
                <p>Xin chúc mừng!</p>
                <p>Luận giải về <span className="font-bold text-accent_color_3_2">{removeSign(defaultData.full_name).toUpperCase()}</span> đã</p>
                <p>sẵn sàng.</p>
              </div>
              <div>
                <ButtonPTH
                  type='default'
                  disable={loading}
                  fillIcon={colors.neutral_color_1_8}
                  onClick={onOk}
                  className="w-40 pth-text-24"
                  >
                  <span>Xem ngay</span>
                </ButtonPTH>
              </div>
            </div>
            )
          }
        </Modal>
        {!visible && defaultData && _renderForm()}
      </div>
    </Spin>
  );

  /**************************** END **************************/

};

export default MainReSearch;
