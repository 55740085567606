import React, { useState, useEffect } from 'react';
import { typeScreenManagerSignIn } from 'src/constants';
import auth from '../../../firebase.config';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { Input } from 'antd';
import ButtonPTH from 'src/components/buttons/ButtonPTH';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAccount } from 'src/services/actions/account.actions';
import { useIsMount } from 'src/utils/helpers/functions/useIsMount';
import { notifyError, notifySuccess } from 'src/components/notification';
import { getMessageV1 } from 'src/utils/helpers/functions/getMessage';

const SendOTP = (props) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [disabled, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typeBtn, setTypeBtn] = useState('transparent');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState('INPUT_PHONE_NUMBER');
    const [result, setResult] = useState('');
    const {phone, stateRegister, stateConfirmAccount} = useSelector((state) => state.accountReducer);
    const dispatch = useDispatch();
    const isMount = useIsMount();

    useEffect(() => {
      window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          signin();
        }
      }, auth);
    }, []);

    useEffect(() => {
      if(props.phone){
        setPhoneNumber(props.phone);
        setDisable(true);
        setTypeBtn('pth')
      }
    }, []);

    // useEffect(() => {
    //   if (isMount)
    //     return
    //   const { success, message, error, data } = stateConfirmAccount;
    //   if (success) {
    //     // notifySuccess('Đăng ký thành công');
    //     changeDirector(typeScreenManagerSignIn.ACTIVE_ACCOUNT_SUCCESS);
  
    //   } else if (success === false || error) {
    //     notifyError(getMessageV1(message));
    //   }
    // }, [stateConfirmAccount.isLoading]);

	const signin = () => {
		if (phoneNumber === "") return;
    setLoading(true);
    const appVerifier = window.recaptchaVerifier;
    const _phoneNumber = `+84${phoneNumber.slice(1)}`;
		
    signInWithPhoneNumber(auth, _phoneNumber,appVerifier).then((result) => {
      setResult(result);
      setStep('VERIFY_OTP');
      setLoading(false);
    })
      .catch((err) => {
        notifyError(getMessageV1(err));
        setLoading(false);
      });
	}

	const ValidateOtp = () => {
		if (otp === null) return;
        
      setLoading(true);
		result.confirm(otp).then((result) => {
      setLoading(false);
          // setStep('VERIFY_SUCCESS');
          // if(stateRegister.data && stateRegister.data.id){
          //   dispatch(confirmAccount({id: stateRegister.data.id}))
          // }else{
            
          // }
          if(props.isChangePass){
            changeDirector(typeScreenManagerSignIn.CHANGE_PASSWORD)
          }else{
            props.onConfirmOTP()
          }
		})
        .catch((err) => {
          setLoading(false);
          notifyError(getMessageV1('Mã không chính xác'));
       })
	}

  const changeDirector = (values) => {
    props.changeType(values)
}

	return (
		<div className={props.isChangePass ? 'px-8 py-8' : ''}>
			<center>

                {step === 'INPUT_PHONE_NUMBER' &&
                  <div>
                    <Input disabled={disabled} value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }}
                      placeholder="Nhập số điện thoại" />
                    <br/><br/>
                    <div id="recaptcha-container"></div>
                    <ButtonPTH
                      id='sign-in-button'
                      type={typeBtn}
                      htmlType='button'
                      onClick={signin}
                      loading={loading}>
                      Gửi OTP
                    </ButtonPTH>
                  </div>
                }

                {step === 'VERIFY_OTP' &&
                  <div>
                    <Input type="text" onChange={(e) => { setOtp(e.target.value) }}
                      placeholder="Nhập OTP" />
                    <br/><br/>
                    <ButtonPTH
                      id='verify-button'
                      type={typeBtn}
                      htmlType='button'
                      onClick={ValidateOtp}
                      loading={loading}>
                      Xác nhận OTP
                    </ButtonPTH>
                  </div>
                }

                {step === 'VERIFY_SUCCESS' &&
                  <h3>Xác nhận thành công</h3>
                }

                {step === 'VERIFY_FAIL' &&
                  <h3>Xác nhận thất bại</h3>
                }
			</center>
      {
        props.isChangePass && 
        <div>
          <hr className='mt-2 mb-2 border-accent_color_3_2'/>
          <div className='t-a-end'>
            <span className='text-14 font-medium text-accent_color_3_2'>
            Bạn  đã có tài khoản ? <a className='text-sm a-login text-left font-bold cursor-pointer' onClick={(e) => changeDirector(typeScreenManagerSignIn.SIGN_IN)}> Đăng nhập tại đây</a>
            </span>
          </div>
        </div>
      }
      
		</div>
	);
}

export default SendOTP;

