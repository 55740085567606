import { Col, Tag } from "antd";
import { useEffect, useState } from "react";
import colors from "src/utils/colors";

import "./styles.less";

const CardLifePathIndex = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [data, setData] = useState<any>({});
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    if(props.data && props.data.strengths){
      const strengths = props.data.strengths.split('-');
      strengths.shift();
      const weaknesses = props.data.weaknesses.split('-');
      weaknesses.shift();
      const lesson_learned = props.data.lesson_learned.split('-');
      lesson_learned.shift();
      const occupation = props.data.occupation.split('-');
      occupation.shift();
      
      const _data = {
        ...props.data,
        strengths,
        weaknesses,
        lesson_learned,
        occupation,
      }
      setData(_data);
    }
  }, [props.data]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHỈ SỐ ĐƯỜNG ĐỜI (SỐ CHỦ ĐẠO) CỦA BẠN LÀ: <span className="text-accent_color_2_1">
            {`SỐ ${data?.number} - ${data.name_of_number && data.name_of_number.toUpperCase()}`}</span></strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <div>
                <i>
                Chỉ số này hé lộ con đường mà bạn sẽ trải qua trong cuộc đời này. Nó cho bạn thấy bạn sẽ gặp phải 
                những trải nghiệm như thế nào, và bạn học được gì sau những trải nghiệm đó. Nó cung cấp nhiều thông 
                tin về con người bạn và cuộc đời mà bạn sẽ sống. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                </i>
              </div>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích về số <b>{props.number}</b> của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                <div>
                  <i><b>Điểm mạnh: </b></i>
                </div>
                {
                  (data.strengths || []).map((item: any) => {
                    return(
                      <div>
                        <i>- {item}</i>
                      </div>
                    );
                  })
                }
                <div>
                  <i><b>Điểm yếu:</b></i>
                </div>
                {
                  (data.weaknesses || []).map((item: any) => {
                    return(
                      <div>
                        <i>- {item}</i>
                      </div>
                    );
                  })
                }
                <div>
                  <i><b>Bài học:</b></i>
                </div>
                {
                  (data.lesson_learned || []).map((item: any) => {
                    return(
                      <div>
                        <i>- {item}</i>
                      </div>
                    );
                  })
                }
                <div>
                  <i><b>Định hướng nghề nghiệp:</b></i>
                </div>
                {
                  (data.occupation || []).map((item: any) => {
                    return(
                      <div>
                        <i>- {item}</i>
                      </div>
                    );
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardLifePathIndex;
