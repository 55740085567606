/* eslint-disable */
import { Col, Form, Input, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonPTH from "src/components/buttons/ButtonPTH";
import FormInput from "src/components/form/FormInput";
import { notifyError, notifySuccess } from "src/components/notification";
import { getInfoAccount, updateInfoAccount } from "src/services/actions/account.actions";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { localGetFormLogin } from "src/utils/localStorage";

const Profile = (props: any) => {
  const [changeForm] = Form.useForm();
  const {dataUser,spin}= props
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<any>(false);
  const [isChangePassword, setIsChangePassword] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [dataProfile, setDataProfile] = useState<any>([]);
  const {stateGetInfoAccount,stateUpdateInfoAccount} = useSelector((state: AppState) => state.accountReducer);
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetInfoAccount;
    if (success) {
      setDataProfile(data)
    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateGetInfoAccount.isLoading]);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateUpdateInfoAccount;
    if (success) {
      setVisible(false);
      dispatch(getInfoAccount({}));
      notifySuccess('Cập nhật thành công');
    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateUpdateInfoAccount.isLoading]);

  useEffect(() => {
    dispatch(getInfoAccount({}));
  }, []);

  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */

  const onSubmit = (values: any) => {
    const _params = {
      id: dataProfile?.id,
      full_name: values.full_name,
    }
    dispatch(updateInfoAccount(_params));
  };

  const onOpenModal = (isChangePass: boolean = false) => {
    setIsChangePassword(isChangePass);
    setVisible(true)
  };

  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    
    <div className="mt-3 mb-3">
      <Spin spinning={loading}>
      {
        visible && (
          <Modal
            visible={visible}
            maskClosable={false}
            title={!isChangePassword ? 'Thay đổi thông tin' : 'Thay đổi mật khẩu'}
            closable={false}
            footer={false}
            className="pth-modal-reponse"
          >
            <Form
              form={changeForm}
              requiredMark={false}
              layout='vertical'
              onFinish={onSubmit}
              initialValues={{
                full_name: dataProfile?.full_name,
              }}
              className='w-full h-full flex justify-center items-center flex-col '>
                {
                  !isChangePassword && (
                    <FormInput
                      id='form-full_name'
                      label='Họ và tên'
                      name='full_name'
                      type='text'
                      placeholder="Nhập họ và tên"
                      className="w-full"
                      required
                    />
                  )
                }
                {
                  isChangePassword && (
                    <>
                      <FormInput
                        id='form-password'
                        name='password'
                        label='Mật khẩu mới'
                        type='password'
                        placeholder="Nhập mật khẩu mới"
                        className="w-full input-pass"
                        required
                      />
                      <Form.Item
                        name="confirm"
                        label="Nhập lại mật khẩu mới"
                        dependencies={['password']}
                        className="w-full"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Nhập lại mật khẩu không khớp!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Nhập lại mật khẩu mới"/>
                      </Form.Item>
                    </>
                  )
                }
                <Row>
                  <Col span={24} className="text-right">
                    <ButtonPTH
                      id="form-button-confirm"
                      type="pth"
                      htmlType="submit"
                    >
                      {!isChangePassword ? 'Đổi thông tin' : 'Đổi mật khẩu'}
                    </ButtonPTH>
                    <ButtonPTH
                      id="form-button-cancle"
                      type="danger"
                      className="ml-3"
                      onClick={() => setVisible(false)}
                    >
                      Đóng
                    </ButtonPTH>
                  </Col>
                </Row>
            </Form>
          </Modal>
        )
      }
        <Row gutter={[8,8]}>
          <Col span={24} className='flex justify-center'>
            <div className="w-4/5 px-2 py-2">
              <div className="text-center font-medium pth-text-24"><h1 className="text-accent_color_5_5">Thông tin của bạn</h1></div>
              <div><span>Họ tên:</span></div>
              <div><p className="font-bold pl-2 py-2">{dataProfile?.full_name}</p></div>
              <div><span>Email (Tên đăng nhập):</span></div>
              <div><p className="font-bold pl-2 py-2">{dataProfile?.email}</p></div>
              <div><span>Số điện thoại:</span></div>
              <div><p className="font-bold pl-2 py-2">{dataProfile?.phone}</p></div>
              
              <div className="text-center">
                <ButtonPTH
                  type='pth'
                  fillIcon={colors.neutral_color_1_8}
                  className="w-40 pth-text-24 mx-2"
                  onClick={() => onOpenModal()}
                  >
                  <span>Thay đổi thông tin</span>
                </ButtonPTH>
                <ButtonPTH
                  type='danger'
                  fillIcon={colors.neutral_color_1_8}
                  className="w-40 pth-text-24 mx-2"
                  onClick={() => onOpenModal(true)}
                  >
                  <span>Thay đổi mật khẩu</span>
                </ButtonPTH>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );

  /**************************** END **************************/
};

export default Profile;
