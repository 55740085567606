import React from "react";

function SvgIconKey(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill={props?.fill ? props.fill : "#000"}
        d="M5.179 25H0v-5.18s6.334-6.333 9.304-9.305a8.04 8.04 0 011.946-8.159c3.141-3.141 8.253-3.141 11.394 0A8.002 8.002 0 0125 8.053a8.002 8.002 0 01-2.356 5.697c-1.967 1.966-4.768 2.761-7.495 2.126a8.13 8.13 0 01-.662-.184l-2.101 2.101-1.874.136-.135 1.873-1.584 1.584-1.873.135-.136 1.874L5.179 25zm-3.714-1.465h3.107l.79-.79.188-2.593 2.593-.188.812-.812.188-2.593 2.593-.188 2.382-2.381.446.175c.297.116.606.212.917.285 2.23.519 4.521-.13 6.127-1.736a6.548 6.548 0 001.927-4.661 6.547 6.547 0 00-1.927-4.661 6.6 6.6 0 00-9.322 0c-1.837 1.837-2.408 4.603-1.455 7.047l.174.445-9.54 9.544v3.107z"
      ></path>
      <path
        fill={props?.fill ? props.fill : "#000"}
        d="M17.465 11.195a3.651 3.651 0 01-2.59-1.07 3.638 3.638 0 01-1.072-2.59c0-.978.38-1.898 1.072-2.59a3.666 3.666 0 015.18 0 3.666 3.666 0 010 5.18 3.65 3.65 0 01-2.59 1.07zm0-5.856a2.19 2.19 0 00-1.554.642 2.183 2.183 0 00-.643 1.554c0 .587.228 1.139.643 1.554a2.2 2.2 0 003.108 0 2.2 2.2 0 000-3.108 2.191 2.191 0 00-1.554-.642z"
      ></path>
    </svg>
  );
}

export default SvgIconKey;
