/* eslint-disable */
import { Col, Form, Row, Spin } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SvgGift from "src/assets/svg/SvgGift";
import ButtonPTH from "src/components/buttons/ButtonPTH";
import FormSelect from "src/components/form/FormSelect";
import CustomTable from "src/components/table/CustomTable";
import { dataPakage, selectOptionsId } from "src/constants";
import colors from "src/utils/colors";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsHistoryBuy, defaultDataHistoryBuy } from "./data";

const HistoryBuy = (props: any) => {
  const {dataUser,spin}= props
  const [buyForm] = Form.useForm();
  const isMount = useIsMount();
  const dispatch = useDispatch();
  const [paging, setPaging] = useState<object>({});
  const [customerPrice, setCustomerPrice] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */

  /**************************** END **************************/

  /****************************START**************************/
  /*                          Function                       */
  const btnCopy = () => {};

  const onBuy = () => {};

  // const onChangeTable = (page: any) => {
  //   const _filter = {
  //     ...paramsFilter,
  //     currentPage: page.current,
  //     sizePage: page.pageSize,
  //     isDispatch: true,
  //   };
  //   setParamsFilter(_filter);
  // };

  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    
    <div className="mt-3 mb-3">
      <Spin spinning={loading}>
        <Row gutter={[8,8]}>
          <Col span={24} className='flex justify-center'>
            <div className="w-4/5 px-2 py-2">
              <CustomTable
                rowKey={"pricelist_id"}
                bordered
                paging={paging}
                columns={columnsHistoryBuy({
                  btnCopy
                })}
                dataSource={defaultDataHistoryBuy}
                loading={false}
                // pagination={true}
                className="pth-table-history"
                // onChange={onChangeTable}
              />
            </div>
          </Col>
          <Col span={24} className="flex justify-center">
            <div className="w-4/5 px-2 py-2 text-center bg-neutral_color_1_6 ">
              <div>
                <span className="inline-flex font-medium text-accent_color_4_3 text-18 pth-animation-gift">
                  <SvgGift fill={colors.accent_color_4_3}/>
                  <span className="ml-2">Quà tặng!</span>
                </span>
              </div>
              <div className="mb-3">
                <span className="font-medium text-accent_color_4_3 text-18">Mua từ 3 lượt tra VIP trở lên 
                  chúng tôi sẽ tặng bạn thêm lượt tra online (theo bảng giá bên dưới) 
                  để tra thêm cho người khác.
                </span>
              </div>
              <div>
                <Form
                  form={buyForm}
                  requiredMark={false}
                  layout='vertical'
                  onFinish={onBuy}
                  initialValues={{
                    package_id: 1,
                  }}
                  className='w-full h-full flex justify-center items-center flex-col '>
                    
                    <FormSelect
                      name="package_id"
                      dataSource={dataPakage}
                      placeholder="Mua gói VIP"
                      slOption={selectOptionsId}
                      classItem='w-full'
                      required
                    />

                  <ButtonPTH
                    id='form-button-buy'
                    type='pth'
                    htmlType='submit'
                    fillIcon={colors.neutral_color_1_8}
                    >
                    Đặt mua ngay
                  </ButtonPTH>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );

  /**************************** END **************************/
};

export default HistoryBuy;
