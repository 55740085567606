/* eslint-disable */
import { Tag } from "antd";
import ButtonPTH from "src/components/buttons/ButtonPTH";
import { RenderColTable } from "src/components/table/RenderColTable";
import colors from "src/utils/colors";
import { numberWithCommas, removeSign } from "src/utils/helpers/functions/textUtils";

export const columnsHistorySearch = (props: any) => {
  return [
    {
      title: "#",
      dataIndex: "rank",
      key: "rank",
      align: "center",
      render: (text: string) => {
        return <span className="text-neutral_color_1_2">{text}</span>;
      },
    },
    {
      title: "Lịch sử tra cứu",
      dataIndex: "full_name",
      key: "full_name",
      width: "70%",
      render: (text: string, record: any) => {
        const newData = [
          {
            name: (
              <span
                onClick={() => props.btnSearchNumgolory(record)}
                className="font-medium text-accent_color_5_5 cursor-pointer"
              >
                {`${removeSign(text).toUpperCase()} ${record.birthday}`}
              </span>
            ),
          },
          {
            name: (
              <span className="text-neutral_color_1_2">
                <i>Thời gian: {record.datetime}</i>
              </span>
            ),
          },
          {
            name: (
              <span>
                <span className="text-neutral_color_1_2">
                  Loại tra: <span className="font-medium text-accent_color_2_1">{record.type_name}</span>
                </span>
              </span>
            ),
          },
        ];
        return <RenderColTable data={newData} />;
      },
    },
    {
      title: "PDF báo cáo",
      dataIndex: "url_download",
      key: "url_download",
      align: "center",
      width: "20%",
      // fixed: 'right',
      render: (text: string, record: any, index: number) => {
        return <ButtonPTH
                type='primary'
                fillIcon={colors.neutral_color_1_8}
                onClick={props.btnDownloadPDF(text)}
                icon="download"
                className="w-30 text-14"
                >
                <span>Tải xuống</span>
              </ButtonPTH>;
      },
    },
  ];
};

export const columnsHistoryBuy = (props: any) => {
  return [
    {
      title: "Bạn đã đặt mua",
      dataIndex: "bill_code",
      key: "bill_code",
      width: "80%",
      render: (text: string, record: any) => {
        const newData = [
          {
            name: (
              <span className="text-neutral_color_1_2">
                1. Mã đơn: <span className="font-medium">{text}</span>
                <Tag 
                  onClick={() => props.btnCopy(record)} 
                  color={colors.accent_color_5_5} 
                  className="cursor-pointer ml-2 rounded"
                >
                  Copy
                </Tag>
              </span>
            ),
          },
          {
            name: (
              <span>
                <span className="text-neutral_color_1_2">
                  2. Nội dung: <span className="font-medium">{record.description}</span>
                </span>
              </span>
            ),
          },
          {
            name: (
              <span>
                <span className="text-neutral_color_1_2">
                  3. Thời gian: <i>{record.datetime}</i>
                </span>
              </span>
            ),
          },
          {
            name: (
              <span>
                <span className="text-neutral_color_1_2">
                  4. Trạng thái: <span className="font-medium text-accent_color_2_1">{record.status_name}</span>
                </span>
              </span>
            ),
          },
        ];
        return <RenderColTable data={newData} />;
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: "20%",
      render: (text: string) => {
        return <span className="font-medium">{numberWithCommas(text)}đ</span>;
      },
    },
  ];
};

export const defaultDataHistorySearch = [
  {
    rank: 1,
    full_name: "PHAM TRUNG HIEU",
    birthday: "07/07/1992",
    common_name: "ty",
    gender_id: "1",
    datetime: "2022-03-18 21:29:27",
    type_name: "Vip + PDF",
    url_download: "",
  },
  {
    rank: 2,
    full_name: "nguyen thi bảo thương",
    birthday: "06/06/1991",
    common_name: "thương",
    gender_id: "0",
    datetime: "2022-07-01 15:13:55",
    type_name: "Free",
    url_download: "",
  },
];
export const defaultDataHistoryBuy = [
  {
    bill_code: "TS0281187",
    description: "Mua 1 lượt tra Vip",
    datetime: "2022-03-18 21:29:27",
    status_name: "Đã thanh toán",
    price: 180000
  },
];
