import { Col, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgBanEye from "src/assets/svg/SvgBanEye";
import { getPyramidNumbers } from "src/services/actions/pyramidNumbers.actions";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { calcNumerology, getDayMonthYear } from "src/utils/helpers/functions/helpFunctions";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import useWindowDimensions from "src/utils/helpers/layouts/dimensions";

import "./styles.less";

const CardPyramid = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [dataPyramid, setDataPyramid] = useState<any>({});
  const [dataDescriptionPyramid, setDataDescriptionPyramid] = useState<any>([]);
  const [isVip, setIsVip] = useState(true);
  const { width } = useWindowDimensions();
  const percent = width < 800 ? 0.7 : 1
  

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const {stateGetPyramidNumber} = useSelector((state: AppState) => state.pyramidNumberReducer);
  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    renderPyramid();
    calcPyramid();
  }, []);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateGetPyramidNumber;
    if (success) {
      const _period1 = data.filter((item: any) => item.period === 1);
      const _pinnacle1 = _period1.find((item: any) => item.number === dataPyramid?.top1Number);
      const _challenge1 = _period1.find((item: any) => item.number === dataPyramid?.try1Number);

      const _period2 = data.filter((item: any) => item.period === 2);
      const _pinnacle2 = _period2.find((item: any) => item.number === dataPyramid?.top2Number);
      const _challenge2 = _period2.find((item: any) => item.number === dataPyramid?.try2Number);

      const _period3 = data.filter((item: any) => item.period === 3);
      const _pinnacle3 = _period3.find((item: any) => item.number === dataPyramid?.top3Number);
      const _challenge3 = _period3.find((item: any) => item.number === dataPyramid?.try3Number);

      const _period4 = data.filter((item: any) => item.period === 4);
      const _pinnacle4 = _period4.find((item: any) => item.number === dataPyramid?.top4Number);
      const _challenge4 = _period4.find((item: any) => item.number === dataPyramid?.try4Number);
      
      setDataDescriptionPyramid([
        {
          title: `.1. GIAI ĐOẠN TỪ ĐẦU ĐỜI TỚI NĂM ${dataPyramid?.top1Age} TUỔI (${dataPyramid?.top1Year})`,
          titlePinnacle: `.1.1 Đỉnh cao của bạn trong giai đoạn này là số ${dataPyramid?.top1Number}`,
          descriptionPinnacle: _pinnacle1?.pinnacle,
          titleChallenge: `.1.2 Thử thách của bạn trong giai đoạn này là số ${dataPyramid?.try1Number}`,
          descriptionChallenge: _challenge1?.challenge,
        },
        {
          title: `.2 GIAI ĐOẠN HAI TỪ NĂM ${+dataPyramid?.top1Age +1} TUỔI (${+dataPyramid?.top1Year + 1}) TỚI ${dataPyramid?.top2Age} TUỔI (${dataPyramid?.top2Year})`,
          titlePinnacle: `.2.1 Đỉnh cao của bạn trong giai đoạn này là số ${dataPyramid?.top2Number}`,
          descriptionPinnacle: _pinnacle2?.pinnacle,
          titleChallenge: `.2.2 Thử thách của bạn trong giai đoạn này là số ${dataPyramid?.try2Number}`,
          descriptionChallenge: _challenge2?.challenge,
        },
        {
          title: `.3. GIAI ĐOẠN BA TỪ NĂM ${+dataPyramid?.top2Age +1} TUỔI (${+dataPyramid?.top2Year + 1}) TỚI ${dataPyramid?.top3Age} TUỔI (${dataPyramid?.top3Year})`,
          titlePinnacle: `.3.1 Đỉnh cao của bạn trong giai đoạn này là số ${dataPyramid?.top3Number}`,
          descriptionPinnacle: _pinnacle3?.pinnacle,
          titleChallenge: `.3.2 Thử thách của bạn trong giai đoạn này là số ${dataPyramid?.try3Number}`,
          descriptionChallenge: _challenge3?.challenge,
        },
        {
          title: `.4. GIAI ĐOẠN TỪ NĂM ${dataPyramid?.top4Age} TUỔI (${dataPyramid?.top4Year}) TỚI CUỐI ĐỜI`,
          titlePinnacle: `.4.1 Đỉnh cao của bạn trong giai đoạn này là số ${dataPyramid?.top4Number}`,
          descriptionPinnacle: _pinnacle4?.pinnacle,
          titleChallenge: `.4.2 Thử thách của bạn trong giai đoạn này là số ${dataPyramid?.try4Number}`,
          descriptionChallenge: _challenge4?.challenge,
        },
      ])
    }
  }, [stateGetPyramidNumber.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  const calcPyramid = () => {
    const _defaultNumber = 36;
    const _numberMilestones = 9;
    const resultDay = props.data.resultDay;
    const resultMonth = props.data.resultMonth;
    const resultYear = props.data.resultYear;
    let resultNumber = props.data.resultNumber;
    resultNumber = resultNumber === 1 ? 10 : (resultNumber === 22 ? 4 : resultNumber);

    const { _day, _month, _year } = getDayMonthYear(props.data.birthday);
    
    const top1Number = calcNumerology(resultMonth + resultDay, 1);
    const top1Age = _defaultNumber - resultNumber;
    const top1Year = +_year + top1Age;;
    const top2Number = calcNumerology(resultDay + resultYear, 1);
    const top2Age = top1Age + _numberMilestones;
    const top2Year = top1Year + _numberMilestones;
    const top3Number = calcNumerology(resultMonth + resultDay + resultDay + resultYear, 2);
    const top3Age = top2Age + _numberMilestones;
    const top3Year = top2Year + _numberMilestones;
    const top4Number = calcNumerology(resultMonth + resultYear, 2);
    const top4Age = top3Age + _numberMilestones;
    const top4Year = top3Year + _numberMilestones;
    const try1Number = calcNumerology(Math.abs(resultMonth - resultDay), 1);
    const try1Year = top1Year;
    const try2Number = calcNumerology(Math.abs(resultDay - resultYear), 1);  
    const try2Year = top2Year;  
    const try3Number = calcNumerology(Math.abs(Math.abs(resultMonth - resultDay) - Math.abs(resultDay - resultYear)), 2);
    const try3Year = top3Year;
    const try4Number = calcNumerology(Math.abs(resultMonth - resultYear), 2);
    const try4Year = top4Year;
    const result = {
      resultDay,
      resultMonth,
      resultYear,
      day: _day,
      month: _month,
      year: _year,
      top1Number,
      top1Age,
      top1Year,
      top2Number,
      top2Age,
      top2Year,
      top3Number,
      top3Age,
      top3Year,
      top4Number,
      top4Age,
      top4Year,
      try1Number,
      try1Year,
      try2Number,
      try2Year,
      try3Number,
      try3Year,
      try4Number,
      try4Year,
    }
    props.setDataCircleOfLife({
      top1Age,
      top1Year,
      top4Age,
      top4Year,
    })
    setDataPyramid(result);
    getDataPyramid([top1Number,try1Number],[top2Number,try2Number],[top3Number,try3Number],[top4Number,try4Number]);
  }

  const renderPyramid = () => {
    const canvas:any = document.getElementById('pth-canvas-pyramid');
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "#fff";
    ctx.fillRect (0, 0, 220 * percent, 250 * percent);
    
    // Đường thứ nhất
    ctx.beginPath();
    ctx.setLineDash([4, 1]);
    ctx.strokeStyle = 'blue';
    ctx.moveTo(20 * percent,250 * percent);
    ctx.lineTo(220* percent,0);
    ctx.lineTo(420 * percent,250 * percent);
    ctx.lineTo(220 * percent,500 * percent);
    ctx.lineTo(20 * percent,250 * percent);
    
    ctx.lineTo(170 *percent,350 * percent);
    ctx.lineTo(220 * percent,420 * percent);
    
    ctx.lineTo(270 * percent,350 * percent);
    ctx.lineTo(420 * percent,250 * percent);
    
    ctx.lineTo(270 * percent,150 * percent);
    ctx.lineTo(220 * percent,80 * percent);
    
    ctx.lineTo(170 * percent,150 * percent);
    ctx.lineTo(20 * percent,250 * percent);
    ctx.stroke();
    
    // Đường thứ hai
    ctx.beginPath();
    ctx.strokeStyle = 'blue';
    ctx.moveTo(170 * percent,150 * percent);
    ctx.lineTo(270 * percent,350 * percent);
    ctx.stroke();
    
    // Đường thứ ba
    ctx.beginPath();
    ctx.strokeStyle = 'blue';
    ctx.moveTo(270 * percent,150 * percent);
    ctx.lineTo(170 * percent,350 * percent);
    ctx.stroke();

    // Đường thứ 4
    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.moveTo(0,250 * percent);
    ctx.lineTo(440 * percent,250 * percent);
    ctx.stroke();
  }

  const getDataPyramid = (period1: any,period2: any,period3: any,period4: any) => {
    const _params = [
      period1,
      period2,
      period3,
      period4,
    ];
    dispatch(getPyramidNumbers(_params));
  }
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. KIM TỰ THÁP THẦN SỐ HỌC</strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của biểu đồ này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <div>
                <i>Kim tự tháp cho thấy 4 giai đoạn trong cuộc đời của bạn sẽ tương ứng với đỉnh cao là 
                  số nào và thử thách là con số nào, tức là bạn nên tập trung phát triển số nào trong những 
                  năm này để đạt được nhiều thành công nhất. Từ năm đỉnh cao đầu tiên đến năm cuối cùng (27 năm) 
                  chính là khoảng thời gian gặt hái nhiều thành công trong cuộc đời của bạn. Tuy vậy, trong 4 
                  giai đoạn này cũng sẽ có những thách thức cụ thể mà cuộc đời muốn bạn vượt qua - những con số 
                  thử thách sẽ nói lên điều đó. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                </i>
              </div>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md">
          <div className="flex justify-center px-10 py-10">
            <div className="pth-pyramid">
              <canvas id="pth-canvas-pyramid" width={440 * percent} height={500 * percent}></canvas> 
              <div className="pth-pyramid-pinnacle">
                <span>ĐỈNH CAO</span>
              </div>
              <div className="pth-pyramid-challenge">
                <span>THỬ THÁCH</span>
              </div>
              <div className="pth-tower-month">
                <p className="pth-tower-number">
                  {
                    isVip && <span>{dataPyramid?.resultMonth}</span>
                  }
                  {
                    !isVip && <SvgBanEye/>
                  }
                </p>
                <div className="pth-tower-description">Tháng {dataPyramid?.month}</div>
              </div>
              <div className="pth-tower-day">
                <p className="pth-tower-number">
                  {
                    isVip && <span>{dataPyramid?.resultDay}</span>
                  }
                  {
                    !isVip && <SvgBanEye/>
                  }
                </p>
                <div className="pth-tower-description">Ngày {dataPyramid?.day}</div>
              </div>
              <div className="pth-tower-year">
                <p className="pth-tower-number">
                  {
                    isVip && <span>{dataPyramid?.resultYear}</span>
                  }
                  {
                    !isVip && <SvgBanEye/>
                  }
                </p>
                <div className="pth-tower-description">{dataPyramid?.year}</div>
              </div>
              <div className="pth-tower-top-1">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.top1Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>{dataPyramid?.top1Age} tuổi</p>
                      <p>({dataPyramid?.top1Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-top-3">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.top3Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>{dataPyramid?.top3Age} tuổi</p>
                      <p>({dataPyramid?.top3Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-top-2">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.top2Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>{dataPyramid?.top2Age} tuổi</p>
                      <p>({dataPyramid?.top2Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-top-4">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.top4Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>{dataPyramid?.top4Age} tuổi</p>
                      <p>({dataPyramid?.top4Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-try-1">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.try1Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>({dataPyramid?.try1Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-try-3">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.try3Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>({dataPyramid?.try3Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-try-2">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.try2Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>({dataPyramid?.try2Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
              <div className="pth-tower-try-4">
                {
                  isVip && (
                  <>
                    <p className="pth-tower-number">
                      <span>{dataPyramid?.try4Number}</span>
                    </p>
                    <div className="pth-tower-description">
                      <p>({dataPyramid?.try4Year})</p>
                    </div>
                  </>
                  )
                }
                {
                  !isVip && (
                    <p className="pth-tower-number">
                      <SvgBanEye/>
                    </p>  
                  )
                }
              </div>
            </div>
          </div>
          <div className="text-center"><b>Kim tự tháp thần số học của bạn</b></div>
          <div className="mx-2 mt-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer mb-2" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích kim tự tháp thần số học của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                {
                  dataDescriptionPyramid.map((item: any) => {
                    const _descriptionPinnacle = item.descriptionPinnacle ? item.descriptionPinnacle.split('\n') : [];
                    const _descriptionChallenge = item.descriptionChallenge ? item.descriptionChallenge.split('\n') : [];
                    return(
                    <>
                      <div key={geneUniqueKey()}>
                        <i><b>
                        {props.rank}{item.title}
                        </b></i>
                      </div>
                      <div>
                        <i><b>
                        {props.rank}{item.titlePinnacle}
                        </b></i>
                      </div>
                      {
                        _descriptionPinnacle.map((itemDesc: any) => {
                          return(
                            <div key={geneUniqueKey()}>
                              <i>
                              {itemDesc}
                              </i>
                            </div>
                          );
                        })
                      }
                      <div>
                        <i><b>
                        {props.rank}{item.titleChallenge}
                        </b></i>
                      </div>
                      {
                        _descriptionChallenge.map((itemDesc: any) => {
                          return(
                            <div key={geneUniqueKey()}>
                              <i>
                              {itemDesc}
                              </i>
                            </div>
                          );
                        })
                      }
                    </>
                    )
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
          </div>
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardPyramid;
