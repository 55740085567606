import { Col, Tag } from "antd";
import { useState } from "react";
import colors from "src/utils/colors";

import "./styles.less";

const CardGrownUpIndex = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  
  /****************************START**************************/
  /*                         Life Cycle                      */
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */
  /**************************** END **************************/

  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/

  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <Col span={24} className='flex justify-center'>
      <div className="pth-content-2-3 px-2 py-2 bg-neutral_color_1_7 rounded-md">
        <div>
          <h1><strong>{props.rank}. CHỈ SỐ TRƯỞNG THÀNH CỦA BẠN LÀ: <span className="text-accent_color_2_1">
          {props.data?.number}</span></strong></h1>
          {
            !showMore && (
              <div>
                <i>
                Ý nghĩa của chỉ số này là ... <span onClick={() => setShowMore(true)}
                  className="text-accent_color_5_6 cursor-pointer">(Xem thêm)</span>
                </i>
              </div>
            )
          }
          {
            showMore && (
              <>
                <div>
                  <i>
                  Số trưởng thành của bạn cho biết hướng thành công và mong muốn tiềm ẩn dần dần xuất hiện 
                  vào khoảng từ 40 trở lên (một số người sớm hơn hoặc muộn hơn). Mục tiêu cơ bản này bắt 
                  đầu xuất hiện khi bạn hiểu rõ hơn về bản thân.
                  </i>
                </div>
                <div>
                  <i>
                  Với sự hiểu biết về bản thân, bạn sẽ nhận thức rõ hơn về con người của mình, mục tiêu 
                  thực sự của bạn trong cuộc sống là gì và bạn muôn đặt ra hướng đi nào cho cuộc đời của mình. 
                  Tóm lại đây là món quà của sự trưởng thành: bạn không còn lãng phí thời gian và năng lượng cho 
                  những thứ không thuộc bản sắc đặc biệt của riêng bạn.
                  </i>
                </div>
                <div>
                  <i>
                  Cho dù hiện tại bạn đang ở độ tuổi nào, cuộc sống của bạn đang được chuyển sang một hướng cụ thể, hướng tới một mục tiêu rất cụ thể. Mục tiêu đó có thể được xem như một phần thưởng hoặc sự hoàn thành một lời hứa tiềm ẩn trong những nỗ lực hiện tại của bạn, mà bạn thường không biết điều đó một cách có ý thức. Trong khi các đặc điểm của con số này thường được nhìn thấy trong thời thơ ấu, chúng ta có xu hướng mất dần các khía cạnh này cho đến khi lớn lên. Nhưng 
                  dù sao thì cuộc sống của chúng ta cũng luôn bị ảnh hưởng bởi nó. 
                  </i>
                </div>
                <div>
                  <i>
                  Số trưởng thành của bạn bắt đầu có tác dụng sâu sắc hơn đến cuộc sống của bạn sau 35 tuổi - 
                  khoảng thời gian bạn bước vào chu kỳ Đỉnh cao thứ hai của mình. Ảnh hưởng của số này tăng 
                  đều đặn khi bạn dần lớn tuổi hơn. <span onClick={() => setShowMore(false)}
                  className="text-accent_color_5_6 cursor-pointer">(Rút gọn)</span>
                  </i>
                </div>
              </>
            )
          }
        </div>
        <div className="bg-neutral_color_1_8 rounded-md px-2 py-2">
          {
            !showDescription && (
              <Tag color={colors.accent_color_5_5} className="rounded cursor-pointer" 
                onClick={() => setShowDescription(true)}>
                <span>Xem giải thích về số <b>{props.data?.number}</b> này của bạn</span>
              </Tag>
            )
          }
          {
            showDescription && (
              <div className="mb-2">
                {
                  (props.data?.description || []).map((item: any) => {
                    return(
                      <div>
                        <i>{item}</i>
                      </div>
                    );
                  })
                }
                
                <div className="mb-1">
                  <Tag color={colors.accent_color_2_1} className="rounded cursor-pointer" 
                    onClick={() => setShowDescription(false)}>
                    <span>Ẩn giải thích</span>
                  </Tag>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Col>
  );

  /**************************** END **************************/

};

export default CardGrownUpIndex;
