import { call, put, takeLatest } from 'redux-saga/effects';
import { API_ARROW_INDEX, API_NUMBER_OF_REPETITIONS } from 'src/services/api/url.index';
import { api } from '../../api/api.index';
import { API_URL } from '../../api/config';
import { IAction, ICatchError, IRootResponse } from '../../interfaces/root.interfaces';
import { actionFailure, actionSuccess } from '../root.actions';
import numberOfRepetitionTypes from '../types/numberOfRepetition.types';

function* getNumberOfRepetition(action: IAction) {
  try {
    const { payload } = action;
    const res: IRootResponse = yield call(() => {
      const _filter = {}
      return api.get(`${API_URL}/${API_NUMBER_OF_REPETITIONS}`, _filter)
    });
    yield put(actionSuccess(numberOfRepetitionTypes.GET_NUMBER_OF_REPETITION_SUCCESS, res));
  } catch (err: ICatchError | any) {
    yield put(actionFailure(numberOfRepetitionTypes.GET_NUMBER_OF_REPETITION_FAILURE, err));
  }
};
// takeLeading => nhiều action => lấy cái đầu tiên
// takeLatest => nhiều action => lấy cái cuối
export default [
  takeLatest(numberOfRepetitionTypes.START_GET_NUMBER_OF_REPETITION, getNumberOfRepetition),
]

