
import { IAction } from '../interfaces/root.interfaces';
import arrowIndexTypes from './types/arrowIndex.types';

export const getArrowIndex = (params: any): IAction => {
  
  const action: IAction = {
    type: arrowIndexTypes.START_GET_ARROW_INDEX,
    payload: { params },
  }
  return action;
};