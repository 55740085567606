import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgetAccount } from '../../../services/actions/account.actions';
import { AppState } from '../../../types';
import FormInput from 'src/components/form/FormInput';

import { IFormForgetPass, IPropsSignIn } from '../interfaces';
import ButtonPTH from '../../../components/buttons/ButtonPTH';
import { notifyError, notifySuccess } from 'src/components/notification';
import { useIsMount } from 'src/utils/helpers/functions/useIsMount';
import { getMessageV1 } from 'src/utils/helpers/functions/getMessage';
import '../styles.less';

import { typeScreenManagerSignIn, screenSignIn } from "src/constants";
import { localGetFormLogin } from 'src/utils/localStorage';

const ForgetPassword = (props:IPropsSignIn) => {
  const [registerForm] = Form.useForm();

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const {stateForgetPass, phone, email} = useSelector((state: AppState) => state.accountReducer);

  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    let _formLogin = localGetFormLogin();
    if (!phone && !email && _formLogin) {
      let _dataUser = JSON.parse(_formLogin);
      registerForm.setFieldsValue({
        username: _dataUser.username,
      });
    }
  }, []);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateForgetPass;
    if (success) {
      notifySuccess(message || '');
      changeDirector(typeScreenManagerSignIn.OTP);

    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateForgetPass.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */

  const btnForgetPassword = (values: IFormForgetPass) => {
    let params;
    
    if (values.username.search('@') !== -1) {
      params = {
        email: values.username.trim(),
        username: values.username.trim(),
      };
    } else {
      params = {
        phone: values.username.trim(),
        username: values.username.trim(),
      };
    }
    dispatch(forgetAccount(params));
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const changeDirector = (values: number) => {
      props.changeType(values)
  }

  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/


  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div
      className='pth-content-1-3 px-8 py-8'
    >
      <Form
        form={registerForm}
        requiredMark={false}
        layout='vertical'
        onFinish={btnForgetPassword}
        onFinishFailed={onFinishFailed}
        initialValues={{
          username: phone || email || '',
        }}
        className='w-full h-full flex justify-between flex-col pth-form-item-label'>
        <div>
          <FormInput
            id='form-username'
            name='username'
            label='Email/ Số điện thoại đã đăng ký'
            type='phoneAndEmail'
            placeholder="Nhập Email/ Số điện thoại đã đăng ký"
            disabled={stateForgetPass.isLoading}
            className='mt-5 w-full'
            required
          />
        </div>

        <ButtonPTH
          id='form-button-signin'
          type='transparent'
          htmlType='submit'
          loading={stateForgetPass.isLoading}>
          Tiếp tục
        </ButtonPTH>
        <hr className='mt-2 mb-2 border-accent_color_3_2'/>
      </Form>
      <div className='t-a-end'>
        <span className='text-14 font-medium text-accent_color_3_2'>
        Bạn  đã có tài khoản ? <a className='text-sm a-login text-left font-bold cursor-pointer' onClick={(e) => changeDirector(typeScreenManagerSignIn.SIGN_IN)}> Đăng nhập tại đây</a>
        </span>
      </div>
    </div>
  )

  /**************************** END **************************/

}

export default ForgetPassword;