import { Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerAccount } from '../../../services/actions/account.actions';
import { AppState } from '../../../types';
import FormInput from 'src/components/form/FormInput';
import FormSelect from 'src/components/form/FormSelect';

import { IFormRegister, IPropsSignIn } from '../interfaces';
import ButtonPTH from '../../../components/buttons/ButtonPTH';
import { notifyError, notifySuccess } from 'src/components/notification';
import { useIsMount } from 'src/utils/helpers/functions/useIsMount';
import { getMessageV1 } from 'src/utils/helpers/functions/getMessage';
import '../styles.less';
import { defaultData } from '../data';

import { dataTypeCustomer, selectOptionsValue, typeScreenManagerSignIn, screenSignIn } from "src/constants";
import { localGetFormLogin } from 'src/utils/localStorage';
import SendOTP from './sendOTP';
import { nameWebsite } from 'src/utils/text';

const Register = (props:IPropsSignIn) => {
  const [registerForm] = Form.useForm();

  const dispatch = useDispatch();
  const isMount = useIsMount();
  const [visible, setVisible] = useState<boolean>(false);
  const {stateRegister, phone, email} = useSelector((state: AppState) => state.accountReducer);

  /****************************START**************************/
  /*                         Life Cycle                      */
  useEffect(() => {
    let _formLogin = localGetFormLogin();
    if (!phone && !email && _formLogin) {
      let _dataUser = JSON.parse(_formLogin);
      if (_dataUser.username.search('@') !== -1) {
        registerForm.setFieldsValue({
          email: _dataUser.username,
        });
      }else{
        registerForm.setFieldsValue({
          phone: _dataUser.username,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isMount)
      return
    const { success, message, error, data } = stateRegister;
    if (success) {
      notifySuccess('Đăng ký thành công');
      changeDirector(typeScreenManagerSignIn.ACTIVE_ACCOUNT_SUCCESS);

    } else if (success === false || error) {
      notifyError(getMessageV1(message));
    }
  }, [stateRegister.isLoading]);
  /**************************** END **************************/


  /****************************START**************************/
  /*                          Function                       */

  const btnRegister = (values: IFormRegister) => {
    let params;
    const _phone = values.phone.trim();
    const _email = `${_phone}@gmail.com`
    params = {
      full_name: values.fullname.trim(),
      email: _email,
      phone: _phone,
      password: values.password.trim(),
      role: 'cd772768-2423-4124-aa68-d0edd16546ca',
      status: 'active',
      email_notifications: false
    }
    dispatch(registerAccount(params));
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const changeDirector = (values: number) => {
      props.changeType(values)
  }

  const onOpenModalOTP = () => {
    registerForm.validateFields()
    .then(values => {
      setVisible(true);
    })
    .catch(errorInfo => {
    });
  }

  const onSubmit = () => {
    setVisible(false);
    registerForm.submit();
  }

  /**************************** END **************************/


  /****************************START**************************/
  /*                         Component                       */

  /**************************** END **************************/


  /****************************START**************************/
  /*                        Return Page                      */

  return (
    <div
      className='pth-content-1-3 px-8 py-8'
    >
      {
        visible && (
          <Modal
            visible={visible}
            title={'Gửi mã xác nhận OTP về số điện thoại'}
            onCancel={() => setVisible(false)}
            footer={false}
            width='30%'
          >
            <SendOTP phone={registerForm.getFieldValue('phone')} onConfirmOTP={onSubmit}/>
          </Modal>
        )
      }
      <Form
        form={registerForm}
        requiredMark={false}
        layout='vertical'
        onFinish={btnRegister}
        onFinishFailed={onFinishFailed}
        initialValues={{
          email: email || '',
          phone: phone || '',
          groupUser: defaultData.typeCustomer,
        }}
        className='w-full h-full flex justify-between flex-col pth-form-item-label'>
        <div>
          <FormInput
            id='form-fullname'
            name='fullname'
            label='Họ tên'
            type='text'
            placeholder="Họ tên đầy đủ của bạn"
            disabled={stateRegister.isLoading}
            className='mt-5 mb-0 w-full'
            required
          />
          {/* <FormInput
            id='form-email'
            name='email'
            label='Email'
            type='email'
            placeholder="Nhập email"
            disabled={stateRegister.isLoading}
            classItem='mt-3 mb-0'
            required
          /> */}
          <FormInput
            id='form-phone'
            name='phone'
            label='Số điện thoại'
            type='phone'
            placeholder="Nhập số điện thoại"
            disabled={stateRegister.isLoading}
            classItem='mt-3 mb-0'
            required
          />
          <FormInput
            id='form-password'
            name='password'
            label='Mật khẩu'
            type='password'
            placeholder="Nhập mật khẩu"
            disabled={stateRegister.isLoading}
            classItem='mt-3 mb-0 input-pass'
            required
          />
          <Form.Item
            name="confirm"
            label="Nhập lại mật khẩu"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Nhập lại mật khẩu không khớp!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Nhập lại mật khẩu"/>
          </Form.Item>
        </div>

        <ButtonPTH
          id='form-button-signin'
          type='transparent'
          onClick={onOpenModalOTP}
          loading={stateRegister.isLoading}>
          Đăng ký
        </ButtonPTH>
        <div className='text-center'>
          <span className='text-accent_color_3_2'>Bằng việc bạn bấm chọn đăng ký tức là bạn đã đồng ý với những 
          chính sách & điều khoản của {nameWebsite}</span>
        </div>
        <hr className='mt-2 mb-2 border-accent_color_3_2'/>
      </Form>
      <div className='t-a-end'>
        <span className='text-14 font-medium text-accent_color_3_2'>
        Bạn  đã có tài khoản ? <a className='text-sm a-login text-left font-bold cursor-pointer' onClick={(e) => changeDirector(typeScreenManagerSignIn.SIGN_IN)}> Đăng nhập tại đây</a>
        </span>
      </div>
    </div>
  )

  /**************************** END **************************/

}

export default Register;