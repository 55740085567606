const routerNames = {
  SIGN_IN: "/sign-in",
  ACTIVE_OTP: "/active-otp",
  HOME: "/home",
  SEARCH: "/search",
  HISTORY_SEARCH: "/history-search",
  HISTORY_BUY: "/history-buy",
  RE_SEARCH: "/re-search",
  PROFILE: "/profile",
  PRIVACY_POLICY: "/privacy-policy",
  TERM_AND_CONDITION: "/term-and-condition",
};

export default routerNames;
