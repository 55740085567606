import { all } from "redux-saga/effects";
import accountSagas from "./account.sagas";
import arrowIndexSagas from "./arrowIndex.sagas";
import circleOfLifeSagas from "./circleOfLife.sagas";
import globalSagas from "./global.sagas";
import historySearchSagas from "./historySearch.sagas";
import meanOfIndexSagas from "./meanOfIndex.sagas";
import numberOfRepetitionSagas from "./numberOfRepetition.sagas";
import personalMonthYearSagas from "./personalMonthYear.sagas";
import pyramidNumbersSagas from "./pyramidNumbers.sagas";
import searchNumerologySagas from "./searchNumerology.sagas";

function* rootSagas() {
  yield all([
    ...globalSagas,
    ...accountSagas,
    ...searchNumerologySagas,
    ...pyramidNumbersSagas,
    ...circleOfLifeSagas,
    ...personalMonthYearSagas,
    ...meanOfIndexSagas,
    ...arrowIndexSagas,
    ...numberOfRepetitionSagas,
    ...historySearchSagas,
  ]);
}

export default rootSagas;
