
import { useEffect, useState } from "react";

import {
  Avatar, Layout, Menu, Typography
} from "antd";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { putSignIn } from "src/services/actions/global.actions";
import routerNames from "src/utils/data/routerName";
import { localGetFormLogin, localGetToken } from "src/utils/localStorage";
import imageMenuIcon from '../../assets/images/MenuIcon.svg';
import Logo from '../../assets/images/logo.png';
import SignOutIcon from '../../assets/images/SignOut.svg';
import UserIcon from '../../assets/images/UserIcon.svg';

const { Header  } = Layout;
const setting = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      fill="#111827"
    ></path>
  </svg>,
];
function HeaderLayout(props: {
  placement?: any,
  name?: any,
  subName?: any,
  onPress?: any,
  handleSidenavColor?: any,
  handleSidenavType?: any,
  handleFixedNavbar?: any,
  pathname?: any
}) {

  const {
    placement,
    name,
    subName,
    onPress,
    handleSidenavColor,
    handleSidenavType,
    handleFixedNavbar,
    pathname,
  } = props;

  const { Title, Text } = Typography;
  const defaultItems = [
    {
      label: (
        <Link
          to={routerNames.HOME}
        >
          Trang chủ
        </Link>
      ),
      key: 'home',
    },
    // {
    //   label: ( <Link
    //     to={routerNames.SEARCH}
    //   >
    //     Tra cứu
    //   </Link>
    //   ),
    //   key: 'search',
    // },
    // {
    //   label: 'Dịch vụ',
    //   key: 'SubMenu',
    //   // icon: <SettingOutlined />,
    //   children: [
    //     {
    //       label: 'Thần số học trọn đời',
    //       key: 'setting:1',
    //     },
    //     {
    //       label: 'Đặt tên khai sinh',
    //       key: 'setting:2',
    //     },
    //   ],
    // },
    
  ];

  const [visible, setVisible] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [openNav, setOpenNav] = useState(true);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [current, setCurrent] = useState(pathname);
  const [items, setItems] = useState<any>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCurrent(pathname)
  }, [pathname]);

  useEffect(() => {
    const _token = localGetToken();
    const _account = localGetFormLogin();
    if (_token && _account) {
      let _user:any = JSON.parse(_account);
      const _name = _user.username ? _user.username : "Tài khoản";
        
      setItems([
        ...defaultItems,
        {
          label: 'Tài khoản',
          key: 'account',
          // icon: <SettingOutlined />,
          children: [
            {
              label: ( <Link
                to={routerNames.HISTORY_SEARCH}
              >
                Lịch sử tra cứu
              </Link>
              ),
              key: 'history-search',
            },
            {
              label: ( <Link
                to={routerNames.HISTORY_BUY}
              >
                Đã mua
              </Link>
              ),
              key: 'history-buy',
            },
            {
              label: ( <Link
                to={routerNames.PROFILE}
              >
                Thông tin
              </Link>
              ),
              key: 'info-account',
            },
            {
              label: (
                <span
                  onClick={btnSignOut}
                >
                  Đăng xuất
                </span>
              ),
              key: 'signOut',
            },
          ],
        },
      ])
    }else{
      setItems([
        ...defaultItems,
        {
          label: (
            <Link
              to={routerNames.SIGN_IN}
            >
              Đăng nhập
            </Link>
          ),
          key: 'signIn',
        },
      ])
    }
  }, [localGetToken()]);

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);


  const btnSignOut = () => {
    setVisiblePopover(false);
    dispatch(putSignIn(false));
  };
  const btnProfile = () => {
    setVisiblePopover(false);
    history.push({
      pathname: routerNames.PROFILE,
    });
  };

  /**************************** END **************************/

  /****************************START**************************/
  /*                      Return Component                   */
  const content = () => {
    return (
      <div className="flex flex-col">
        <button
          onClick={btnProfile}
          className="flex items-center justify-start my-1"
        >
          <img src={UserIcon} alt=''/>
          <p className="text-14 px-2">Tài khoản</p>
        </button>
        <button
          onClick={btnSignOut}
          className="flex items-center justify-start my-1"
        >
          <img src={SignOutIcon} alt=''/>
          <p className="text-14 px-2">Đăng xuất</p>
        </button>
      </div>
    );
  };

  const onClick = (e:any) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  
  const _renderMenu = () => {
    return(
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} disabledOverflow={true}/>
    )
  }

  return (
    <Header className="header">
      {/* <div className="setting-drwer" onClick={showDrawer}>
        {setting}
      </div> */}
      <div
        className="fixed w-full z-50 flex items-center justify-between bg-neutral_color_1_8 top-0 left-0 right-0 px-28 pth-header"
        style={{ height: 60 }}
      >
        <div className="flex justify-center">
        </div>
        <div className="menu-flex">
          {_renderMenu()}
        </div>
      </div>
    </Header>
  );
}

export default HeaderLayout;
