import * as React from "react"

const SvgGift = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill={props.fill || "#000"}>
      <path d="M19.824 11.767H9.892l8.676-5.01c.35-.201.47-.65.268-1l-2.197-3.805a.732.732 0 0 0-1-.269l-2.243 1.29A2.934 2.934 0 0 0 9.001.394 2.926 2.926 0 0 0 7.537 3.03l.078 2.335-1.942-1.21a2.926 2.926 0 0 0-3.015-.052 2.91 2.91 0 0 0-1.365 1.78 2.91 2.91 0 0 0 .292 2.222c.258.446.62.807 1.052 1.06l-2.27 1.307a.732.732 0 0 0-.265 1.007l2.246 3.806a.732.732 0 0 0 .997.262l1.098-.634v9.354c0 .405.328.733.733.733h14.648a.732.732 0 0 0 .733-.733V12.5a.732.732 0 0 0-.733-.732Zm-8.056 1.465h1.464v4.823l-.214-.214a.732.732 0 0 0-1.036 0l-.214.214v-4.823ZM9.733 1.66a1.467 1.467 0 0 1 2.001.536c.385.667.189 1.54-.42 1.976l-2.23 1.283L9 2.98a1.464 1.464 0 0 1 .732-1.32Zm-5.99 6.394a1.455 1.455 0 0 1-.889-.682 1.455 1.455 0 0 1-.146-1.112A1.464 1.464 0 0 1 4.9 5.398L6.955 6.68 4.65 8.007c-.288.112-.603.13-.906.048Zm-.502 5.86L1.74 11.373l13.996-8.055L17.2 5.856l-13.96 8.06Zm15.85 9.62H5.909v-9.468l1.446-.835h2.949v6.592a.732.732 0 0 0 1.25.518l.947-.947.947.947a.733.733 0 0 0 1.25-.518v-6.592h4.395v10.303ZM24.268 8.837h-.733v-.732a.733.733 0 0 0-1.465 0v.732h-.732a.732.732 0 0 0 0 1.465h.732v.733a.732.732 0 1 0 1.465 0v-.733h.733a.732.732 0 1 0 0-1.465ZM21.29 5.908a.732.732 0 1 0 0-1.465.732.732 0 0 0 0 1.465Z" />
      <path d="M18.36 10.302a.732.732 0 1 0 0-1.465.732.732 0 0 0 0 1.465Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgGift
